import React, { FC, useCallback } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import type { ButtonProps } from "@costar/theme-ui";
import { Button } from "@costar/theme-ui";
import { IconRecommend } from "@costar/theme-ui-icons/csg/IconRecommend";

import { UUISubject, UUISubjectPayloads } from "../../../subjects";

const RecommendButton: FC<ButtonProps> = props => {
    const { toggled, disabled, ...rest } = props;

    const onClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.RecommendButtonClicked]>(UUISubject.RecommendButtonClicked).notify();
    }, []);

    return (
        <Button
            automation-id="uui-recommend-button"
            data-testid="recommend-button"
            variant="ghost"
            size="xxl"
            color={toggled ? "primary" : "neutral.dark"}
            p="0"
            hoverColor="primary"
            onClick={onClick}
            disabled={disabled}
            icon={<IconRecommend />}
            sx={{ pt: "4px", fontSize: "2em", mr: "-4px" }}
            {...rest}
        />
    );
};

export default React.memo(RecommendButton);
