import React, { FC, useCallback, useMemo, useState } from "react";

import { Box } from "@costar/theme-ui";

import { useI18N } from "../../../i18n/react-i18n";
import { getNavConfig, ProductId, useConfigContext } from "../../common";

import { UnauthMainMenu } from "./main-menu";
import UnauthMasthead from "./masthead";
import { NavConfigContextProvider } from "./nav-config.context";

// Tabs that, when clicked, take the user to the login page instead of displaying
// an upsell modal
// const LOGIN_TABS = ["MARKETING_CENTER"] as ProductId[];

export const UnAuthenticatedApp: FC = () => {
    const culturalResources = useI18N()!;
    const config = useConfigContext();

    const navConfig = useMemo(() => getNavConfig(culturalResources, config), [culturalResources, config]);
    const [selection, setSelection] = useState<ProductId>(navConfig[0].id);

    const navigate = useCallback(
        (tabId: ProductId) => {
            const currentTab = navConfig.find(tab => tab.id === tabId);
            // Handle bug case
            if (!currentTab) {
                console.warn(`No tab for id: ${tabId}`);
                // This will fail, but it might fail _better_ than just throwing an error
                setSelection(tabId);
                return;
            }

            // User clicked a link they're allowed to go to - navigate there
            if (currentTab.isUserSubscriber && currentTab.href && !window.location.pathname.match(currentTab.href)) {
                window.location.assign(currentTab.href);
            } else {
                // User is either not allowed to navigate to link or tab is not a link
                setSelection(tabId);
            }
        },
        [navConfig]
    );
    return (
        <NavConfigContextProvider value={navConfig}>
            <Box width="100%" height="89px" as="header">
                <UnauthMasthead onSidebarTabClick={navigate} />
                <UnauthMainMenu selection={selection} setSelection={navigate} />
            </Box>
        </NavConfigContextProvider>
    );
};
