import * as React from "react";

import { Anchor, Flex, Icon, SwitchContent, utils } from "@costar/theme-ui";

import { ROOT_URL } from "../../../common";

import { LeftNavContentProps } from "./types";

const LeftContent: React.FC<LeftNavContentProps> = props => {
    const { hamburgerIcon: hamburgerMenu, rootUrl = ROOT_URL, links = [] } = props;
    // FIXME: mediaQuery prop is required by SwitchContentProps interface but
    // has a default value set by the component. This is a bug in theme-ui
    return (
        <Flex gap={["xl"]} fontSize="sm" flexWrap="wrap" overflow="hidden">
            {hamburgerMenu}
            <Anchor href={rootUrl} title="CoStar Logo">
                {utils.isBrowser ? (
                    <SwitchContent
                        mediaQuery="min-width"
                        replacementContent={[null, <Icon icon="costarLogo" height={32} width={117} />]}
                    >
                        <Icon icon="costarWheel" size={32} />
                    </SwitchContent>
                ) : (
                    <Icon icon="costarLogo" height={32} width={117} />
                )}
            </Anchor>
            {links.map((i, indx) => (
                <Anchor key={`left-nav-link-${indx}`} variant="hover-primary" href={i.url}>
                    {i.label}
                </Anchor>
            ))}
        </Flex>
    );
};

export default React.memo(LeftContent);
