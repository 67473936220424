// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { I18NContext } from "../../../i18n/react-i18n";
import { useI18N } from "../../../i18n/react-i18n";
import type { Resources } from "../../../i18n/resources";

/**
 * Wraps {@link useI18N} in a null check.
 *
 * This hook should reduce replicated null checks with getting cultural
 * resources.
 *
 * @returns The {@link Resources} object stored within the nearest
 * {@link I18NContext} provider.
 */
export const useStrictI18N = (): Resources => {
    const culturalResources = useI18N();

    // Throw an error if cultural resources is undefined
    if (!culturalResources) {
        // use a more developer-friendly error message in dev
        let message: string;
        if (process.env.NODE_ENV !== "production") {
            message =
                "Could not get cultural resources from useI18N. Did you forget to wrap your component in <I18NContext.Provider />?";
        } else {
            message = "Missing cultural resources";
        }

        throw new Error(message);
    }

    return culturalResources;
};
