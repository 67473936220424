import React, { useContext } from "react";

import { ClientConfig } from "./config";

/**
 * @ignore - internal component.
 */
const Context = React.createContext({} as ClientConfig);

// Always show display name for context, even in production. Makes debugging
// significantly easier. NOTE: this is only done for several highly important
// context providers. It shouldn't be done for every component.
Context.displayName = "ConfigContext";

export const ConfigContextProvider = Context.Provider;
export const ConfigContextConsumer = Context.Consumer;

export const useConfigContext = (): ClientConfig => useContext(Context);
