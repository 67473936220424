import React from "react";

import { NavConfig } from "../../common";

const NavConfigContext = React.createContext({} as NavConfig);

if (process.env.NODE_ENV !== "production") {
    NavConfigContext.displayName = "NavConfigContext";
}

export const NavConfigContextProvider = NavConfigContext.Provider;
export const NavConfigContextConsumer = NavConfigContext.Consumer;

export const useUnauthNavConfigContext = () => React.useContext(NavConfigContext);
