export interface PropertySearchRequest {
    readonly term?: string | null;
    readonly sortField?: string;
    readonly sortAsc?: boolean;
    readonly pageSize?: number;
    readonly filterId?: string;
    readonly filterType?: string;
}

export interface PropertySearchPin {
    readonly i: number;
    readonly c: number;
    readonly a: number;
    readonly ad: number;
}

export interface PropertySearchPlacard {
    readonly propertyId: number;
    readonly buildingRating: number;
    readonly constructionStatus: number;
}

export interface PropertySearchPlacardsResponse {
    readonly placards: PropertySearchPlacard[];
}

export interface PropertySearchPropertyItem {
    readonly propertyId: string;
    readonly buildingAreaTotal: number;
}

export interface PropertySearchPropertiesResponse {
    readonly properties: readonly PropertySearchPropertyItem[];
}

export interface PropertySearchResponse {
    readonly PropertyCount: number;
    readonly SpaceCount: number;
    readonly Pins: readonly PropertySearchPin[];
    readonly resultGuid: string;
    readonly errorMessage: string;
}

export interface PropertySearchRepository {
    search(request: PropertySearchRequest): Promise<PropertySearchResponse>;
    properties(ids: readonly number[]): Promise<PropertySearchPropertiesResponse>;
    placards(ids: number[]): Promise<PropertySearchPlacardsResponse>;
}

export enum FusionSearchProductType {
    Property = 1,
    Tenant = 2,
    SaleComps = 5,
    ShoppingCenter = 38,
    PublicRecord = 41,
    ForSale = 47,
    MultiFamily = 52,
    LeaseComps = 63,
    Market = 70,
}
