const sessionStarted = (glanceVisitor, setScreenShareCode) => () => {
    setScreenShareCode(glanceVisitor.getKey());
};

export const screenShareSessionClose = () => {
    window?.GLANCE?.Cobrowse?.Visitor?.stopSession();
};

export const emitScreenShareStartEvent = (setScreenShareCode, closeModal) => {
    const glanceVisitor = window?.GLANCE?.Cobrowse?.Visitor;
    if (glanceVisitor == null) {
        return;
    }
    if (glanceVisitor?.inSession()) {
        setScreenShareCode(glanceVisitor?.getKey());
        return;
    }

    glanceVisitor.addEventListener("agents", closeModal);
    glanceVisitor.addEventListener("sessionstart", sessionStarted(glanceVisitor, setScreenShareCode));
    glanceVisitor.startSession();
};

export const addGlanceIfNotPresent = (glanceSite: string) => {
    const glanceScriptId = "glance-cobrowse";
    const groupId = "20803";

    if (typeof document !== "undefined") {
        const glanceLoaded = document.getElementById(glanceScriptId);
        if (!glanceLoaded) {
            const glanceScript = document.createElement("script");
            glanceScript.setAttribute("type", "text/javascript");
            glanceScript.setAttribute(
                "src",
                "https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=20803&site=" + glanceSite
            );
            glanceScript.setAttribute("id", glanceScriptId);
            glanceScript.setAttribute("data-groupid", groupId);
            glanceScript.setAttribute("data-site", glanceSite);
            // if (GLANCE_LOCAL_COOKIE === 'ls') {
            //   glanceScript.setAttribute('data-cookietype', 'ls')
            // }
            glanceScript.setAttribute("charset", "UTF-8");
            document.head.appendChild(glanceScript);
        }
    }
};
