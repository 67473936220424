import React, { FC } from "react";

import { Anchor, Flex, Text } from "@costar/theme-ui";

import { RichIcu } from "$i18n/react-i18n";

export type MixedAnalyticsTooltipProps = {
    primaryMessage?: string;
    secondaryMessage?: string;
    anchorUrl?: string;
    anchorText?: string;
};

export const MixedAnalyticsTooltip: FC<MixedAnalyticsTooltipProps> = props => {
    const { primaryMessage = "", secondaryMessage = "", anchorUrl = "", anchorText = "" } = props;
    return (
        <Flex flexDirection="column" gap="lg">
            <Flex flexDirection="column" gap="md">
                <Text>
                    <RichIcu icuContent={primaryMessage} />
                </Text>
                <Text>
                    <RichIcu icuContent={secondaryMessage} />
                </Text>
            </Flex>
            <Flex justifyContent="end" flexDirection="row" gap="xs">
                <Anchor color="primary" href={anchorUrl}>
                    {anchorText}
                </Anchor>
            </Flex>
        </Flex>
    );
};
