import React, { FC, useCallback } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { Anchor, Divider, Flex, Tooltip, utils } from "@costar/theme-ui";
import { IconArrowThickLeft } from "@costar/theme-ui-icons/csg/IconArrowThickLeft";
import { IconArrowThickRight } from "@costar/theme-ui-icons/csg/IconArrowThickRight";

import { UUISubject } from "../../../subjects";

import { prevNextButtonClicked } from "./../../../../common/state/reducer/state.actions";
import { useSubnavState } from "./../../../../common/state/state.context";
import type { ButtonState } from "./types";

export type NextPreviousButtonProps = {
    previousButton: ButtonState & {
        label?: string;
    };
    nextButton: ButtonState & {
        label?: string;
    };
    activeRecordIndex: number | undefined;
};
const NextPreviousButtons: FC<NextPreviousButtonProps> = props => {
    const { previousButton, nextButton, activeRecordIndex } = props;

    const { dispatch } = useSubnavState();

    const updateCounter = useCallback(
        (buttonClicked: boolean) => dispatch(prevNextButtonClicked(buttonClicked)),
        [dispatch]
    );

    const handleOnNextClick = useCallback(() => {
        updateCounter(true);
        subject(UUISubject.NextPrevButtonClicked).notify([activeRecordIndex, true]);
    }, [activeRecordIndex, updateCounter]);

    const handleOnPrevClick = useCallback(() => {
        updateCounter(false);
        subject(UUISubject.NextPrevButtonClicked).notify([activeRecordIndex, false]);
    }, [activeRecordIndex, updateCounter]);

    return (
        <Flex gap="0.25em" pr="sm">
            <Tooltip variant="pendo" title={previousButton?.to ? previousButton?.label || "" : ""}>
                <Anchor
                    role="button"
                    automation-id="uui-previous-button"
                    variant="primary-icon"
                    onClick={handleOnPrevClick}
                    icon={<IconArrowThickLeft transform="scale(1.2)" />}
                    disabled={previousButton?.disabled}
                />
            </Tooltip>
            <Divider orientation="vertical" height="1.5em" alignSelf="center" />
            <Tooltip variant="pendo" title={nextButton?.to ? nextButton?.label || "" : ""}>
                <Anchor
                    automation-id="uui-next-button"
                    variant="primary-icon"
                    onClick={handleOnNextClick}
                    icon={<IconArrowThickRight transform="scale(1.2)" />}
                    disabled={nextButton?.disabled}
                />
            </Tooltip>
        </Flex>
    );
};

export default React.memo(NextPreviousButtons);
