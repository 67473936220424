
import * as React from "react";
import { ICUMessageFormatter } from "@costar/i18n";

import { TYPE } from "@formatjs/icu-messageformat-parser";

type RichICUProps = {
    /**
     * The content, *not the key*, of an ICU message that contains HTML
     */
    icuContent: string;
    /**
     * Zero or more `RichIcuHtmlElement`s for attaching behaviors to inline HTML. No other children are accepted
     */
    children?:
        | React.ReactComponentElement<typeof RichIcuHtmlElement>
        | React.ReactComponentElement<typeof RichIcuHtmlElement>[];
};

type RichIcuRendererProps = {
    /**
     * The content, *not the key*, of an ICU message, which contain select & plural types.
     */
    icuContent: string;
    /**
     * A map of selectors and components to render from the passed ICU string.
     */
    renderComponents: {
        [richSelector: string]: (passedValue: { label: string; value: number }[] | string | null) => JSX.Element;
    };
};
type RichIcuHtmlElementProps = {
    /**
     * The element query selector for the inline HTML element included in the ICU content. This should point to a *single* HTML element
     */
    selector: string;
    /**
     * Optional class name that should be used for the styling the DOM element
     */
    className?: string;
    /**
     * Optional click handler for the DOM element inlined in the ICU content
     */
    onClick?: () => void;
};

const IcuContext = React.createContext<HTMLDivElement | undefined>(undefined);

function useHandlerOnOff(element: HTMLElement & EventTarget, event: string, handler?: () => void) {
  React.useEffect(() => {
    handler && element?.addEventListener(event, handler);

      return () => {
        handler && element?.removeEventListener(event, handler);
      };
  }, [element, event, handler]);
}

type IcuProps = {
    [key: number]: {
        ast_value?: string;
        placeholder?: string;
        ast_name?: string;
        options?: {
            label: string;
            value: number;
        }[];
    };
};
/**
 * Parses an ICU statement with embedded selects and plurals to an object with options.
 *
 *  @param icuContent ICU string with embedded select & plural types
 *  @param culture Culture
 * */
function icuToProps({ icuContent }: { icuContent: string }): IcuProps {
    const astTemplate = new ICUMessageFormatter(icuContent);
    const { ast } = astTemplate;

    let icuProps: IcuProps = {};

    for (const key in ast) {
        if (Object.prototype.hasOwnProperty.call(ast, key)) {
            const template = ast[key];
            const optionsValues =
                (template as any).options &&
                Object.entries((template as any).options).map((el: any) => ({
                    label: el[1]["value"][0]?.value,
                    value: Number(el[0]),
                }));

            const templateValue = (template as any).value.trim();
            if (template) {
                switch (template.type) {
                    case TYPE.literal:
                        icuProps[key] = { ast_value: templateValue };
                        break;
                    case TYPE.select:
                        icuProps[key] = {
                            ast_name: templateValue,
                            options: optionsValues,
                        };
                        break;
                    default:
                        icuProps[key] = { ast_name: templateValue };
                        break;
                }
            }
        }
    }
    return icuProps;
}

/**
 * Adds behavior and styling to a DOM element inlined in an ICU message and inserted via `RichIcu`
 *
 * @param {RichIcuHtmlElementProps} props
 */
export function RichIcuHtmlElement({ selector, className, onClick }: RichIcuHtmlElementProps) {
    const ref = React.useContext(IcuContext);
    const element = ref?.querySelector(selector) as HTMLElement;

    React.useEffect(() => {
        const classes = className?.split(/\s/);
        classes?.forEach(cn => cn && element?.classList?.add(cn));

        return () => {
            classes?.forEach(cn => cn && element?.classList?.remove(cn));
        };
    }, [className, element]);

    useHandlerOnOff(element, "click", onClick);

    return <></>;
}

/**
 * Injects ICU text with inline HTML into the DOM, accepts `RichIcuHtmlElement` as children
 *
 * @param RichICUProps props
 */
export function RichIcu({ icuContent, children }: RichICUProps) {
    const [containerElement, setContainerElement] = React.useState<HTMLDivElement>();
    const handleRef = React.useCallback((ref: HTMLDivElement) => {
        setContainerElement(ref);
    }, []);

    React.Children.forEach(children, (child) => {
      if (child && child.type !== RichIcuHtmlElement) {
        throw new Error(
          "Broken invariant: Only RichIcuHtmlElement is allowed as a child for RichICU"
        );
      }
    });

    return (
        <IcuContext.Provider value={containerElement}>
            <div ref={handleRef} dangerouslySetInnerHTML={{ __html: icuContent }} />
            {children}
        </IcuContext.Provider>
    );
}

/**
 * Allows for an ICU string with embedded select & plural types to render children components with options from the ICU
 * This allows for full translation of a message, with parts of the message containing DOM elements
 *  @param icuContent ICU string with embedded select & plural types
 *  @param culture Culture
 *  @param renderComponents props
 */
export function RichIcuRenderer({ icuContent, renderComponents }: RichIcuRendererProps) {

    let augmentedChildren: JSX.Element[] = [];
    if (icuContent.match(/{(.*)}/g)) {
        const htmlObject = icuToProps({ icuContent });
        augmentedChildren = Object.values(htmlObject).map(part => {
            if (part.ast_value) return React.createElement('span', {key: (Object.values(part)[0]) as string }, `${part.ast_value} `);
            if (part.ast_name) {
                let passedValue = part.options ? part.options : part.placeholder ? part.placeholder : null;
                return renderComponents[part.ast_name](passedValue);
            } else { return <></> }
        });
    } else if (icuContent.match(/<(.*)>/g)) {
        console.error("Must not contain HTML, use RichICU for passing html content.");
    } else {
        console.error("Not a parseable ICU template.");
    }

    return <><div className="rich-icu-renderer">{augmentedChildren}</div></>;
}
  
