// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ProductId, SectionId } from "src/common";

import type { Observer } from "@csgp-mfe-utils/event-observer";

import {
    AlertClickEventPayload,
    ButtonClickEventPayload,
    NavbarButtonsPayload,
    NavigationEvent,
    NextPrevClickEventPayload,
} from "./payload-types";

/**
 * The names of all subjects the UUI will publish (and, in some cases, listen
 * to). This is our API Contract to other MFEs.
 */

export enum UUISubject {
    /* *****EVENTS***** */

    /** Fires when the share feedback button clicked on the help dropdown */
    ShareFeedbackClicked = "uui-help-feedback-clicked",

    /** Fires when the share request training button clicked on the help dropdown */
    RequestTrainingClicked = "uui-help-training-clicked",

    /** Fires when the favorites mode button clicked on the sub-menu */
    FavoritesModeButtonClicked = "uui-favorites-mode-clicked",

    /**Fires when the alerts button is clicked.*/
    AlertButtonClicked = "uui-alert-button-clicked",

    /**Fires when a nav link is clicked when it does not have an href.*/
    MenuTabSelected = "uui-menu-selected",

    /**Fires when the back button is clicked.*/
    BackButtonClicked = "uui-back-button-clicked",

    /**The label of the tier 2 subnav.*/
    SetSubnavLabel = "uui-subnav-label-set",

    /**Total count of favorites on the tier 2 subnav.*/
    SetFavoritesCount = "uui-favorites-count-set",

    /**Fires when the active record index in the pager has changed. The tuple returns the index and true if is next, false if its prev.*/
    NextPrevButtonClicked = "uui-next-prev-button-clicked",

    /**Dynamic navbar Buttons */
    NavbarButtons = "uui-navbar-buttons",

    /**Fires when the save button is clicked.*/
    SaveButtonClicked = "uui-save-button-clicked",

    /**Fires when the remove button is clicked.*/
    RemoveButtonClicked = "uui-remove-button-clicked",

    /**Fires when the more button is clicked.*/
    MoreButtonClicked = "uui-more-button-clicked",

    /**Fires when the reports button is clicked.*/
    ReportsButtonClicked = "uui-reports-button-clicked",

    /**Fires when the favorite button is clicked.*/
    FavoriteButtonClicked = "uui-favorite-button-clicked",

    /**Fires when the check mark button is clicked.*/
    CheckMarkClicked = "uui-check-mark-clicked",

    /**Fires when the 'share feedback' button is clicked.*/

    /* ATTRIBUTE STATE */

    /**Explicitly specify the active product instead of relying on URL matching. Used only FOR LEASE / LEASING only.*/
    ActiveProductKey = "uui-active-product-key",

    /**The current pager selected index.*/
    ActiveRecordIndex = "uui-active-record-index",

    /**The search result total count for paging results.*/
    ResultSetTotalCount = "uui-result-set-total-count",

    ResultSetTotalCountTooltipText = "uui-result-set-total-count-tooltip-text",

    /**When true, costar-header will fire 'navItemClicked' events for every single item. The host app will have to either process the metadata for the click event, or call the `processClickEvent` callback.*/
    DetailedClickEvent = "uui-detailed-click-event",

    /**The number of alerts shown on the Alerts button badge*/
    AlertsCount = "uui-alerts-count",

    /**The number of alerts shown on the Recommendations Cart button badge*/
    RecommendationsCount = "uui-recommendations-count",

    /* SHOW BUTTON STATES */
    ShowBackButton = "uui-show-back-button",
    /**Override string or boolean flag sent from suite **/
    BackButtonOverrideLabel = "back-button-override-label",
    ShowOmniSearch = "uui-show-omnisearch",
    ShowFavoriteButton = "uui-show-favorite-button",
    ShowFavoriteButtonChecked = "uui-show-favorite-button-checked",
    /** * Shows the favorite button in an active state when true. Has no effect is show-favorite-button is set to false.*/
    ShowCheckMarkButton = "uui-show-check-mark-button",
    /** * Shows the checkmark button in an active state when true. Has no effect is show-check-mark-button is set to false.*/
    ShowCheckMarkButtonChecked = "uui-show-check-mark-button-checked",
    ShowRemoveButton = "uui-show-remove-button",
    ShowReportsButton = "uui-show-reports-button",
    ShowSaveButton = "uui-show-save-button",
    ShowMoreButton = "uui-show-more-button",
    ShowShareButton = "uui-show-share-button",
    ShowAlertsButton = "uui-show-alerts-button",
    ShowHelpButton = "uui-show-help-button",
    ShowRecommendationsCartButton = "uui-show-reocmmendations-cart-button",
    /**  * Show or hide the 2nd navigation row. Must also be authenticated. */
    ShowSubNav = "uui-show-subnav",
    ShowFavoritesModeButton = "uui-show-favorites-mode",
    ShowFavoritesModeButtonChecked = "uui-show-favorites-mode-checked",
    HideRequestTraining = "uui-hide-request-training",
    ShowRecommendButton = "uui-show-recommend-button",
    ShowRecommendButtonChecked = "uui-show-recommend-button-checked",
    DisableRecommendButton = "uui-disable-recommend-button",
    RecommendButtonClicked = "uui-recommend-button-clicked",
}

// TODO(don): This needs to be standardized. I'm not sure how we should approach
// this, but it should look something like this. It should also probably be in mfe-utils.
/**
 * A map type that correspond a {@link UUISubject subject} with the type of payload
 * that is passed to {@link Observer Observers} when an event is published to it.
 */
export type UUISubjectPayloads = {
    [UUISubject.ShareFeedbackClicked]: void;
    [UUISubject.RequestTrainingClicked]: void;
    [UUISubject.FavoritesModeButtonClicked]: void;
    [UUISubject.AlertButtonClicked]: AlertClickEventPayload;
    [UUISubject.MenuTabSelected]: NavigationEvent;
    [UUISubject.BackButtonClicked]: void;
    [UUISubject.SaveButtonClicked]: ButtonClickEventPayload;
    [UUISubject.RemoveButtonClicked]: void;
    [UUISubject.MoreButtonClicked]: ButtonClickEventPayload;
    [UUISubject.ReportsButtonClicked]: void;
    [UUISubject.FavoriteButtonClicked]: void;
    [UUISubject.CheckMarkClicked]: void;
    [UUISubject.NextPrevButtonClicked]: NextPrevClickEventPayload;
    [UUISubject.NavbarButtons]: NavbarButtonsPayload;
    [UUISubject.ActiveProductKey]: ProductId | SectionId | undefined;
    [UUISubject.SetSubnavLabel]: string;
    [UUISubject.SetFavoritesCount]: number;
    [UUISubject.ActiveRecordIndex]: number | undefined;
    [UUISubject.ResultSetTotalCount]: number | undefined;
    [UUISubject.ResultSetTotalCountTooltipText]: string | undefined;
    [UUISubject.DetailedClickEvent]: boolean;
    [UUISubject.ShowBackButton]: boolean;
    [UUISubject.BackButtonOverrideLabel]: string | undefined;
    [UUISubject.ShowOmniSearch]: boolean;
    [UUISubject.ShowFavoriteButton]: boolean;
    [UUISubject.ShowFavoriteButtonChecked]: boolean;
    [UUISubject.ShowFavoritesModeButton]: boolean;
    [UUISubject.ShowFavoritesModeButtonChecked]: boolean;
    [UUISubject.ShowCheckMarkButton]: boolean;
    [UUISubject.ShowCheckMarkButtonChecked]: boolean;
    [UUISubject.ShowRemoveButton]: boolean;
    [UUISubject.ShowReportsButton]: boolean;
    [UUISubject.ShowSaveButton]: boolean;
    [UUISubject.ShowMoreButton]: boolean;
    [UUISubject.ShowShareButton]: boolean;
    [UUISubject.ShowAlertsButton]: boolean;
    [UUISubject.AlertsCount]: number;
    [UUISubject.RecommendationsCount]: number;
    [UUISubject.ShowHelpButton]: boolean;
    [UUISubject.ShowRecommendationsCartButton]: boolean;
    [UUISubject.ShowSubNav]: boolean;
    [UUISubject.HideRequestTraining]: boolean;
    [UUISubject.ShowRecommendButton]: boolean;
    [UUISubject.ShowRecommendButtonChecked]: boolean;
    [UUISubject.DisableRecommendButton]: boolean;
    [UUISubject.RecommendButtonClicked]: void;
};

export type UUISubjectData = {
    /**
     * Information used for legacy compatibility.
     */
    legacy: {
        /**
         * Legacy DOM event name that Costar Suite listens to, if applicable.
         *
         * Used for mapping new pub/sub events emitted from the nav to DOM events
         * consumed by costar suite.
         *
         * @see {@file ../index.ts} WebComponent entrypoint. This is used on mount.
         */
        domEventName?: string;

        /**
         * Legacy attribute name that Costar Suite modifies, if applicable.
         * Must be in lower-kebab-case.
         *
         * Used for mapping attribute-based communication from Costar Suite to
         * the nav. Whenever this attribute is mutated by Suite, a event is published
         * under the subject this data is for.
         */
        attrEventName?: string;
    };
};

export const uuiSubjectData: Record<UUISubject, UUISubjectData> = {
    [UUISubject.RequestTrainingClicked]: {
        legacy: {
            domEventName: "requestTrainingClicked",
        },
    },
    [UUISubject.ShareFeedbackClicked]: {
        legacy: {
            domEventName: "shareFeedbackClicked",
        },
    },

    [UUISubject.AlertButtonClicked]: {
        legacy: {
            domEventName: "alertsClicked",
        },
    },
    [UUISubject.FavoritesModeButtonClicked]: {
        legacy: {
            domEventName: "favoritesModeButtonClicked",
        },
    },
    [UUISubject.MenuTabSelected]: {
        legacy: {
            domEventName: "navItemClicked",
            attrEventName: "selected-tab",
        },
    },
    [UUISubject.SetSubnavLabel]: {
        legacy: {
            attrEventName: "subnav-label",
        },
    },
    [UUISubject.SetFavoritesCount]: {
        legacy: {
            attrEventName: "favorites-count",
        },
    },
    [UUISubject.BackButtonClicked]: {
        legacy: {
            domEventName: "backClicked",
        },
    },
    [UUISubject.RemoveButtonClicked]: {
        legacy: {
            domEventName: "removeClicked",
        },
    },
    [UUISubject.SaveButtonClicked]: {
        legacy: {
            domEventName: "saveClicked",
        },
    },
    [UUISubject.ReportsButtonClicked]: {
        legacy: {
            domEventName: "reportsClicked",
        },
    },
    [UUISubject.FavoriteButtonClicked]: {
        legacy: {
            domEventName: "favoriteClicked",
        },
    },
    [UUISubject.MoreButtonClicked]: {
        legacy: {
            domEventName: "moreButtonClicked",
        },
    },
    [UUISubject.CheckMarkClicked]: {
        legacy: {
            domEventName: "checkMarkClicked",
        },
    },
    [UUISubject.NextPrevButtonClicked]: {
        legacy: {
            domEventName: "activeRecordIndexChanged",
        },
    },
    [UUISubject.ActiveRecordIndex]: {
        legacy: {
            attrEventName: "active-record-index",
        },
    },
    [UUISubject.DetailedClickEvent]: {
        legacy: {
            attrEventName: "detailed-click-event",
        },
    },
    [UUISubject.ActiveProductKey]: {
        // Used only FOR LEASE / LEASING only
        legacy: {
            attrEventName: "active-product-key",
        },
    },
    [UUISubject.ResultSetTotalCount]: {
        legacy: {
            attrEventName: "result-set-total-count",
        },
    },
    [UUISubject.ResultSetTotalCountTooltipText]: {
        legacy: {
            attrEventName: "result-set-total-count-tooltip-text",
        },
    },
    [UUISubject.ShowBackButton]: {
        legacy: {
            attrEventName: "show-back-button",
        },
    },
    [UUISubject.BackButtonOverrideLabel]: {
        legacy: {
            attrEventName: "back-button-override-label",
        },
    },
    [UUISubject.ShowFavoriteButton]: {
        legacy: {
            attrEventName: "show-favorite-button",
        },
    },
    [UUISubject.NavbarButtons]: {
        legacy: {
            attrEventName: "navbar-buttons",
        },
    },
    [UUISubject.ShowFavoriteButtonChecked]: {
        legacy: {
            attrEventName: "show-favorite-button-checked",
        },
    },
    [UUISubject.ShowCheckMarkButton]: {
        legacy: {
            attrEventName: "show-check-mark-button",
        },
    },
    [UUISubject.ShowCheckMarkButtonChecked]: {
        legacy: {
            attrEventName: "show-check-mark-button-checked",
        },
    },
    [UUISubject.ShowRemoveButton]: {
        legacy: {
            attrEventName: "show-remove-button",
        },
    },
    [UUISubject.ShowReportsButton]: {
        legacy: {
            attrEventName: "show-reports-button",
        },
    },
    [UUISubject.ShowSaveButton]: {
        legacy: {
            attrEventName: "show-save-button",
        },
    },
    [UUISubject.ShowOmniSearch]: {
        legacy: {
            attrEventName: "show-omni-search",
        },
    },
    [UUISubject.ShowMoreButton]: {
        legacy: {
            attrEventName: "show-more-button",
        },
    },
    [UUISubject.ShowShareButton]: {
        legacy: {
            attrEventName: "show-share-button",
        },
    },
    [UUISubject.ShowAlertsButton]: {
        legacy: {
            attrEventName: "show-alerts-button",
        },
    },
    [UUISubject.AlertsCount]: {
        legacy: {
            attrEventName: "alerts-count",
        },
    },
    [UUISubject.RecommendationsCount]: {
        legacy: {
            attrEventName: "recommendations-count",
        },
    },
    [UUISubject.ShowHelpButton]: {
        legacy: {
            attrEventName: "show-help-button",
        },
    },
    [UUISubject.ShowRecommendationsCartButton]: {
        legacy: {
            attrEventName: "show-recommendations-cart-button",
        },
    },
    [UUISubject.ShowSubNav]: {
        legacy: {
            attrEventName: "show-subnav",
        },
    },
    [UUISubject.ShowFavoritesModeButton]: {
        legacy: {
            attrEventName: "show-favorites-mode-button",
        },
    },
    [UUISubject.ShowFavoritesModeButtonChecked]: {
        legacy: {
            attrEventName: "show-favorites-mode-button-checked",
        },
    },
    [UUISubject.HideRequestTraining]: {
        legacy: {
            attrEventName: "hide-request-training",
        },
    },
    [UUISubject.ShowRecommendButton]: {
        legacy: {
            attrEventName: "show-recommend-button",
        },
    },
    [UUISubject.ShowRecommendButtonChecked]: {
        legacy: {
            attrEventName: "show-recommend-button-checked",
        },
    },
    [UUISubject.DisableRecommendButton]: {
        legacy: {
            attrEventName: "disable-recommend-button",
        },
    },
    [UUISubject.RecommendButtonClicked]: {
        legacy: {
            domEventName: "recommendClicked",
        },
    },
};

/**
 * Maps attribute name set by suite -> pub/sub subject
 */
export const legacyAttributeToSubject = Object.entries(uuiSubjectData).reduce((acc, [subject, data]) => {
    const { attrEventName } = data.legacy;
    if (attrEventName) {
        acc[attrEventName] = subject as UUISubject;
    }
    return acc;
}, {} as Record<string, UUISubject>);

/**
 * Given a {@link UUISubject subject name}, gets the corresponding legacy attribute
 * name used on the UUI root web component.
 *
 * @param subject The subject name
 * @returns The corresponding attribute name, if any
 */
export const getAttrFor = (subject: UUISubject): string | undefined => uuiSubjectData[subject]?.legacy?.attrEventName;
