import React, { FC, useCallback } from "react";

import type { BoxProps } from "@costar/theme-ui";
import { Anchor, Box, List, ListItem, ListItemText, TextContainer } from "@costar/theme-ui";

import { useStrictI18N } from "../../hooks";

import { SearchItemOption } from "./types";

type RecentSearchProps = BoxProps & {
    title?: string;
    label?: string;
    message?: string;
    lastSearchURI?: string;
    hasLastSearch?: boolean;
    recentSearches: SearchItemOption[];
    handleClick: (SearchItemOption) => void;
};

export const RecentSearch: FC<RecentSearchProps> = props => {
    const resources = useStrictI18N();
    const {
        title,
        label = resources.typeAhead.recentSearchesPrompt.label(),
        message,
        lastSearchURI,
        hasLastSearch,
        recentSearches,
        handleClick,
        ...rest
    } = props;

    const lastSearchLabel = resources.typeAhead.searchType.lastSearch();
    const isRecentSearches = recentSearches !== null && recentSearches.length > 0;

    return (
        <Box color="text" {...rest}>
            {!message && title && (
                <Box backgroundColor="neutral.light" fontWeight="normal" p="xs">
                    <TextContainer>{title}</TextContainer>
                </Box>
            )}
            {!message && (hasLastSearch || isRecentSearches) && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    fontWeight="bold"
                    paddingLeft="xs"
                    paddingRight="xs"
                    paddingTop="xs-2"
                    paddingBottom="xs-2"
                    borderBottom="lightGray"
                >
                    {label}
                    {hasLastSearch && lastSearchURI !== "" && (
                        <Anchor href={lastSearchURI} fontWeight="normal" color="primary">
                            {lastSearchLabel}
                        </Anchor>
                    )}
                </Box>
            )}
            {!message &&
                isRecentSearches &&
                recentSearches.map(recentSearch => (
                    <RecentSearchEntry key={recentSearch.id} handleClick={handleClick} {...recentSearch} />
                ))}
            {message && <Box p="xs">{message}</Box>}
        </Box>
    );
};

type RecentSearchEntryProps = SearchItemOption & {
    handleClick: (search: SearchItemOption) => void;
};

const RecentSearchEntry = React.memo(function RecentSearchEntry(props: RecentSearchEntryProps) {
    const { handleClick, ...recentSearch } = props;
    const onClick = useCallback(() => handleClick(recentSearch), [handleClick, recentSearch]);

    return (
        <Box borderBottom="lightGray">
            <List
                sx={{
                    "&:hover": {
                        color: "white",
                        bg: "primaries.3",
                    },
                }}
                p="0"
            >
                <ListItem button as="a" color="black" onClick={onClick}>
                    <ListItemText primary={recentSearch.label} />
                </ListItem>
            </List>
        </Box>
    );
});
