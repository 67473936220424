import * as React from "react";
import { FC, useCallback, useEffect, useState } from "react";
import urlJoin from "url-join";

import { Anchor, Box, Button, Divider, Flex, Grid, Image, ModalPane, Text } from "@costar/theme-ui";

import realEstateIncludedIcon from "../../../assets/icons/real-estate-included-icon.png";
import realEstateNotIncludedIcon from "../../../assets/icons/real-estate-notincluded-icon.png";
import { appRoot, useConfigContext } from "../../../common";
import { useStrictI18N } from "../../hooks";

import { AddListingBuildingModalProps } from "./types";

const describeId = "add-listing-business-description-id";
const labelId = "add-listing-business-label-id";

if (process.env.NODE_ENV !== "production") {
    ModalPane.displayName ??= "ModalPane";
    Image.displayName ??= "Image";
    Box.displayName ??= "Box";
}

const AddListingBuildingModal: FC<AddListingBuildingModalProps> = props => {
    const { open, onClose, contactId, ...rest } = props;
    const {
        options: { navLinks },
    } = useConfigContext();
    const culturalResources = useStrictI18N();
    const [shouldClose, setShouldClose] = useState(false);

    const businessListingNoRealEstateUrl = `${navLinks.bizBuySell}${contactId ? `?cid=${contactId}` : ""}`;
    const businessListingWithRealEstateUrl = `${navLinks.listingManager}/listing/create/business-for-sale/`;

    const handleCloseClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setShouldClose(true);
            if (onClose) {
                onClose(event, "backdropClick");
            }
        },
        [onClose]
    );
    useEffect(() => {
        if (shouldClose) {
            setShouldClose(false);
        }
    }, [shouldClose]);

    return (
        <ModalPane
            automation-id="uui-add-listing-building-modal"
            aria-describedby={describeId}
            aria-labelledby={labelId}
            role="modal"
            variant="modal.cosmos"
            maxWidth="730px"
            maxHeight="100%"
            open={shouldClose ? false : open}
            onClose={onClose}
            {...rest}
        >
            <Flex relative gap="lg" flexDirection="column" alignContent="center" p="xl" width="100%">
                <Box absolute top="0" right="0" p="1em">
                    <Button
                        color="neutral.dark"
                        iconSize="xs"
                        iconTop="close"
                        onClick={handleCloseClick}
                        p="0"
                        variant="icon"
                    />
                </Box>
                <Grid gridTemplateColumns="1fr 10px 1fr" gap="lg">
                    <Text gridColumn="span 3" fontSize="xxl" fontWeight="light">
                        {culturalResources.addListing.forSale.businessModalTitle()}
                    </Text>
                    <Flex relative gap="xxl" flexDirection="column" alignContent="center" alignItems="center">
                        <Image src={realEstateIncludedIcon} />
                        <Anchor
                            variant="primary-button"
                            color="white !important"
                            marginBottom="xl"
                            target="_blank"
                            href={businessListingWithRealEstateUrl}
                        >
                            {culturalResources.addListing.forSale.businessModalYes()}
                        </Anchor>
                    </Flex>
                    <Flex relative flexDirection="column" alignContent="center" alignItems="center">
                        <Divider orientation="vertical" height="100%" />
                    </Flex>
                    <Flex relative gap="xxl" flexDirection="column" alignContent="center" alignItems="center">
                        <Image src={realEstateNotIncludedIcon} />
                        <Anchor
                            variant="primary-button"
                            color="white !important"
                            marginBottom="xl"
                            target="_blank"
                            href={businessListingNoRealEstateUrl}
                        >
                            {culturalResources.addListing.forSale.businessModalNo()}
                        </Anchor>
                    </Flex>
                </Grid>
            </Flex>
        </ModalPane>
    );
};

export default AddListingBuildingModal;
