import gql from "graphql-tag";

export const HEADER_QUERY = gql`
    query Header($activeProductKey: String, $culture: String) {
        user {
            apps(input: { activeProductKey: $activeProductKey, culture: $culture }) {
                id
                i18nKey
                text
                defaultHref
                href
                isUserSubscriber
                alternatePaths
                countriesSubscriptions
                ignorePaths
                isActive
                sections {
                    id
                    i18nKey
                    text
                    landingPath
                    baseUrl
                    href
                    alternatePaths
                    ignorePaths
                    isUserSubscriber
                    dropdownItems {
                        id
                        isHeader
                        i18nKey
                        text
                        landingPath
                        baseUrl
                        alternatePaths
                        ignorePaths
                        href
                        isUserSubscriber
                    }
                }
                hoverItems {
                    id
                    i18nKey
                    text
                    landingPath
                    baseUrl
                    href
                    isUserSubscriber
                }
            }
        }
        getUserInfo {
            contactId
            locationCountryCode
        }
    }
`;
