import { AcceptedLanguage } from "../../types";
/**
 * Gets a cookie stored within the document.
 *
 * @param cname Name of the cookie to retrieve
 * @returns The stored cookie's value, or an empty string if it doesn't exist
 *
 * @see setCookie
 */
export const getCookie = (cname: string): string => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
};

/**
 * Sets a cookie within the document.
 *
 * @param cname Cookie name
 * @param cvalue Cookie value
 * @param exdays Days until cookie expires
 *
 * @see getCookie
 */
export const setCookie = (cname: string, cvalue: string, exdays: number) => {
    let expires = "";

    if (exdays < 0) {
        expires = "Thu, 01 Jan 1970 00:00:00 UTC";
    } else {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        expires = "expires=" + d.toUTCString();
    }

    const domain = window.location.hostname.indexOf(".costar.com") > -1 ? ".costar.com" : "";

    document.cookie = `${cname}=${cvalue}; ${expires}; path=/; domain=${domain}`;
};

export const mapLangToCultureCode = (
    supportedLocales: AcceptedLanguage[],
    lang?: string,
    akamaiCulture?: string
): AcceptedLanguage => {
    // eslint-disable-next-line no-nested-ternary
    return lang
        ? supportedLocales.includes(lang as AcceptedLanguage)
            ? (lang as AcceptedLanguage)
            : "en-US"
        : "en-US";
};

export const getCookiesPreferCulture = (preferNewsCookie = false): string | undefined => {
    const selectedCultureGlobalized = getCookie("selectedCultureGlobalized");
    const selectedCulture = getCookie("selectedCulture");
    const csNewsContext = JSON.parse(getCookie("cs_newsContext") || "{}") as Record<string, string | undefined>;

    return (
        selectedCultureGlobalized ||
        (preferNewsCookie ? csNewsContext["culture"] || selectedCulture : selectedCulture || csNewsContext["culture"])
    );
};
