import configuration from "@costar/configuration";
import fetch from "@costar/costar-fetch";
import Logger, { EndpointWriter, WebConsoleWriter } from "@costar/logger/client";
import type { Level } from "@costar/logger/dist/common/interfaces/helpers";

import { onLog } from "../common";

export const configureClientLogger = (appName: string, options?: { level: Level }): void => {
    // configure error logger
    const LOG_ERROR_URL = `${appName}/api/log-error`;
    let logLevel: Level = options?.level || "error";
    if (!options) {
        logLevel = (configuration.get("options")?.logLevel as Level) || logLevel;
    }
    const consoleWriter = new WebConsoleWriter({
        console: {
            // we can't just pass "console" because we are going to patch the "log method so any
            // code that uses it directly will go through our logger
            // FIXME(don): Will this interfere with other MFEs?
            log: window.console.log,
        },
        options: {
            level: logLevel,
        },
    });

    const endpointWriter = new EndpointWriter({
        request: fetch,
        consoleWriter,
        options: {
            url: LOG_ERROR_URL,
            level: logLevel,
            useFetch: true,
            onLog: (obj: any) => {
                if (obj.message.startsWith(`POST ${LOG_ERROR_URL}`)) {
                    return false;
                }
                obj.other = {
                    url: window.location.href,
                    screen: {
                        width: window.innerWidth,
                        height: window.innerHeight,
                    },
                };
            },
        },
    });

    if (logLevel === "debug") {
        // devs don't want errors to be persisted on app restart
        endpointWriter.clearLocalStorage();
    }

    const logger = new Logger({
        level: logLevel,
        writer: [consoleWriter, endpointWriter],
        onLog,
    });

    configuration.set("logger", logger);
};
