import { useCallback, useEffect, useRef } from "react";

/**
 * Wraps a function that may be constantly changing to create a new function
 * that maintains both the same object reference and the expected behavior of
 * the original function.
 *
 * What does that mean?
 *
 * Often times we (accidentally) create lambda functions within a react
 * component, or a callback created with {@link useCallback} gets unecessarily
 * re-created due to a malformed dependency array. This hook allows consumers of
 * such functions to prevent unnecessary re-renders or execution of
 * {@link useEffect effectful code}.
 *
 *
 * @example
 * ```ts
 * import React from "react";
 * import { useStableCallback } from "../hooks";
 *
 * // Because onClick is an anonymous function created within Child's props,
 * // it will never be "the same". Child's props will therefore always be
 * // different.
 * const Parent: FC = () => <Child onClick={() => console.log("I was clicked!")} />;
 *
 * const Child: FC<{ onClick: () => void }> = ({ onClick }) => {
 *     // the memory reference to stableOnClick will never change, and will therefore
 *     // pass an Object.is check. It is safe to use within a dependency array.
 *     const stableOnClick = useStableCallback(onClick)
 *     return <button onClick={stableOnClick}>"click me!"</button>
 * }
 *```

 * @param callback The potentially unstable callback function
 * @returns A function with the exact same behavior as `callback`, but whose
 * object reference will never change.
 */
export function useStableCallback<F extends (...args: any) => any>(callback: F): F;
export function useStableCallback<F extends (...args: any) => any>(
    callback: undefined
): (...args: Parameters<F>) => undefined;
export function useStableCallback<F extends (...args: any) => any>(
    callback: F | undefined
): (...args: Parameters<F>) => ReturnType<F> | undefined;
export function useStableCallback<F extends (...args: any) => any>(callback: F | undefined): F {
    const cb = useRef<F | undefined>(callback);

    useEffect(() => {
        cb.current = callback;
    }, [callback]);

    const stableCb: F = useCallback((...args) => cb?.current?.(...args), []) as unknown as F;

    return stableCb;
}
