import gql from "graphql-tag";

import { SEARCH_ITEM_PARTS } from "./fragments";

export const OMNI_SEARCH_QUERY = gql`
    query OmniSearch(
        $term: String
        $searchType: SearchType = ALL_PROPERTIES
        $countryCode: String
        $secondaryLookup: Boolean
        $preqinEnabled: Boolean
    ) {
        search {
            all(
                input: { term: $term, type: $searchType, countryCode: $countryCode, secondaryLookup: $secondaryLookup }
                features: { preqinEnabled: $preqinEnabled }
            ) {
                entries {
                    ...SearchItemParts
                }
            }
        }
    }
    ${SEARCH_ITEM_PARTS}
`;

export const RECENTLY_VIEWED_QUERY = gql`
    query RecentlyViewed($culture: String) {
        search {
            recentlyViewed(culture: $culture) {
                userEntities {
                    UserEntityId
                    verticalTag
                    entityTag
                    groupTag
                    instance
                    productType
                    value {
                        list {
                            address
                            id
                            idType
                            lvt
                            redirect {
                                href
                            }
                        }
                    }
                    status
                    updatedTime
                    isDefault
                }
            }
        }
    }
`;
