import { CountryCode } from "$graphql/core";

/**
 * @internal
 * @param country
 */
export const setCultureCookie = (country: CountryCode): void => {
    document.cookie = `selectedCulture=${countryToCulture(country)};domain=costar.com ;path=/`;
};

/**
 * @internal
 * @param country
 * @returns
 */
export const countryToCulture = (country: CountryCode) => {
    switch (country) {
        case "CA":
            return "en-CA";
        case "GB":
            return "en-GB";
        default:
            return "en-US";
    }
};
