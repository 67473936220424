import React, { useCallback } from "react";

import { Anchor, Flex, Icon, SwitchContent } from "@costar/theme-ui";

import { isBrowser, ROOT_URL } from "../../../common";
import { SearchTypeAhead } from "../type-ahead";

export type CenterMastheadContentProps = {
    /**
     * @default {@link ROOT_URL}
     */
    rootUrl?: string;

    /**
     * @default false
     */
    showOmniSearch?: boolean;
};

const CenterContent: React.FC<CenterMastheadContentProps> = props => {
    const { rootUrl = ROOT_URL, showOmniSearch } = props;
    const onLogoClick = useCallback(() => {
        window.location.href = rootUrl;
    }, [rootUrl]);

    const FullLogo = React.memo(() => (
        <Icon icon="costarLogo" height={32} width={117} automation-id="uui-costar-anchor" />
    ));
    const IconLogo = React.memo(() => <Icon icon="costarWheel" size={32} automation-id="uui-costar-anchor" />);

    if (process.env.NODE_ENV !== "production") {
        FullLogo.displayName = "FullLogo";
        IconLogo.displayName = "IconLogo";
    }
    // FIXME: mediaQuery prop is required by SwitchContentProps interface but
    // has a default value set by the component. This is a bug in theme-ui
    return (
        <Flex gap="xs" flexWrap="nowrap" overflow="hidden" alignItems="center">
            <Anchor href="#" onClick={onLogoClick}>
                {isBrowser() && window && !window.location.href.includes("/home") ? (
                    <SwitchContent mediaQuery="min-width" replacementContent={[null, <FullLogo />]}>
                        <IconLogo />
                    </SwitchContent>
                ) : (
                    <FullLogo />
                )}
            </Anchor>
            {showOmniSearch && <SearchTypeAhead />}
        </Flex>
    );
};

export default CenterContent;
