/**
 * This file is intentionally not co-located with the authenticated masthead
 * due to it's close relationship (dependency-wise) with the hamburger menu.
 * This is due to quirks with the @costar/universal-menu package.
 */
import React, { AriaRole, ComponentProps, MutableRefObject, PropsWithoutRef, useEffect } from "react";

import { styled, Theme } from "@costar/theme-ui";

import { menuButton } from "./constants";

/**
 * This component interfaces between the UUI and the `universal-menu`. As such,
 * it's implementation is a little funky.
 */
export const HamburgerIcon = React.forwardRef<
    HTMLSpanElement,
    // PropsWithoutRef<Parameters<typeof HamburgerIconContainer>[0]>
    PropsWithoutRef<ComponentProps<typeof HamburgerIconContainer>>
>((props, ref) => {
    // Monkey-patch the universal-menu's icon on mount.
    useEffect(() => {
        const menuButtonElem = (ref as MutableRefObject<HTMLSpanElement>)?.current?.querySelector(menuButton.buttonId);
        if (menuButtonElem) {
            menuButtonElem.setAttribute("role", "button" as AriaRole);
            // TODO: set aria-expanded, if possible
        }
    }, [ref]);

    return <HamburgerIconContainer {...props} ref={ref} />;
});

// padding: 8px 16px;
const HamburgerIconContainer = styled.span`
    position: relative;
    padding-left: 16px;
    height: 26px;
    ${/* size includes border, not just content */ ""}
    box-sizing: border-box;
    border-right: ${props => props.theme["secondary.dark.thicker"]};
    border-top: ${props => props.theme["secondary.dark.thicker"]};

    & #${menuButton.buttonId}:hover {
        opacity: 0.6;
    }

    & #${menuButton.buttonId} {
        display: inline-flex;
    }

    & #${menuButton.labelId} {
        display: none;
    }
`;

if (process.env.NODE_ENV !== "production") {
    HamburgerIcon.displayName = "HamburgerIcon";
    HamburgerIconContainer.displayName = "HamburgerIconContainer";
}
