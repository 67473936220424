export interface HydrationContext {
    /**
     * Initial HTML attributes specified by the consuming application via query
     * string parameters. Used for resolving client side attribute changes made
     * before connectedCallback
     */
    initialAttributes: Record<string, string | string[] | undefined>;

    /**
     * Emotion class ids extracted during SSR.
     */
    emotionIds: string[];
}

export type HydrationContextKey = "__UUI_HYDR_CTX";
export const HYDRATION_CONTEXT_KEY: HydrationContextKey = "__UUI_HYDR_CTX";

export const renderHydrationContext = (ctx: HydrationContext): string =>
    `window.${HYDRATION_CONTEXT_KEY} = ${JSON.stringify(ctx)};`;

export const checkHydrationContext = (ctx: unknown): ctx is HydrationContext => {
    if (typeof ctx !== "object" || ctx == null) return false;
    const hydrationContext = ctx as Record<keyof HydrationContext, unknown>;

    // check initialAttributes
    if (!hydrationContext["initialAttributes"] || typeof hydrationContext["initialAttributes"] !== "object")
        return false;

    // check emotionIds
    if (!Array.isArray(hydrationContext["emotionIds"])) return false;

    return true;
};

// typeof ctx === "object" && ctx != null && ("initialAttributes" in ctx && typeof ctx["initialAttributes"]] === "object");
