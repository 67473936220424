import React, { FC, useCallback, useMemo } from "react";

import { ProductId } from "../../../common";
import { MainMenu } from "../../shared/main-menu";
import { useUnauthNavConfigContext } from "../nav-config.context";

import { useCurrentTab } from "$client/hooks";
import UpsellModal, { UpsellModalProps } from "$client/shared/upsell-modal";

export type UnauthMainMenuProps = {
    selection: ProductId;
    setSelection: (tabId: ProductId) => void;
};
export const UnauthMainMenu: FC<UnauthMainMenuProps> = ({ selection, setSelection }) => {
    const navConfig = useUnauthNavConfigContext();
    // const [selection, setSelection] = useState<ProductId>(navConfig[0].id);
    const onUpsellModalClose = useCallback(() => setSelection(navConfig[0].id), [setSelection, navConfig]);
    const currentTab = useCurrentTab(navConfig, selection);

    const modalData: Omit<UpsellModalProps, "onClick"> = useMemo(() => {
        const { id: productKey, isUserSubscriber } = currentTab;
        return {
            productKey,
            open: !isUserSubscriber,
        };
    }, [currentTab]);

    return (
        <>
            <MainMenu selection={selection} setSelection={setSelection} navConfig={navConfig} />
            <UpsellModal onClose={onUpsellModalClose} {...modalData} />
        </>
    );
};
