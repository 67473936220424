import React, { FC, useMemo } from "react";

import { Box } from "@costar/theme-ui";

import { ConnectedSubMenu } from "../auth/sub-menu";

import { AuthMainMenu } from "./main-menu/auth-main-menu";
import { ConnectedUpsellModal } from "./upsell/connected-upsell-modal";
import { AuthMasthead } from "./masthead";
import { AuthNavConfigContextProvider, useNavConfigQuery } from "./nav-config";

import { useCulture } from "$client/hooks";

export const App: FC = () => {
    const cultureCode = useCulture();
    const {
        loading,
        data: { navConfig, userInfo },
    } = useNavConfigQuery(cultureCode);
    const authNavConfigData = useMemo(() => ({ loading, navConfig, userInfo }), [loading, navConfig, userInfo]);

    return (
        <AuthNavConfigContextProvider value={authNavConfigData}>
            <Box width="100%" as="header">
                <AuthMasthead />
                <AuthMainMenu />
                <ConnectedSubMenu />
                <ConnectedUpsellModal />
            </Box>
        </AuthNavConfigContextProvider>
    );
};
