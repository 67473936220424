// this is a copy of experimental feature in suite
// sync with suite: packages\shared-react-hooks\src\hooks\use-cosmos-theme.ts

import React, { useMemo } from "react";

export const useCosmosTheme = (defaultCosmosTheme, requestHeaders?: Record<string, string>) => {
    const CosmosTheme = useMemo(() => {
        if (!requestHeaders) {
            return defaultCosmosTheme;
        }
        const customFont = getCustomFont(requestHeaders);
        const forcedFont = customFont?.fontFamily;
        if (!forcedFont) {
            return defaultCosmosTheme;
        }
        return {
            ...defaultCosmosTheme,
            fonts: {
                ...defaultCosmosTheme.fonts,
                body: `${forcedFont}, Arial, sans-serif`,
                heading: `${forcedFont}, Arial, sans-serif`,
            },
        };
    }, [defaultCosmosTheme, requestHeaders]);

    return CosmosTheme;
};

// this is a copy of font experimental feature in suite
// sync with suite: packages\core\src\utilities\custom-header-parser.ts

const REQ_HEADER_FORCED_FONT = "cs-features-font";

export const getCustomFont = (
    requestHeaders: Record<string, string>,
    addGooglePreconnect: boolean = false
):
    | {
          fontFamily: string;
          embedFontCode?: string;
      }
    | undefined => {
    const reqForcedFont = requestHeaders?.[REQ_HEADER_FORCED_FONT];
    if (!reqForcedFont) {
        return;
    }

    if (reqForcedFont.includes("https") && reqForcedFont.includes("css")) {
        // e.g. cs-features-font: Inter|https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap
        const [fontFamily, url] = reqForcedFont.split("|", 2);
        if (addGooglePreconnect && /^https:\/\/fonts.googleapis.com\//.test(url ?? "")) {
            return {
                fontFamily,
                embedFontCode: `
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href=${url} rel="stylesheet">`,
            };
        }

        return {
            fontFamily,
            embedFontCode: `<link href=${url} rel="stylesheet">`,
        };
    }

    return {
        fontFamily: reqForcedFont,
    };
};
