import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Anchor, Box, Button, Flex, Icon, Image, ModalPane, Text } from "@costar/theme-ui";

import strLogo from "../../../assets/icons/str-updated-logo.svg";
import { useConfigContext } from "../../../common";
import { useStrictI18N } from "../../hooks";

import buildUpsellData from "./build-upsell-data";
import { UpsellModalProps } from "./types";

const describeId = "upsell-modal-describe-id";
const labelId = "upsell-modal-label-id";

const UpsellModal: FC<UpsellModalProps> = props => {
    const { productKey, open, loginUrl, onClose, ...rest } = props;
    const culturalResources = useStrictI18N();
    const { akamaiCulture, options } = useConfigContext();
    const isUK = akamaiCulture === "en-GB";
    const data = useMemo(
        () => buildUpsellData(culturalResources, isUK, productKey, loginUrl || options?.navLinks?.login),
        [culturalResources, isUK, loginUrl, productKey, options?.navLinks?.login]
    );
    const [shouldClose, setShouldClose] = useState(false);

    const handleCloseClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setShouldClose(true);
            if (onClose) {
                onClose(event, "backdropClick");
            }
        },
        [onClose]
    );
    useEffect(() => {
        if (shouldClose) {
            setShouldClose(false);
        }
    }, [shouldClose]);

    return (
        <ModalPane
            aria-describedby={describeId}
            aria-labelledby={labelId}
            data-testid="upsell-modal"
            variant="modal.cosmos"
            maxWidth="580px"
            maxHeight="100%"
            open={shouldClose ? false : open}
            onClose={onClose}
            modalProps={{
                width: "100vw",
                height: "100vh",
            }}
            {...rest}
        >
            <Flex relative gap="lg" flexDirection="column" alignContent="center" p="xl">
                <Box absolute top="0" right="0" p="1em">
                    <Button
                        color="neutral.dark"
                        iconSize="xs"
                        iconTop="close"
                        title="Close"
                        onClick={handleCloseClick}
                        p="0"
                        variant="icon"
                    />
                </Box>
                <Flex flexDirection="row" alignSelf="center" gap="sm">
                    <Icon icon="costarLogo" height={36} width={135} />
                    {productKey === "STR" && <Image src={strLogo} height={36} width={96} />}
                </Flex>
                <Text fontSize="md" fontWeight="bold" textAlign="center" m="0px 80px">
                    {data.message.line1}
                </Text>
                <Text fontSize="md" fontWeight="body" textAlign="center" m="0px 65px 16px">
                    {data.message.line2}
                </Text>
                <Flex justifyContent="center" gap="lg" paddingBottom="md">
                    {data.links.map(({ label, variant, url }) => (
                        <Anchor key={`upsell-link-${label}`} variant={variant} px="xs" height="2em" href={url}>
                            {label}
                        </Anchor>
                    ))}
                </Flex>
                <Flex fontSize="xs" justifyContent="center" gap=".2em">
                    <Text>{data.subscription.message}</Text>
                    <Anchor variant="hover-primary" color="primary.contrast" href={data.subscription.url} p="0">
                        {data.subscription.clickLabel}
                    </Anchor>
                </Flex>
            </Flex>
        </ModalPane>
    );
};

export default UpsellModal;
