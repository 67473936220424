import { useEffect } from "react";

/**
 * A function, given references to elements, that checks if mouse was clicked inside those elements.
 *
 * @param refs array of references to components
 * @param handleOutsideClick the function to run if mouse was clicked outside the given elements.
 *
 * @returns `true` if `a` is equal to `b`.
 */

//export const useOutsideAlerter = <T>(refs: React.MutableRefObject<T>[], handleOutsideClick: () => void): void => {

export const useOutsideAlerter = (refs: React.MutableRefObject<any>[], handleOutsideClick: () => void): void => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            let clickedInside = false;
            refs.forEach(r => {
                if (r.current && r.current.contains(event.target)) {
                    clickedInside = true;
                }
            });
            if (clickedInside === false) {
                handleOutsideClick();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refs, handleOutsideClick]);
};
