import type { NormalizedCacheObject } from "./apollo-packages";
import { ApolloClient } from "./apollo-packages";

import { ParsedUserPreferences as UserPreferences } from "$types";

type SerializedCacheState = {
    apolloCache: NormalizedCacheObject;
    preferences: UserPreferences;
};
type DeserializedCacheState<S extends SerializedCacheState> = SerializedCacheState &
    Partial<Omit<S, keyof SerializedCacheState>>;

// type Extension<S extends SerializedCacheState> = {
//     /**
//      * Transform/hydrate an {@link NormalizedCacheObject Apollo Client's Cache}
//      * before it's persisted in browser storage.
//      *
//      * @param cache
//      * @returns the transformed cache state. This will be stored for later user.
//      */
//     transform: (cache: NormalizedCacheObject) => S;
//     /**
//      * Perform cache invalidation on state loaded from storage.
//      *
//      * @param cache The cache state loaded from storage
//      * @returns `true` if the cache is valid and should not be invalidated, `false` otherwise
//      */
//     checkCache: (cache: DeserializedCacheState<S>) => boolean;
// }
const noop = <T>(x: T) => x;
interface ApolloCacheManagerOptions {
    key: string;
    clearBeforeStore?: boolean;
    // transform: (cache: NormalizedCacheObject) => S;
    checkCache?: (cache: DeserializedCacheState<SerializedCacheState>) => boolean;
}
export class ApolloCacheManager {
    private key: string;
    private clearBeforeStore: boolean;
    private static DEFAULT_CACHE: NormalizedCacheObject = {};

    constructor(options: ApolloCacheManagerOptions) {
        this.key = options.key;
        this.clearBeforeStore = options.clearBeforeStore ?? false;

        this.store = this.store.bind(this);
        this.load = this.load.bind(this);
    }

    /**
     * Store the the Apollo Client's current cache into session storage.
     *
     * @param preferences The user's preferences, which are stored with the
     * client's cache
     */
    public store(client: ApolloClient<NormalizedCacheObject>, preferences: UserPreferences | undefined): void {
        if (!preferences) return;

        if (this.clearBeforeStore) {
            client.cache.gc();
        }
        // const cache = client.cache.extract();
        // const state: SerializedCacheState = {
        //     apolloCache: cache,
        //     preferences,
        // };

        // try {
        //     const serializedState = JSON.stringify(state);
        //     sessionStorage.setItem(this.key, serializedState);
        // } catch (e) {
        //     sessionStorage.removeItem(this.key);
        // }
    }

    /**
     * Load the Apollo Client's cache from browser storage.
     *
     * The cache will be invalidated if the user's current preferences don't
     * match the preferences that were stored with the cache.
     *
     * @param preferences The user's current preferences
     * @returns a {@link NormalizedCacheObject cache} that can safely be used to
     * hydrate the cache of an {@link ApolloClient}
     */
    public load(preferences: UserPreferences | undefined): NormalizedCacheObject {
        try {
            // unauth
            if (!preferences) return ApolloCacheManager.DEFAULT_CACHE;

            // support SSR
            if (typeof window === "undefined" || typeof sessionStorage === "undefined") {
                return ApolloCacheManager.DEFAULT_CACHE;
            }

            // return default if no cache is found,
            const serializedState = sessionStorage.getItem(this.key);
            if (!serializedState) {
                return ApolloCacheManager.DEFAULT_CACHE;
            }

            // or if the cache is invalid
            const parsedState = JSON.parse(serializedState) as Partial<SerializedCacheState>;
            if (
                !this.areCachedMembersPresent(parsedState) ||
                !this.arePreferencesEqual(parsedState.preferences, preferences)
            ) {
                return ApolloCacheManager.DEFAULT_CACHE;
            }

            return parsedState.apolloCache;
        } catch (e) {
            return ApolloCacheManager.DEFAULT_CACHE;
        }
    }

    private arePreferencesEqual(cached: UserPreferences, current: UserPreferences): boolean {
        const storedKeys = Object.keys(cached) as (keyof UserPreferences)[];
        const currentKeys = Object.keys(current) as (keyof UserPreferences)[];
        return (
            storedKeys.length === currentKeys.length && storedKeys.every(key => Object.is(current[key], cached[key]))
        );
    }

    private areCachedMembersPresent(state: Partial<SerializedCacheState>): state is SerializedCacheState {
        return !!state.apolloCache && !!state.preferences;
    }
}
