import React, { FC, useCallback, useRef } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { useBoolean } from "@costar/theme-ui";
import { PopoverSelectContainer } from "@costar/theme-ui-select";

import { useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

const MoreButton: FC = () => {
    const [isPopoverOpen, popoverOpenAction] = useBoolean();

    const moreButtonRef = useRef<HTMLButtonElement>(null);

    const culturalResources = useStrictI18N();

    const handleOnMoreClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.MoreButtonClicked]>(UUISubject.MoreButtonClicked).notify({
            container: moreButtonRef.current,
            open: isPopoverOpen,
        });
        popoverOpenAction.on();
    }, [isPopoverOpen, popoverOpenAction]);

    const handleOnMoreClose = useCallback(() => popoverOpenAction.off(), [popoverOpenAction]);
    const chevron = { color: "neutral.dark" };

    return (
        <PopoverSelectContainer
            ref={moreButtonRef}
            id={"costar-more-button"}
            role={"uui-more-button"}
            automation-id={"uui-more-button"}
            sx={{
                height: "28px",
                fontSize: "xs",
            }}
            onClose={handleOnMoreClose}
            icon={chevron}
            open={isPopoverOpen}
            onOpen={handleOnMoreClick}
            paneProps={{
                fontSize: "xs",
            }}
            popoverProps={{
                disableScrollLock: true,
                children: <span data-testid={"popover-more-container"} />,
            }}
        >
            {culturalResources?.submenu?.detail?.label?.more()}
        </PopoverSelectContainer>
    );
};

export default React.memo(MoreButton);
