import React, { FC, useMemo } from "react";

import { Button, Tooltip } from "@costar/theme-ui";

import { MixedAnalyticsTooltip, MixedAnalyticsTooltipProps } from "./tooltip-content/mixed-analytics-tooltip";

import { ResultSetTotalCountTooltipConfig } from "$common/state/state";

interface TooltipRendererProps {
    tooltipMessage: string | ResultSetTotalCountTooltipConfig;
}

export const TooltipRenderer: FC<TooltipRendererProps> = props => {
    const { tooltipMessage } = props;
    const tooltipProps = useMemo(() => {
        if (typeof tooltipMessage === "string") {
            return { title: tooltipMessage };
        } else {
            const { tooltipComponentType, data } = tooltipMessage;
            //Add custom tooltip types here
            switch (tooltipComponentType) {
                case "MixedAnalyticsTooltip":
                    return {
                        title: <MixedAnalyticsTooltip {...(data as MixedAnalyticsTooltipProps)} />,
                        interactive: true,
                        maxWidth: 461,
                    };
                default:
                    return { title: "" };
            }
        }
    }, [tooltipMessage]);

    return (
        <Tooltip arrow {...tooltipProps}>
            <Button icon="info" variant="ghost" />
        </Tooltip>
    );
};
