import { DocumentNode, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";

import { canUseDOM } from "../../common";

const makeUseIsomorphicQuery: () => <TData>(
    query: DocumentNode,
    options: QueryHookOptions & { initialData?: TData | undefined }
) => QueryResult<TData> = () => {
    // Webpack _should_ notice that canUseDOM will always be true on the browser and
    // false on the server, causing the false branch to be removed during compilation
    if (canUseDOM) {
        // Use useQuery on client. Provide `initialData` while loading
        return function useIsomorphicQuery<TData>(
            query: DocumentNode,
            options: QueryHookOptions & { initialData?: TData | undefined }
        ): QueryResult<TData> {
            const response = useQuery<TData>(query, options);

            if (response.loading && options.initialData) {
                response.data = options.initialData;
            }

            return response;
        };
    } else {
        // On server, use stub hook that only returns initial data
        return function useIsomorphicQuery<TData>(
            _query: DocumentNode,
            options: QueryHookOptions & { initialData?: TData | undefined }
        ): QueryResult<TData> {
            // TODO: Populate this object with subs for rest of query result properties
            return { loading: true, data: options.initialData } as QueryResult<TData>;
        };
    }
};

/**
 * Similar to {@link useQuery}, with two important changes:
 * 1. If an `initialData` property is provided, it will used as the value of
 *    `data` while the query is loading
 * 2. This hook will work on both the server and the client
 *
 * @note this should not be used by the unauthenticated nav as we must
 * completely avoid GraphQL queries to keep [TTI](https://web.dev/interactive/)
 * as low as possible.
 *
 * @returns
 *
 * @see {@link useQuery}
 */
export const useIsomorphicQuery = makeUseIsomorphicQuery();
