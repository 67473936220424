import React, { FC, PropsWithChildren, useContext, useMemo } from "react";

import type Logger from "@costar/logger";

import getLogger from "$common/log";

const LoggerContext = React.createContext<Logger>(console as unknown as Logger);

export const useLogger = (): Logger => React.useContext(LoggerContext);
export const LoggerConsumer = LoggerContext.Consumer;
export const LoggerProvider: FC<PropsWithChildren<{ logger?: Logger }>> = ({ children, logger: loggerOverride }) => {
    const logger = useMemo(() => loggerOverride || getLogger(), [loggerOverride]);
    return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};
