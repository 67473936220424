import React, { type ForwardedRef, useMemo } from "react";

import { Anchor, Badge, Icon } from "@costar/theme-ui";

export type AlertButtonProps = {
    alertsCount?: number | undefined;
    onClick: () => void;
};

/**
 *
 * TODO(don): Should this contain notify() logic or should that be passed down
 * as a prop?
 *
 * @param props
 * @param ref
 *
 * @returns
 */
const AlertButton = ({ onClick, alertsCount }: AlertButtonProps, ref: ForwardedRef<HTMLElement>) => {
    const extraProps = useMemo(() => {
        if (!alertsCount) {
            return {};
        } else if (alertsCount == -1) {
            const dot: "dot" | "standard" | undefined = "dot";
            return { type: dot, badgeContent: 1 };
        } else {
            const standard: "dot" | "standard" | undefined = "standard";
            return { type: standard, badgeContent: alertsCount, max: 99 };
        }
    }, [alertsCount]);

    return (
        <Anchor
            data-testid="alert-button"
            role="menuitem"
            aria-label="Alerts"
            automation-id="uui-alerts-button"
            onClick={onClick}
            ref={ref}
        >
            <Badge variant="accent" fontSize="lg" overlap="circular" {...extraProps}>
                <Icon p="0" icon="alert" color="neutrals.10" size="xxl" />
            </Badge>
        </Anchor>
    );
};

export default React.memo(React.forwardRef<HTMLElement, AlertButtonProps>(AlertButton));
