import React from "react";

import { Anchor, Divider, Flex } from "@costar/theme-ui";

import { HamburgerIcon } from "../hamburger-menu";
import { HamburgerMenu } from "../hamburger-menu";
import { SearchPropertyDetailTypeAhead } from "../type-ahead/search-property-detail-type-ahead";

import { LinkItem } from "./types";

/**
 * @todo rename
 */
export type LeftMastheadContentProps = {
    hamburgerIconRef?: React.RefObject<HTMLElement>;
    links?: LinkItem[];
    showDetailOmniSearch?: boolean;
};

const LeftContent: React.FC<LeftMastheadContentProps> = props => {
    const { hamburgerIconRef, links = [], showDetailOmniSearch } = props;

    if (!hamburgerIconRef) {
        throw new Error("hamburgerIconRef is undefined");
    }
    // FIXME: mediaQuery prop is required by SwitchContentProps interface but
    // has a default value set by the component. This is a bug in theme-ui
    return (
        <Flex flexWrap="nowrap" alignItems="center" automation-id="uui-masthead-left-content">
            <HamburgerIcon ref={hamburgerIconRef} />
            <HamburgerMenu hamburgerIconRef={hamburgerIconRef} />
            {showDetailOmniSearch && <SearchPropertyDetailTypeAhead showDetailOmniSearch />}
            {links.map(link => (
                <Anchor key={link.url} variant="hover-primary" href={link.url}>
                    {link.label}
                </Anchor>
            ))}
        </Flex>
    );
};

export default LeftContent;
