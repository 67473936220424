import * as React from "react";
import { useCallback, useRef } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { Theme } from "@costar/theme-ui";
import { Box, Flex, useThemeUI } from "@costar/theme-ui";

import { AcceptedLanguage } from "../../../types";
import { UUISubject } from "../../subjects";

import AlertButton from "./buttons/alert-button";
import HelpFeedbackButton from "./buttons/help-feedback-button";
import AuthAddListingDropdown from "./auth-add-listing-dropdown";
import { RecommendationsCartButton } from "./recommendations-cart-button";

function hideAtMobileBreakpoint(theme: Theme) {
    const { breakpoints } = theme;
    return breakpoints
        ? {
              [`${breakpoints.down(0)}`]: {
                  display: "none",
              },
          }
        : {};
}

export type RightMastheadContentProps = {
    loginUrl?: string;
    loginLabel?: string;
    initialLanguage?: AcceptedLanguage;
    availableLanguageOptions?: Partial<Record<AcceptedLanguage, string>>;
    showAlertsButton?: boolean;
    alertsCount?: number;
    showRecommendationsCartButton?: boolean;
    recommendationsCount?: number;
    showHelpButton?: boolean;
    hideRequestTraining?: boolean;
};

const RightContent: React.FC<RightMastheadContentProps> = props => {
    const { showAlertsButton, showHelpButton, hideRequestTraining, showRecommendationsCartButton } = props;
    const { theme } = useThemeUI() as { theme: Theme };
    const alertButtonRef = useRef<HTMLAnchorElement>(null);

    const onAlertClick = useCallback(() => {
        subject(UUISubject.AlertButtonClicked).notify({
            container: alertButtonRef.current,
        });
    }, []);

    return (
        <Flex
            gap="sm"
            alignItems="center"
            flexWrap="nowrap"
            paddingRight="lg"
            automation-id="uui-masthead-right-content"
        >
            {showAlertsButton && !showHelpButton && (
                <>
                    <Box width="25.33px"></Box>
                    <AlertButton alertsCount={props.alertsCount} onClick={onAlertClick} ref={alertButtonRef} />
                </>
            )}
            {!showAlertsButton && showHelpButton && (
                <>
                    <Box width="24px"></Box>
                    <HelpFeedbackButton hideRequestTraining={hideRequestTraining} />
                </>
            )}
            {!showAlertsButton && !showHelpButton && (
                <>
                    <Box width="59.34px"></Box>
                </>
            )}
            {showAlertsButton && showHelpButton && (
                <>
                    <AlertButton alertsCount={props.alertsCount} onClick={onAlertClick} ref={alertButtonRef} />
                    <HelpFeedbackButton hideRequestTraining={hideRequestTraining} />
                </>
            )}
            {showRecommendationsCartButton && (
                <RecommendationsCartButton recommendationsCount={props?.recommendationsCount} />
            )}
            <AuthAddListingDropdown marginLeft="xs" sx={hideAtMobileBreakpoint(theme)} />
        </Flex>
    );
};

export default RightContent;
