// fallback of requestIdleCallback from https://developer.chrome.com/blog/using-requestidlecallback/
// requestIdleCallback is not supported in some browsers, such as Safari. https://caniuse.com/requestidlecallback

type IdleCallback = (deadline: IdleDeadline) => void;

export function requestIdleCallbackFallback(callback: IdleCallback, options?: { timeout: number }): number {
    // Check if the environment supports requestIdleCallback
    if (window.requestIdleCallback) {
        return window.requestIdleCallback(callback, options);
    } else {
        // Fallback using setTimeout
        const start = Date.now();
        return window.setTimeout(() => {
            callback({
                didTimeout: false,
                timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
            } as IdleDeadline);
        }, 1);
    }
}

export function cancelIdleCallbackFallback(id: number): void {
    if (typeof window !== "undefined" && "cancelIdleCallback" in window) {
        window.cancelIdleCallback(id);
    } else {
        clearTimeout(id);
    }
}
