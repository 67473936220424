export const appName = "mfe-universal-ui";
// internal path that server listens on
export const appRoot = `/${appName}` as const;

// Used for sending static asset paths as well as sent to the client for chunk loading
// Note: assets now get routed through container even on local env
export const getPathPrefix = (): string => {
    const containerPrefix = process.env.CONTAINER_PREFIX || "";
    return `${containerPrefix}/${appName}`;
};

export const ROOT_URL = "/";
export const HOME_URL = "/home";
export const THEME_UI_CONTEXT_ID = "css-uui";

/**
 * Name of the root WebComponent for the Universal UI Header
 *
 * Currently only used for unauthenticated header.
 * @todo: Use for authenticated header component?
 */
export const UUI_ROOT = "costar-header";

/**
 * Name of the main tracer used by the UUI MFE server
 */
export const JAEGER_TRACER_NAME = "uui-mfe";
