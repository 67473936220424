import React, { useCallback, useState } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { Icon, List, ListItem, ListItemText } from "@costar/theme-ui";
import { Box, Button, Tooltip } from "@costar/theme-ui";

import { useOutsideAlerter, useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

import { useAuthNavConfigContext } from "$client/auth/nav-config";
import { AuthenticatedClientConfig, MainProductIds, useConfigContext, useNavState } from "$common";

export type HelpButtonProps = {
    hideRequestTraining?: boolean;
};

const HelpFeedbackButton = ({ hideRequestTraining }: HelpButtonProps) => {
    /** TODO this is hard coded for now until hosts/app config file is created.
    // from: UUI repo -> ..\universal-ui\packages\universal-ui-config\src\schema\hosts.ts
    */
    const {
        nav: { tabSelection: productMode },
    } = useNavState();
    const isStr = productMode === MainProductIds.STR;

    const { userInfo } = useAuthNavConfigContext();

    const locationCountryCode = userInfo?.locationCountryCode;

    const config = useConfigContext() as AuthenticatedClientConfig;

    const helpURI =
        locationCountryCode !== "GBR"
            ? config.options.hosts.newSearchHelp.uri
            : config.options.hosts.newSearchHelpUk.uri;
    const resources = useStrictI18N();

    const [anchor, setAnchor] = useState<Element>();
    const handleClose = useCallback(() => setAnchor(undefined), []);
    const open = Boolean(anchor);

    const tooltipRef = React.useRef(null);
    const dropdownRef = React.useRef(null);
    /** Used to detect Clicks away to dismiss the dropdown. */
    useOutsideAlerter([tooltipRef, dropdownRef], handleClose);
    const [showHelpTooltip, setShowHelpTooltip] = useState<boolean>(false);

    const onButtonClick = useCallback(
        event => {
            if (!open) {
                setAnchor(event.currentTarget);
                setShowHelpTooltip(false);
            } else {
                setAnchor(undefined);
            }
        },
        [open]
    );

    const onTooltipHover = useCallback(
        event => {
            if (open) {
                /**  do not open the scond tooltip if the main dropdown is open */
                return;
            } else {
                setShowHelpTooltip(true);
            }
        },
        [open]
    );
    const onTooltipClose = useCallback(event => {
        setShowHelpTooltip(false);
    }, []);

    const onHelpClick = useCallback(() => {
        setAnchor(undefined); // Once modal pops up on Suite, we close the popover by setting anchor undefined.
        const helpURL = `${helpURI}/${productMode === "PUBLIC_RECORD" ? "publicrecordsearch" : "help"}`;
        window?.open(helpURL, "_blank")?.focus();
    }, [productMode, helpURI]);

    const onRequestTrainingClicked = useCallback(() => {
        setAnchor(undefined); // Once modal pops up on Suite, we close the popover by setting anchor undefined.
        subject<UUISubjectPayloads[UUISubject.RequestTrainingClicked]>(UUISubject.RequestTrainingClicked).notify();
    }, []);

    const onShareFeedbackClicked = useCallback(() => {
        setAnchor(undefined); // Once modal pops up on Suite, we close the popover by setting anchor undefined.
        subject<UUISubjectPayloads[UUISubject.ShareFeedbackClicked]>(UUISubject.ShareFeedbackClicked).notify();
    }, []);

    return (
        <Tooltip
            open={open}
            arrow
            ref={tooltipRef}
            placement="bottom-start"
            interactive
            onClose={handleClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            top="23px"
            title={
                <Box width="100%" ref={dropdownRef} automation-id="uui-help-dropdown">
                    <List p="0">
                        {!isStr && (
                            <ListItem data-testid="open-help-button" button variant="ghostMod" onClick={onHelpClick}>
                                <ListItemText primary={resources.search.common.share.helpWithFeatures()} />
                            </ListItem>
                        )}
                        {!isStr && !hideRequestTraining && (
                            <ListItem
                                data-testid="training-button"
                                button
                                variant="ghostMod"
                                onClick={onRequestTrainingClicked}
                            >
                                <ListItemText primary={resources.search.common.share.requestTraining()} />
                            </ListItem>
                        )}
                        <ListItem
                            button
                            data-testid="feedback-button"
                            variant="ghostMod"
                            onClick={onShareFeedbackClicked}
                        >
                            <ListItemText primary={resources.search.common.share.shareFeedback()} />
                        </ListItem>
                    </List>
                </Box>
            }
        >
            <Button
                automation-id="uui-help-icon"
                data-testid="help-button"
                p="0"
                icon={
                    <Tooltip
                        placement={"bottom"}
                        variant={"pendo"}
                        arrow
                        title={
                            isStr
                                ? resources.search.common.share.shareFeedback()
                                : resources.search.common.share.helpAndFeedback()
                        }
                        top="3px"
                        onClose={onTooltipClose} // prevents the second tooltip from opening if the dropdown is open.
                        onOpen={onTooltipHover}
                        open={showHelpTooltip}
                    >
                        <Icon icon="question-with-boarder" size="xxl" />
                    </Tooltip>
                }
                variant="ghost"
                color="neutrals.10"
                size="xl+3"
                onClick={onButtonClick}
            />
        </Tooltip>
    );
};

export default React.memo(HelpFeedbackButton);
