import { products } from "@costar/costar-enums";

export type ProductKey = keyof typeof products;

export interface ItemsResponse<T> {
    readonly Items: readonly T[];
}

export enum SuiteSearchType {
    None = 0,
    Property = 1,
    ForLease = 2,
    ForSale = 3,
    SaleComps = 4,
    LeaseComps = 5,
    PublicRecord = 6,
    Tenants = 7,
    Professionals = 8,
    AddProperties = 9,
}

// export enum LookupSearchType {
//     EntityCountProperty = 0,
//     EntityCountForLease = 1,
//     EntityCountForSale = 2,
//     EntityCountLeaseComp = 3,
//     EntityCountSaleComp = 4,
//     EntityCountTenant = 5,
// }

export enum LookupSearchProductID {
    AllProperties = 1,
    ForLease = 1,
    ForSale = 47,
    SaleComps = 5,
    Tenant = 2,
    LeaseComps = 219,
    PropertyLight = 338, //aka CoStarPropertyPro
    ForSaleLight = 340, // aka CoStarForSalePro
    Professionals = 40,
}

export enum GeographyFilterType {
    Unknown = 0,
    PostalCode = 2,
    City = 3,
    Submarket = 5,
    Street = 6,
    ResearchMarket = 7,
    County = 10,
    Subdivision = 11,
    ShoppingCenter = 14,
    Country = 15,
    PostalCodeGroup = 16,
    Region = 17,
    CBSA = 18,
    CSA = 19,
    DMA = 20,
    Cluster = 21,
    Airport = 22,
    HotelName = 23,
    TransitStation = 24,
    Military = 25,
    University = 26,
    SchoolWithAttendanceZone = 37,
    SchoolWithNoAttendanceZone = 38,
    SchoolDistrict = 39,
    CityAggregate = 40,
    CoStarMarket = 132,
}

export enum LookupSearchMatchType {
    Unknown = 0,
    Property = 1,
    PostalCode = 2,
    City = 3,
    BuildingName = 4,
    Submarket = 5,
    Street = 6,
    ResearchMarket = 7,
    Contact = 8,
    Company = 9,
    County = 10,
    Subdivision = 11,
    GeocodeResult = 12,
    BuildingPark = 13,
    ShoppingCenter = 14,
    Country = 15,
    PostalCodeGroup = 16,
    Region = 17,
    StoreType = 18,
    TransitStation = 19,
    PropertyResidential = 20,
    MultifamilyProperty = 21,
    Airport = 22,
    HotelName = 23,
    TransitStation2 = 24,
    Military = 25,
    University = 26,
    StoreBrand = 27,
    GoogleResults = 28,
    PropertyManagers = 29,
    SearchEngineOptimization = 30,
    Metro = 31,
    SaleCompID = 32,
    SubmarketCluster = 34,
    MSA = 35,
    PropertyGroup = 36,
    CompEntity = 50,
    Comp = 51,
    ForSale = 52,
    ContactSaleComp = 81,
    ContactShoppingCenter = 82,
    ContactLeaseDeal = 83,
    ContactTenant = 84,
    ContactProperty = 85,
    SubscriptionContact = 86,
    CompanySaleComp = 91,
    CompanyShoppingCenter = 92,
    CompanyLeaseDeal = 93,
    StreetIntersection = 131,
    CoStarMarket = 132,
    Fund = 137,
    FundOwner = 138,
}

export enum SuiteProductMode {
    None = 0,
    AllProperties = 1,
    MultiFamily = 11,
    Retail = 12,
    ShoppingCenters = 13,
    ForLease = 2,
    ForSale = 47,
    LeaseComps = 4,
    SaleComps = 5,
    Tenants = 6,
    PublicRecord = 41,
}

export enum ContactTypeaheadCustomRole {
    AllTypes = 0,
    Lender = 100,
    TitleCompany = 101,
}
