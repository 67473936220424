import React, { FC, useCallback, useMemo } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import {
    ROOT_URL,
    SectionId,
    setSelectedTab,
    setSubNavSelectedTab,
    setUpsellModal,
    useNavState,
    useSubnavState,
    useUpsellState,
} from "../../../common";
import { useAuthNavConfigContext } from "../nav-config";

import { useCurrentTab } from "$client/hooks";
import UpsellModal, { UpsellModalProps } from "$client/shared/upsell-modal";
import { NavigationEvent, UUISubject, UUISubjectPayloads } from "$client/subjects";

export const ConnectedUpsellModal: FC = () => {
    const { loading, navConfig } = useAuthNavConfigContext();

    const {
        nav: {
            tabSelection: activeProductId, // the id for the product the user is currently viewing. This reflects the user's navigation state.
            emitDetailedClickEvent,
        },
        dispatch,
    } = useNavState();
    const {
        subnav: { subNavTabSelection },
    } = useSubnavState();

    const { upsellUserSubscriber, upsellProductKey } = useUpsellState().upsell;

    const subNavConfig = useCurrentTab(navConfig, activeProductId)?.sections;
    const subNavUserSubscriber = subNavConfig?.find(({ id }) => id === subNavTabSelection)?.isUserSubscriber;

    const currentTab = useCurrentTab(navConfig, activeProductId);
    const { id: productKey, isUserSubscriber } = currentTab || {};
    const currProductKey = upsellProductKey ? upsellProductKey : productKey;
    const open = upsellUserSubscriber === undefined ? !loading && !isUserSubscriber : !upsellUserSubscriber;

    const modalData: Omit<UpsellModalProps, "onClick"> = useMemo(() => {
        return {
            productKey: currProductKey,
            open: open, // only handle showing the upsell modal after navconfig data has finished loading in
        };
    }, [currProductKey, open]);

    // TODO combine all the  maintab and sub tab navigation functions for better code reuse
    const navigateHome = useCallback(() => {
        const tab = navConfig[0];

        const { id, baseUrl } = tab;
        const tabHref = tab.href || tab.defaultHref;
        const href = tabHref && new URL(tabHref, window.location.origin).toString();

        const shouldEmitNavigationEvent: boolean = emitDetailedClickEvent || href === undefined;
        if (shouldEmitNavigationEvent) {
            const navEventPayload: NavigationEvent = {
                id,
                href,
                selfHandleRoute: false, // this seems to always be false in <costar-nav-item />
                baseUrl: baseUrl,
                isUserSubscribed: true,
                landingPath: undefined, // what is this?
                // This business logic is copied from uui <costar-nav-item />
                processClickEvent(highlightOnly = false) {
                    if (highlightOnly) {
                        dispatch(setSelectedTab(id));
                    } else if (href) {
                        window.location.href = href;
                    }
                },
            };
            subject<UUISubjectPayloads[UUISubject.MenuTabSelected]>(UUISubject.MenuTabSelected).notify(navEventPayload);
        } else {
            dispatch(setSelectedTab(id));
            window.location.href = href as string;
        }
    }, [dispatch, emitDetailedClickEvent, navConfig]);

    const onUpsellModalClose = useCallback(() => {
        if (!isUserSubscriber || (subNavTabSelection && !subNavUserSubscriber)) {
            navigateHome();
        }
        // }
        dispatch(setUpsellModal(undefined, undefined)); // reset the upsell modal state
    }, [dispatch, isUserSubscriber, navigateHome, subNavTabSelection, subNavUserSubscriber]);

    return <UpsellModal onClose={onUpsellModalClose} {...modalData} />;
};
