import { useEffect, useRef } from "react";

/**
 * A function that checks if two objects are equal.
 *
 * @param a the first object
 * @param b the second object
 *
 * @returns `true` if `a` is equal to `b`.
 */
export type Compare<T> = (a: T, b: T) => boolean;

/**
 * Memoizes a potentially complex object using a structural equality check.
 *
 * The purpose of this hook is different from {@link useMemo} in that it doesn't
 * aim to avoid expensive computation that creates an object, but rather to
 * allow complex objects to pass an {@link Object.is} check so that they can be
 * used within a dependency array.
 *
 * This is achieved by storing the previous value of {@link curr}, and comparing
 * it using {@link isEqual} to its new/current value. If no change has occurred
 * (i.e. {@link isEqual} returns true), the previous object is returned.
 *
 * @param curr The current value of the object being memoized
 * @param isEqual a {@link Compare comparison} function that returns `true` if
 * {@link curr} is equal to its previous value, i.e. if the data it contains
 * hasn't changed.
 *
 * @returns a memoized value for {@link curr}.
 *
 * @see {@link Compare}
 * @see [useHooks - useMemoCompare](https://usehooks.com/useMemoCompare/). Same
 * nature, different implementation.
 */
export const useMemoCompare = <T>(curr: T, isEqual: Compare<T>): T => {
    const prev = useRef(curr);

    if (!isEqual(prev.current, curr)) {
        prev.current = curr;
    }

    return prev.current;
};
