import React, { useMemo, useRef, useState } from "react";

import { Drawer, Flex } from "@costar/theme-ui";

import { ClientConfig, NavConfig, UnauthenticatedClientConfig, useConfigContext } from "../../../common";
import { useStrictI18N } from "../../hooks";
import { isReactKeyboardEvent } from "../../utils";
import HamburgerIcon from "../hamburger-icon";
import { useUnauthNavConfigContext } from "../nav-config.context";
import SideBarMenu from "../sidebar-menu";
import { SideBarMenuProps } from "../sidebar-menu/sidebar-menu";

import buildLeftNavOptions from "./build-left-nav-options";
import buildRightNavOptions from "./build-right-nav-props";
import LeftContent from "./left-content";
import RightContent from "./right-content";

export type UnauthMastheadProps = {
    onSidebarTabClick: SideBarMenuProps["onTabClick"];
};

export const UnauthMasthead: React.FC<UnauthMastheadProps> = props => {
    const { onSidebarTabClick } = props ?? {};
    const navConfig: NavConfig = useUnauthNavConfigContext();
    const config: ClientConfig = useConfigContext();
    const culturalResources = useStrictI18N();

    const hamburgerIconRef = useRef<HTMLButtonElement>(null);
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (isReactKeyboardEvent(event) && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setSideMenuOpen(open);
    };

    const [leftNavContentProps, rightNavContentProps] = useMemo(
        () =>
            [
                buildLeftNavOptions(culturalResources, config.akamaiCulture),
                buildRightNavOptions(culturalResources, config as UnauthenticatedClientConfig),
            ] as const,
        [culturalResources, config]
    );

    return (
        <Flex height="48px" justifyContent="space-between" as="menu">
            <LeftContent
                hamburgerIcon={<HamburgerIcon ref={hamburgerIconRef} onClick={toggleDrawer(true)} />}
                {...leftNavContentProps}
            />
            <Drawer
                css={undefined} // TODO: this looks like a bug in theme-ui
                anchor={"left"}
                open={sideMenuOpen}
                onClose={toggleDrawer(false)}
                PaneProps={{
                    variant: "scrollbar.transition",
                    border: "neutral",
                    boxShadow: "rgb(0 0 0 / 30%) 1px 0 25px 2px",
                    sx: {
                        left: 0,
                    },
                }}
            >
                <SideBarMenu
                    onClose={toggleDrawer(false)}
                    onTabClick={onSidebarTabClick}
                    loginLabel={rightNavContentProps.loginLabel}
                    rootUrl={leftNavContentProps.rootUrl}
                    topLinks={leftNavContentProps.links}
                    navConfig={navConfig}
                    loginUrl={rightNavContentProps.loginUrl}
                />
            </Drawer>
            <RightContent {...rightNavContentProps} />
        </Flex>
    );
};
