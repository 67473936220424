import React from "react";
import { hydrateRoot } from "react-dom/client";

import subject from "@csgp-mfe-utils/event-observer";
import createCache from "@emotion/cache";

import configuration from "@costar/configuration";
import { defineCustomElement } from "@costar/costar-one-platform";
import type Logger from "@costar/logger";

import { getLazyMessages } from "../../i18n/lazy-messages";
import { clientResources, initializeMessages } from "../../i18n/resources.client";
import {
    appName,
    appRoot,
    checkHydrationContext,
    ClientConfig,
    deserialize,
    HYDRATION_CONTEXT_KEY,
    HydrationContext,
    HydrationContextKey,
    NavState,
    THEME_UI_CONTEXT_ID,
    toCamelCase,
    UUI_ROOT,
} from "../common";
import { GraphqlClientSideProvider } from "../graphql/client/client-side-provider";
import type { AppFeatures } from "../server/render/types";

import { getCulture } from "./hooks/use-culture";
import { ErrorBoundary } from "./shared/error-boundary/error-boundary";
import { legacyAttributeToSubject, uuiSubjectData } from "./subjects/all-subjects";
import { UniversalUIContainer } from "./app-container";
import { configureClientLogger } from "./configure-logger";
import { cancelIdleCallbackFallback, requestIdleCallbackFallback } from "./utils";

enum Attributes {
    InitialConfig = "initial-config",
    InitialState = "initial-state",
    AppRoot = "app-root",
}

type WindowWithHydrationContext = typeof window & Record<HydrationContextKey, HydrationContext>;

const sleep = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

// /**
//  * Sets a Global variable that is used to define the webpack public path using webpack-require-from plugin
//  * The variable name must match what is set in the webpack config.
//  * Path needs to have a trailing slash added (behavior of the Webpack plugin)
//  * @param param0
//  */
// const setResourcePublicPath = (config?: { pathPrefix?: string }) => {
//     if (typeof window !== "undefined" && config?.pathPrefix) {
//         // Sets Webpack Public path for Async Chunks
//         (window as any).uuiClientChunkURL = `${config.pathPrefix}/`;
//     }
// };

/**
 * `costar-header` web component. SSR returns HTML containing this tag, styles
 * needed, and the React bundle as a script tag. This then mounts the React
 * component to itself.
 */
class UniversalUI extends HTMLElement {
    reactRoot: ReturnType<typeof hydrateRoot> | undefined;
    abortController: AbortController = new AbortController();
    private _observerHandles: Array<() => void> = [];
    private _logger: Logger | undefined;
    private _didHydrate: boolean;
    private _appFeatures?: AppFeatures;
    private _hydrationContext: HydrationContext | undefined;
    private _clientConfig: ClientConfig;
    private _diffIdleCallback: number | undefined;
    private _pathPrefix: string;

    constructor() {
        super();
        this._didHydrate = false;
        this._clientConfig = {} as ClientConfig; // loaded in _loadConfig during connectedCallback
        this._pathPrefix = appRoot;
    }

    // TODO(don): This appears to _just_ be appName from common/constants...
    getAppRoot = () => {
        return this.getAttribute(Attributes.AppRoot) || "/";
    };

    async connectedCallback() {
        try {
            this.makeLegacyCompatible(); // this must come first
            this._loadConfigAndLogger(); // read config, logger data from attributes
            this._loadHydrationContext(); // read hydration context from window. store in self
            await this.mount(this.getAppRoot());
            this._logger?.debug("Diffing initial and current attributes");
            this._diffIdleCallback = requestIdleCallbackFallback(
                () => {
                    this._diffInitialAttributes();
                    this._diffIdleCallback = undefined;
                },
                { timeout: 1000 }
            );
        } catch (err) {
            // Initialize the logger in case mount failed before it was initialized.
            if (!this._logger) {
                // Options won't exist if the error was thrown before
                // initialConfig was parsed
                if (configuration.has("options")) {
                    configureClientLogger(this._pathPrefix);
                } else {
                    configureClientLogger(this._pathPrefix, { level: "info" });
                }
                this._logger = configuration.get("logger") as Logger;
            }

            this._logger?.error(err);
        }
    }

    disconnectedCallback() {
        this.reactRoot?.unmount && this.reactRoot.unmount();
        this._observerHandles.forEach(detach => detach());
        if (this._diffIdleCallback) {
            cancelIdleCallbackFallback(this._diffIdleCallback);
            this._diffIdleCallback = undefined;
        }
        // TODO: do we need this?
        // this._observerHandles = [];
        this.abortController.abort();
    }

    /**
     * Included for backwards-compatibility reasons.
     *
     * @returns
     */
    whenToggled = (): Promise<boolean> => {
        return Promise.resolve(true);
    };

    private decodeInitialData<T>(attr: Attributes): T {
        const encodedData = this.getAttribute(attr);

        // Throw an error if initial state is missing in development. Suppress it in production.
        // NOTE(don): If this method is called twice with the same attribute, the second
        // call will fail. Maybe we should cache the decoded data.
        if (!encodedData) {
            const error = new Error(`Missing ${attr} attribute on costar-header. This is probably a bug.`);
            if (process.env.NODE_ENV === "production") {
                this._logger?.error(error);
                return {} as any;
            } else {
                throw error;
            }
        }

        // NOTE(don): this.removeAttribute(attr) has been temporarily removed
        // while the News team works on an update to prevent this component from
        // unmounting/remounting.
        return deserialize<T>(encodedData);
    }

    private decodeInitialConfig(): ClientConfig {
        return this.decodeInitialData<ClientConfig>(Attributes.InitialConfig);
    }

    private decodeInitialState(): NavState {
        return this.decodeInitialData<NavState>(Attributes.InitialState);
    }

    private _loadConfigAndLogger() {
        // Get client config
        const initialConfig: ClientConfig = this.decodeInitialConfig();
        //setResourcePublicPath(initialConfig);
        if (initialConfig.pathPrefix) this._pathPrefix = initialConfig.pathPrefix;
        configuration.set("options", initialConfig?.options);
        this._clientConfig = initialConfig;
        this._appFeatures = initialConfig?.appFeatures;

        // Initialize logger. Requires config to be set first.
        configureClientLogger(this._pathPrefix);
        this._logger = configuration.get("logger") as Logger;
    }

    private _loadHydrationContext() {
        if (!(HYDRATION_CONTEXT_KEY in window)) {
            this._logger?.warn("Hydration context not found on window");
            return;
        }

        const hydrationContext = (window as WindowWithHydrationContext)[HYDRATION_CONTEXT_KEY];
        if (process.env.NODE_ENV !== "production") {
            if (!checkHydrationContext(hydrationContext)) {
                throw new TypeError("Invalid hydration context");
            }
        }

        this._hydrationContext = hydrationContext;
    }

    private makeLegacyCompatible(): void {
        this.registerLegacyEventEmitters();
        this.mapPropsToAttributes();
    }

    /**
     * Provides backwards compatibility with old event system.
     *
     * In short, the MFE subscribes to itself (from the new {@link Subject} API)
     * and emits custom DOM events so that costar suite doesn't break.
     *
     * @see {@link uuiSubjectData}
     */
    private registerLegacyEventEmitters() {
        for (const [subjectName, data] of Object.entries(uuiSubjectData)) {
            // Subscribe to each subject that needs to be backwards compatible.
            // The returned detach thunk is stored in a list so that it can
            // be used when component detaches from DOM
            const { domEventName } = data.legacy;
            if (domEventName) {
                this._observerHandles.push(
                    subject(subjectName).attach(args => {
                        const event = new CustomEvent(domEventName, { detail: args });
                        this.dispatchEvent(event);
                    })
                );
            }
        }
    }

    /**
     * This is a array of states that we do not set as attributes when consumer
     * pushes changes to these. We directly notify these changes to the pub/sub.
     * There can be any reason for that. The original reason for implementing
     * this is that the state has function which is lost in JSON.stringify.
     * If we change that function to string before stringifying, we risk loosing
     * references and this is not a recommended solution to put functions in
     * attributes for security and other reasons
     */
    private getDirectlyNotifiedStates() {
        return ["navbar-buttons"];
    }

    /**
     * Used for handling costar suite attribute-based communication with the
     * Universal Nav.
     *
     * Suite sets camelCase attributes that correspond one-to-one with kebabCase
     * HTML Element attributes. The former are properties attached to the
     * header, the same way you could on any POJO. The latter are tag attributes
     * that appear in the dom/HTML.
     *
     * The latter are listened to via {@link attributeChangedCallback}. This is
     * already wired, and is needed for SSR. This adds setters for each of these
     * attributes (in camelCase) that propagates the change as a tag attribute
     * change.
     *
     */
    private mapPropsToAttributes() {
        // for each legacy-attribute-name
        Object.keys(legacyAttributeToSubject).forEach(attr => {
            // map it to a legacyPropertyName
            const propertyName = toCamelCase(attr);
            // Register a setter onto the web component
            Object.defineProperty(this, propertyName, {
                enumerable: true,
                configurable: false,
                // If the new value is nullish, remove it. Otherwise, set it
                set(this: UniversalUI, val: unknown) {
                    if (val == null || val === "undefined" || val === "null") {
                        this.removeAttribute(attr);
                    } else {
                        if (this.getDirectlyNotifiedStates().includes(attr)) {
                            const subjectName = legacyAttributeToSubject[attr];
                            subject(subjectName).notify(val);
                        } else {
                            this.setAttribute(attr, JSON.stringify(val));
                        }
                    }
                },
            });
        });
    }

    /**
     * Diff initial attributes received from the server and current attributes
     * after hydration completes. Run only when `uui-async-scripts` toggle is set.
     *
     * Must be called after legacy compatibility functions and hydration have run.
     */
    private _diffInitialAttributes() {
        // Get initial header attributes set on window during ssr
        const initialAttributes = this._hydrationContext?.initialAttributes;
        if (!initialAttributes) {
            this._logger?.warn(`Missing initial attributes and/or uui hydration context on window.`);
            return;
        }

        // notify subject for attributes that have changed
        for (const [attr, subjectName] of Object.entries(legacyAttributeToSubject)) {
            const currentValue = this.getAttribute(attr);
            const initialValue = initialAttributes[attr];
            try {
                // Attribute value hasn't changed. No need to notify. First if
                // is a short-circuit
                // eslint-disable-next-line eqeqeq
                if (currentValue == initialValue) continue;
                const currentValueDecoded = currentValue != null ? JSON.parse(currentValue) : undefined;
                // eslint-disable-next-line eqeqeq
                if (currentValue === initialValue) continue;

                // Attribute value has changed. Notify.
                this._logger?.debug(
                    `Attribute "${attr}" changed from (${initialValue}) during SSR to (${currentValue}) after hydration.`
                );
                subject(subjectName).notifyAsync(currentValueDecoded);
            } catch (err) {
                // An error was thrown that wasn't from parsing invalid JSON.
                if ((err as Error)?.name !== "SyntaxError") this._logger?.warn(err as Error);

                subject(subjectName).notifyAsync(currentValue);
            }
        }
    }

    /**
     * Returns a list of attributes that, when changed, cause
     * {@link attributeChangedCallback} to be called.
     *
     * @see [WebComponent Lifecycle](https://web.dev/custom-elements-v1/#custom-element-reactions)
     */
    static get observedAttributes(): string[] {
        return Object.keys(legacyAttributeToSubject);
    }

    /**
     * Called by the browser whenever an attribute included in
     * {@link observedAttributes} is modified (usually with
     * {@link Element#setAttribute}).
     *
     * Note that `oldValue` and `newValue` may be the same thing. This method is
     * called whenever the attribute value is set, regardless if it was set to
     * the same thing.
     *
     * @param attr The name of the modified attribute
     * @param oldValue Attribute's previous value
     * @param newValue Attribute's new value.
     */
    public attributeChangedCallback(attr: string, oldValue: string, newValue: string): void {
        const subjectName = legacyAttributeToSubject[attr];
        // Sometimes unnecessary React updates cause old and new values to be the same
        if (!Object.is(oldValue, newValue)) {
            try {
                const parsedNewValue = JSON.parse(newValue);
                subject(subjectName).notify(parsedNewValue);
            } catch (e) {
                subject(subjectName).notify(newValue);
            }
        }
    }

    async mount(appRoot: string = "/") {
        const initialState: NavState = this.decodeInitialState();
        const clientConfig = this._clientConfig;
        if (!clientConfig) {
            throw new TypeError("missing client config");
        }
        const cache = createCache({ key: THEME_UI_CONTEXT_ID, container: this });
        // TODO(don): Enable and test this
        // const emotionIds = this._hydrationContext?.emotionIds;
        // if (emotionIds) {
        //     hydrate(emotionIds);
        // }

        const culture = getCulture(this._clientConfig, this._logger);
        const messages = await getLazyMessages(culture);
        initializeMessages(messages);
        let root = document.getElementById(`${UUI_ROOT}-hydration-root`)!;
        let count = 0;
        // retry in the event that root is null, this happen in some scenario, i.e. search market page load
        while (!root && count < 3) {
            await sleep(100);
            count++;
            root = document.getElementById(`${UUI_ROOT}-hydration-root`)!;
        }
        const UniversalUI: React.FC = () => (
            <GraphqlClientSideProvider
                preferences={clientConfig.authenticated ? clientConfig.userPreferences : undefined}
                pathPrefix={this._pathPrefix}
            >
                <ErrorBoundary logger={this._logger}>
                    <UniversalUIContainer
                        authenticated={clientConfig?.authenticated}
                        initConfig={clientConfig}
                        initState={initialState}
                        culturalResources={clientResources}
                        emotionCache={cache}
                    />
                </ErrorBoundary>
            </GraphqlClientSideProvider>
        );
        // Always preserve display name, no matter what environment we're running in
        UniversalUI.displayName = "UniversalUI";

        this.reactRoot = hydrateRoot(root, <UniversalUI />, {
            identifierPrefix: appName,
            onRecoverableError: err => this._logger?.warn(err),
        });

        this._didHydrate = true;
    }
}

defineCustomElement(UUI_ROOT, UniversalUI);
