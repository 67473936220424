import React, { FC, useCallback } from "react";

import { Flex, List, ListItem, ListItemText, Tab, Tabs, TabsContextProvider, Tooltip } from "@costar/theme-ui";

import { ProductId, warnOrThrow } from "../../../common";

import { MainMenuProps } from "./types";

export const MainMenu: FC<MainMenuProps> = props => {
    const { label = "Main Navigation Menu", selection, navConfig, setSelection } = props;

    const onChange = useCallback(
        (selectedTab: number | string) => {
            if (typeof selectedTab === "number") {
                warnOrThrow("Expected selectedTab to be a ProductId, got a number");
                setSelection(navConfig[selectedTab].id);
            }

            setSelection(selectedTab as ProductId);
        },
        [navConfig, setSelection]
    );

    return (
        <Flex bg="secondary" height="40px">
            <TabsContextProvider selection={selection} onChange={onChange}>
                <Tabs
                    height="40px" // -1px from the container height
                    spacing="xxl"
                    mode="scrollable"
                    automation-id="uui-main-menu-tabs"
                    aria-label={label}
                    as="nav"
                    borderBottom="unset !important"
                >
                    {navConfig.map((item, i) =>
                        item.hoverItems && item.hoverItems.length > 0 && item.hoverItems[0].text ? (
                            <Tooltip
                                automation-id="uui-tab-country-code-input"
                                key={`main-menu-${item.id}`}
                                variant="legacy"
                                placement="bottom"
                                m={0}
                                title={
                                    <List
                                        variant="legacy"
                                        disablePadding
                                        sx={{
                                            boxShadow: "rgb(24, 57, 105) 0px 0px 4px",
                                            border: "1px solid rgb(24, 57, 105)",
                                        }}
                                    >
                                        {item.hoverItems.map(hoverItem => (
                                            <ListItem
                                                key={hoverItem.id}
                                                minWidth="65px"
                                                onClick={setSelection.bind(this, item.id, hoverItem.id)}
                                            >
                                                <ListItemText primary={hoverItem.text} />
                                            </ListItem>
                                        ))}
                                    </List>
                                }
                                interactive
                            >
                                <Tab value={item.id} automation-id={`uui-tab-tier-1-${item.id}`} tabindex={i}>
                                    {item.text}
                                </Tab>
                            </Tooltip>
                        ) : (
                            <Tab
                                key={`main-menu-${item.id}`}
                                value={item.id}
                                automation-id={`uui-tab-tier-1-${item.id}`}
                                tabindex={i}
                            >
                                {item.text}
                            </Tab>
                        )
                    )}
                </Tabs>
            </TabsContextProvider>
        </Flex>
    );
};
