import React, { FC } from "react";

import type { BoxProps } from "@costar/theme-ui";
import { Box, List, ListItem, ListItemText } from "@costar/theme-ui";

import { SearchItemOption } from "./types";

import type { SearchHrefRedirect, SearchItem } from "$graphql/core";
import { SearchType as SearchTypeGQL } from "$graphql/core";

type RecentlyViewedProps = BoxProps & {
    title?: string;
    recentlyViewed?: SearchItemOption[];
    searchType?: SearchTypeGQL;
};

export const RecentlyViewed: FC<RecentlyViewedProps> = props => {
    const { title, recentlyViewed, searchType, ...rest } = props;

    if (recentlyViewed == null || recentlyViewed.length === 0) {
        return null;
    }

    const maxLength = 4;

    return (
        <Box color="text" {...rest}>
            <Box
                fontWeight="bold"
                paddingLeft="xs"
                paddingRight="xs"
                paddingTop="xs-2"
                paddingBottom="xs-2"
                borderBottom="lightGray"
            >
                {title}
            </Box>
            {recentlyViewed &&
                recentlyViewed.slice(0, maxLength).map(recent => <RecentlyViewedEntry key={recent.id} {...recent} />)}
        </Box>
    );
};

const RecentlyViewedEntry = React.memo(function RecentlyViewedEntry({ label, redirect }: SearchItemOption) {
    const href = (redirect as Partial<SearchHrefRedirect>)?.href || "";

    return (
        <Box borderBottom="lightGray">
            <List
                sx={{
                    "&:hover": {
                        color: "white",
                        bg: "primaries.3",
                    },
                }}
                p="0"
            >
                <ListItem button as="a" color="black" href={href}>
                    <ListItemText primary={label} />
                </ListItem>
            </List>
        </Box>
    );
});
