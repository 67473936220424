import React, { FC, useCallback, useMemo } from "react";

import { Flex, List, ListItem, ListItemText, Tab, Tabs, TabsContextProvider, Tooltip } from "@costar/theme-ui";

import { Section, SectionId, warnOrThrow } from "../../../common";
import { ActionKey, ButtonState, SubNavState } from "../../../common/state";
import { useStrictI18N } from "../../hooks";
import UpsellModal from "../../shared/upsell-modal";

import { BackButton } from "./nav-action";

export interface NavLeftContentProps
    extends Pick<SubNavState, "actionButtonState" | "subNavTabSelection" | "backButtonOverrideLabel"> {
    labels?: Partial<Record<Exclude<ActionKey, "tab">, string>>;
    subnavLabel?: string;
    subNavConfig: Section[];
    onTabClickWithoutUpsell?: (item: Section, index: number) => void;
    handleMenuSelectionChange?: (value: number | string) => void;
    setSelection: (tabSelected: SectionId, dropdownSelected?: SectionId | string) => void;
    onBackClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const BackButtonWrapper: FC<NavLeftContentProps> = props => {
    const { actionButtonState, onBackClick, backButtonOverrideLabel } = props;
    const { back: backButton = {} as ButtonState } = actionButtonState;
    const resources = useStrictI18N();
    const backMessage = useMemo(
        () => backButtonOverrideLabel ?? resources?.submenu?.detail?.label?.back(),
        [backButtonOverrideLabel, resources?.submenu?.detail?.label]
    );

    return <>{backButton.show && <BackButton onClick={onBackClick} message={backMessage} />}</>;
};

const SubMenuItems: FC<NavLeftContentProps> = props => {
    const { subNavConfig, setSelection, subNavTabSelection, subnavLabel = "Sub Navigation Menu" } = props;
    const onChange = useCallback(
        (selectedTab: number | string) => {
            if (typeof selectedTab === "number") {
                warnOrThrow("Expected selectedTab to be a SectionId, got a number");
                setSelection(subNavConfig[selectedTab].id);
            }

            setSelection(selectedTab as SectionId);
        },
        [subNavConfig, setSelection]
    );

    return (
        <Flex>
            <UpsellModal
                onClose={() => {
                    console.log("on close");
                }}
            />
            <TabsContextProvider selection={subNavTabSelection} onChange={onChange}>
                <Tabs
                    height="33px" // -1px from the container height
                    spacing="xxl"
                    mode="scrollable"
                    bg="background"
                    aria-label={subnavLabel}
                    borderBottom="unset !important"
                >
                    {subNavConfig.map((item, i) =>
                        item.dropdownItems && item.dropdownItems.length > 0 && item.dropdownItems[0].text ? (
                            <Tooltip
                                key={`main-menu-${item.id}`}
                                automation-id={`uui-tab-tier-2-${item.id}`}
                                variant="legacy"
                                placement="bottom"
                                m={0}
                                title={
                                    <List
                                        variant="legacy"
                                        disablePadding
                                        sx={{
                                            boxShadow: "rgb(5, 89, 179) 0px 0px 4px",
                                            border: "1px solid rgb(5, 89, 179)",
                                        }}
                                    >
                                        {item.dropdownItems.map(hoverItem => (
                                            <ListItem
                                                key={hoverItem.id}
                                                minWidth="65px"
                                                fontSize="xs"
                                                onClick={setSelection.bind(this, item.id, hoverItem.id)}
                                            >
                                                <ListItemText primary={hoverItem?.text} />
                                            </ListItem>
                                        ))}
                                    </List>
                                }
                                interactive
                            >
                                <Tab value={item.id} fontSize="xs" color="text-light-bg" tabindex={i}>
                                    {item.text}
                                </Tab>
                            </Tooltip>
                        ) : (
                            <Tab
                                key={`sub-menu-${item.id}`}
                                automation-id={`uui-tab-tier-2-${item.id}`}
                                value={item.id}
                                fontSize="xs"
                                color="text-light-bg"
                                // role="link"
                                tabindex={i}
                            >
                                {item.text}
                            </Tab>
                        )
                    )}
                </Tabs>
            </TabsContextProvider>
        </Flex>
    );
};

export const NavLeftContent: FC<NavLeftContentProps> = props => {
    const { actionButtonState } = props;
    const { back: backButton = {} as ButtonState } = actionButtonState;
    return (
        <>
            <BackButtonWrapper {...props} />
            {props.subNavConfig?.length && props.subNavTabSelection != null && !backButton.show ? (
                <SubMenuItems {...props} />
            ) : null}
        </>
    );
};
