import { Resources } from "../../../../i18n/resources";

import { ListingOptions } from "./types";

export default function buildListingOptions(culturalResources: Resources, baseUrl?: string): ListingOptions {
    return [
        {
            id: "FOR_SALE_LISTING",
            label: culturalResources.addListing.forSale.label(),
            children: [
                {
                    id: "FOR_SALE_LISTING_BUILDING",
                    label: culturalResources.addListing.forSale.building(),
                    url: `${baseUrl}/listing/create/for-sale/`,
                },
                {
                    id: "FOR_SALE_LISTING_CONDO",
                    label: culturalResources.addListing.forSale.condo(),
                    url: `${baseUrl}/listing/create/condo-for-sale/`,
                },
                {
                    id: "FOR_SALE_LISTING_LAND",
                    label: culturalResources.addListing.forSale.land(),
                    url: `${baseUrl}/listing/create/land-for-sale/`,
                },
                {
                    id: "FOR_SALE_LISTING_PORTFOLIO",
                    label: culturalResources.addListing.forSale.portfolio(),
                    url: `${baseUrl}/listing/create/portfolio/`,
                },
                {
                    id: "FOR_SALE_LISTING_BUSINESS",
                    label: culturalResources.addListing.forSale.business(),
                },
            ],
        },
        {
            id: "FOR_LEASE_LISTING",
            label: culturalResources.addListing.forLease.label(),
            children: [
                {
                    id: "FOR_LEASE_BUILDING_OR_CONDO",
                    label: culturalResources.addListing.forLease.buildingOrCondo(),
                    url: `${baseUrl}/listing/create/for-lease/`,
                },
                {
                    id: "FOR_LEASE_LISTING_LAND",
                    label: culturalResources.addListing.forLease.land(),
                    url: `${baseUrl}/listing/create/land-for-lease/`,
                },
            ],
        },
    ];
}
