import React, { FC } from "react";

import { Box, Button, Flex, Heading, Modal, Pane, Text } from "@costar/theme-ui";

import { useStrictI18N } from "$client/hooks";

const styles = {
    box: {
        margin: "10px",
    },
    codeBox: {
        marginLeft: "10px",
        padding: "10px",
        width: "270px",
        backgroundColor: "#ECECEC",
        color: "#24335A",
        fontSize: "22px",
    },
    code: {
        fontWeight: 600,
    },
    text: {
        fontSize: "13px",
        color: "#343434",
    },
};

type ShareScreenModalProps = { isOpen: boolean; endSessionAndClose: () => void; code: string; closeModal: () => void };

export const ShareScreenModal: FC<ShareScreenModalProps> = props => {
    const { code, endSessionAndClose, isOpen, closeModal } = props;
    const resources = useStrictI18N();

    if (code == null || code === "") {
        endSessionAndClose();
        return null;
    }

    const body = (
        <Pane absolute width={580} border="shadow" p="md" bg="white">
            <Flex flexDirection="column" filled alignItems="start">
                <Flex flexDirection="row" width={556}>
                    <Box style={styles.box}>
                        <Heading as="h1" id="simple-modal-title">
                            {resources.screenShare.modal.title()}
                        </Heading>
                    </Box>
                    <Box marginLeft="auto" marginRight="10px" marginTop="10px">
                        <Button icon="close" onClick={closeModal}></Button>
                    </Box>
                </Flex>
                <Box style={styles.box}>
                    <Text style={styles.text}>{resources.screenShare.modal.narrative.body1()}</Text>
                </Box>
                <Box style={styles.codeBox}>
                    <Text>
                        {resources.screenShare.modal.code()}: <Text style={styles.code}>{code}</Text>
                    </Text>
                </Box>
                <Box style={styles.box}>
                    <Text style={styles.text}>{resources.screenShare.modal.narrative.body2()}</Text>
                </Box>
                <Box style={styles.box} alignSelf="end">
                    <Button variant="primary" onClick={endSessionAndClose}>
                        {resources.screenShare.modal.cancel()}
                    </Button>
                </Box>
            </Flex>
        </Pane>
    );

    return (
        <Flex vertical filled alignItems="center">
            <Modal
                open={!!isOpen}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    bg: "rgba(0, 0, 0, 0.2)",
                }}
            >
                {body}
            </Modal>
        </Flex>
    );
};
