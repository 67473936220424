import { objects } from "@costar/utility";

import { Resources } from "../../../../i18n/resources";
import { HOME_URL } from "../../../common";

import { UpsellModalData } from "./types";
import upsellMessage, { getDemoUrl, getLearnMoreUrl } from "./upsell-message";

// TODO(don): prop builder functions are an anti-pattern. Remove.
export default function buildUpsellData(
    culturalResources: Resources,
    isUK: boolean,
    productKey?: string,
    loginUrl?: string // TODO: make required
): UpsellModalData {
    const messageKey = (productKey && upsellMessage[productKey]) ?? "properties";

    return {
        message: {
            line1: objects.get(culturalResources, `upsell.modal.narrative.${messageKey}.body1`)(),
            line2: objects.get(culturalResources, `upsell.modal.narrative.${messageKey}.body2`)(),
        },
        links: [
            {
                label: culturalResources.upsell.learnMore(),
                url: getLearnMoreUrl(messageKey),
                variant: "text-button",
            },
            {
                label: culturalResources.upsell.getAccess(),
                url: getDemoUrl(isUK, messageKey),
                variant: "primary-button",
            },
        ],
        subscription: {
            message: culturalResources.upsell.isSubscriber(),
            clickLabel: culturalResources.upsell.clickHere(),
            url: loginUrl ?? HOME_URL,
        },
    };
}
