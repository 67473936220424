import once from "lodash/once";

import { ApolloError } from "@apollo/client";

import { ApolloClient, ErrorResponse } from "./apollo-packages";

enum ApolloErrorCodes {
    PersistedQueryNotFound = "PERSISTED_QUERY_NOT_FOUND",
}

enum ErrorTypes {
    RESTDataSourceError = "RESTDataSource",
    FormatterError = "Formatter",
    OtherError = "Other",
}

const getErrorDetailsFromError = (originalError: any | undefined) => {
    const stack = originalError?.stack;
    let errorType = ErrorTypes.OtherError;
    let formatterFile;
    let dataSource;
    if (stack && typeof stack === "string" && stack.includes("RESTDataSource")) {
        errorType = ErrorTypes.RESTDataSourceError;
        const dataSourceError = stack.split("at ");
        if (dataSourceError.length > 1) {
            const errorDataSource = dataSourceError[1].split(".")[0];
            dataSource = errorDataSource !== "" ? errorDataSource : undefined;
        }
    } else if (
        stack &&
        typeof stack === "string" &&
        stack.includes("webpack:/node_modules/@costar-suite/formatters/dist/")
    ) {
        errorType = ErrorTypes.FormatterError;
        const formatterErrorArray = stack.split("webpack:/node_modules/@costar-suite/formatters/dist/");
        if (formatterErrorArray.length > 1) {
            const formatterError = formatterErrorArray[1];
            if (formatterError) {
                const formatter = formatterError.split(":")[0];
                formatterFile = formatter !== "" ? formatter : undefined;
            }
        }
    }

    return {
        errorType,
        formatterFile,
        dataSource,
    };
};

// Formats server error for logging / sending to the client
//
export const formatServerError = (error: any, staleClient: boolean, requestContext?) => {
    const { name, message, path, originalError, source } = error;

    // From https://github.com/apollographql/apollo-server/blob/main/packages/apollo-server-errors/src/index.ts#L92-L112
    //
    const code = error.extensions?.code || "INTERNAL_SERVER_ERROR";
    let stack = error.extensions?.exception?.stacktrace;
    if (requestContext?.debug && !stack) {
        stack = originalError?.stack?.split("\n") || error.stack?.split("\n");
    }

    const errorDetails = getErrorDetailsFromError(originalError);

    // TODO: include data from request context, if necessary
    //
    return {
        message,
        messageJson: {
            code,
            name,
            path,
            originalError: originalError && {
                message: originalError.message,
                status: originalError.status,
                stack: originalError.stack,
            },
            body: source?.body,
            operationName: requestContext?.request?.operationName,
            variables: requestContext?.request?.variables,
            staleClient,
            ...errorDetails,
        },
        stack,
    };
};

declare var window: any;

const setRedirect = (redirectUrl: string) => {
    window.__redirectingToTheLoginPage = true;
    window.location.href = redirectUrl;
};

const setRedirectOnce = once(setRedirect);

export const apolloErrorHandle401 = (errorResponse: ErrorResponse) => {
    const response = errorResponse?.operation?.getContext()?.response;
    if (response?.status === 401) {
        const reason = response?.headers?.get?.("reason");
        const appRoot = window?.options?.newGatewayRoot;
        if (appRoot) {
            const signoutUrl = `${appRoot}home/signout${reason ? `?r=${encodeURIComponent(reason)}` : ""}`;
            setRedirectOnce(signoutUrl);
        }
    }
};

export const formatClientError = (client: ApolloClient<any>) => (errorResponse: ErrorResponse) => {
    const { graphQLErrors, networkError: nw, operation: op } = errorResponse;
    // Do not return or throw an exception.
    // Throwing an exception here causes the graphql queries to never resolve/reject.
    // (See https://www.apollographql.com/docs/react/data/error-handling/)
    const { operationName, variables, extensions, query } = op;

    const operation = { operationName, variables, extensions, query: query?.loc?.source?.body };

    if (graphQLErrors?.length) {
        const csbuild = (graphQLErrors[0] as any).csbuild;
        // TODO(don): add `options` to windows in globals.d.ts
        const staleClient =
            csbuild !== (window as unknown as Window & { options: Record<string, string> }).options.csbuild;
        console.error({
            name: "GraphQLServerError",
            operation,
            graphQLErrors: graphQLErrors.map(f => formatServerError(f as any, staleClient)),
        });
        if (csbuild && staleClient) {
            // Build number returned by the server does not match client value
            // Clear Apollo client cache and reload the page
            client.clearStore();
        }
    }

    if (nw) {
        const { result, stack, statusCode } = nw as any;
        const networkError = { result, stack, statusCode };
        const errorDetails = getErrorDetailsFromError(networkError);

        console.error({
            name: "GraphQLNetworkError",
            operation,
            networkError,
            ...errorDetails,
        });
    }
    apolloErrorHandle401(errorResponse);
};

// Returns true if server error code is PERSISTED_QUERY_NOT_FOUND
//
export const isPersistedQueryNotFoundError = (error: ApolloError) =>
    error?.extraInfo?.code === ApolloErrorCodes.PersistedQueryNotFound;
