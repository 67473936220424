import React, { useCallback } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { Badge, BadgeOrigin, Button, Flex, Tooltip } from "@costar/theme-ui";
import { IconHeart } from "@costar/theme-ui-icons/csg/IconHeart";
import { IconFavorite } from "@costar/theme-ui-icons/md/IconFavorite";

import { useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

type FavoriteButtonProps = {
    isToggled?: boolean;
    favoritesCount?: number;
};
const anochorOrigin: BadgeOrigin = {
    vertical: "center",
    horizontal: "right",
};
const FavoritesModeButton = ({ isToggled, favoritesCount }: FavoriteButtonProps) => {
    const resources = useStrictI18N();

    const favoriteTooltipMessage = !isToggled
        ? resources.toolbar.tooltip.viewFavorites()
        : resources.toolbar.tooltip.viewAllResults();

    const noFavoritesMessage = resources.toolbar.tooltip.noFavorites();

    const onFavoritesModeButtonClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.FavoritesModeButtonClicked]>(
            UUISubject.FavoritesModeButtonClicked
        ).notify();
    }, []);

    return (
        <Flex>
            {!favoritesCount ? (
                <Tooltip arrow variant="pendo" placement="bottom" title={noFavoritesMessage}>
                    <Button
                        icon={<IconHeart />}
                        variant="ghost"
                        data-testid="favorites-mode-button"
                        p="0"
                        size="xxl"
                        color="neutral.medium"
                        disabled
                    />
                </Tooltip>
            ) : (
                <Badge
                    badgeContent={favoritesCount}
                    variant={isToggled ? "white" : "primary"}
                    top="2px"
                    fontSize={"xxl"}
                    anchorOrigin={anochorOrigin}
                    onClick={onFavoritesModeButtonClick}
                    enableClickableStyles
                >
                    <Tooltip arrow variant="pendo" placement="bottom" title={favoriteTooltipMessage}>
                        <Button
                            icon={isToggled ? <IconFavorite /> : <IconHeart />}
                            variant="ghost"
                            data-testid="favorites-mode-button"
                            p="0"
                            size="xxl"
                            color={isToggled ? "primary" : "neutral.dark"}
                            hoverColor="primary"
                            onClick={onFavoritesModeButtonClick}
                            disabled={!favoritesCount}
                        />
                    </Tooltip>
                </Badge>
            )}
        </Flex>
    );
};

export default React.memo(FavoritesModeButton);
