import type { RecentlyViewed, SearchFormRedirect, SearchHrefRedirect, UserEntities } from "$graphql/core";

export function isSearchHrefRedirect(
    searchRedirect: SearchFormRedirect | SearchHrefRedirect
): searchRedirect is SearchHrefRedirect {
    return (searchRedirect as SearchHrefRedirect).href !== undefined;
}

export const hrefRedirect = (redirect: SearchHrefRedirect): void => {
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", redirect.href);

    document.body.appendChild(form);
    form.submit();
};

export const formRedirect = (redirect: SearchFormRedirect): void => {
    const form = document.createElement("form");
    form.setAttribute("method", redirect.method);
    form.setAttribute("action", redirect.action);

    if (redirect.formData) {
        Object.entries(redirect.formData).forEach(([k, v]) => {
            const input = document.createElement("input");
            form.appendChild(input);
            input.setAttribute("name", k);
            input.setAttribute("value", v);
        });
    }

    document.body.appendChild(form);
    form.submit();
};

export const convertRecentlyViewedArrayToObject = (
    recentlyViewed: { recentlyViewed: RecentlyViewed | undefined } | undefined
): Record<string, UserEntities> | undefined => {
    return recentlyViewed?.recentlyViewed?.userEntities?.reduce((obj, item) => {
        if (item?.productType) {
            obj[item.productType] = item;
        }
        return obj;
    }, {} as Record<string, UserEntities>);
};

export const hasSearchKeys = (storage: Storage, searchType: string): boolean => {
    return Object.keys(storage).filter(t => t.indexOf(`costar_search_${searchType.toLowerCase()}`) === 0).length > 0;
};
