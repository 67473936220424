// NOTE(don): These (currently) simple functions are put here to
// 1) centralize config message logic
// 2) serve as backwards-compatable APIs in case we want to change how configs
//    work or are serialized (e.g. maybe configs are feature-rich class
//    instances that require re-hydration, or maybe we want to validate configs
//    before/after they are sent/received)

/**
 * Encodes an object into a string so it can be sent over HTTP.
 *
 * @param obj The config to serialize
 * @returns  The serialized config
 *
 * @see {@link deserialize}
 */
export const serialize = <T>(obj: T): string => encodeURIComponent(JSON.stringify(obj));

/**
 * Decodes an object that was encoded with {@link serialize}.
 *
 * @param encoded The encoded message
 * @returns The object
 */
export const deserialize = <T>(encoded: string): T => JSON.parse(decodeURIComponent(encoded));
