import { Culture } from "@costar/i18n";

export const getLazyMessages: any = async (culture: Culture) => {
  switch (culture) {
    case 'en-US': {
      return { ...(await import(/* webpackChunkName: "language-pack.en-US" */ './language-pack.en-US.js')).default }
    }
    case 'en-CA': {
      return { ...(await import(/* webpackChunkName: "language-pack.en-CA" */ './language-pack.en-CA.js')).default }
    }
    case 'en-GB': {
      return { ...(await import(/* webpackChunkName: "language-pack.en-GB" */ './language-pack.en-GB.js')).default }
    }
    case 'fr-CA': {
      return { ...(await import(/* webpackChunkName: "language-pack.fr-CA" */ './language-pack.fr-CA.js')).default }
    }
    case 'fr-FR': {
      return { ...(await import(/* webpackChunkName: "language-pack.fr-FR" */ './language-pack.fr-FR.js')).default }
    }
    case 'es-ES': {
      return { ...(await import(/* webpackChunkName: "language-pack.es-ES" */ './language-pack.es-ES.js')).default }
    }
    case 'es-MX': {
      return { ...(await import(/* webpackChunkName: "language-pack.es-MX" */ './language-pack.es-MX.js')).default }
    }
    case 'es-419': {
      return { ...(await import(/* webpackChunkName: "language-pack.es-419" */ './language-pack.es-419.js')).default }
    }
    case 'de-DE': {
      return { ...(await import(/* webpackChunkName: "language-pack.de-DE" */ './language-pack.de-DE.js')).default }
    }
    case 'en-ZW': {
      return { ...(await import(/* webpackChunkName: "language-pack.en-ZW" */ './language-pack.en-ZW.js')).default }
    }
    case 'en-ZA': {
      return { ...(await import(/* webpackChunkName: "language-pack.en-ZA" */ './language-pack.en-ZA.js')).default }
    }
    case 'it-IT': {
      return { ...(await import(/* webpackChunkName: "language-pack.it-IT" */ './language-pack.it-IT.js')).default }
    }
    case 'nl-NL': {
      return { ...(await import(/* webpackChunkName: "language-pack.nl-NL" */ './language-pack.nl-NL.js')).default }
    }
    case 'pt-PT': {
      return { ...(await import(/* webpackChunkName: "language-pack.pt-PT" */ './language-pack.pt-PT.js')).default }
    }
    case 'hr-HR': {
      return { ...(await import(/* webpackChunkName: "language-pack.hr-HR" */ './language-pack.hr-HR.js')).default }
    }
    case 'cs-CZ': {
      return { ...(await import(/* webpackChunkName: "language-pack.cs-CZ" */ './language-pack.cs-CZ.js')).default }
    }
    case 'da-DK': {
      return { ...(await import(/* webpackChunkName: "language-pack.da-DK" */ './language-pack.da-DK.js')).default }
    }
    case 'fi-FI': {
      return { ...(await import(/* webpackChunkName: "language-pack.fi-FI" */ './language-pack.fi-FI.js')).default }
    }
    case 'hu-HU': {
      return { ...(await import(/* webpackChunkName: "language-pack.hu-HU" */ './language-pack.hu-HU.js')).default }
    }
    case 'nb-NO': {
      return { ...(await import(/* webpackChunkName: "language-pack.nb-NO" */ './language-pack.nb-NO.js')).default }
    }
    case 'nn-NO': {
      return { ...(await import(/* webpackChunkName: "language-pack.nn-NO" */ './language-pack.nn-NO.js')).default }
    }
    case 'pl-PL': {
      return { ...(await import(/* webpackChunkName: "language-pack.pl-PL" */ './language-pack.pl-PL.js')).default }
    }
    case 'sk-SK': {
      return { ...(await import(/* webpackChunkName: "language-pack.sk-SK" */ './language-pack.sk-SK.js')).default }
    }
    case 'sv-SE': {
      return { ...(await import(/* webpackChunkName: "language-pack.sv-SE" */ './language-pack.sv-SE.js')).default }
    }
    case 'zh-CN': {
      return { ...(await import(/* webpackChunkName: "language-pack.zh-CN" */ './language-pack.zh-CN.js')).default }
    }
    case 'ja-JP': {
      return { ...(await import(/* webpackChunkName: "language-pack.ja-JP" */ './language-pack.ja-JP.js')).default }
    }
    default: ((shouldNeverRun:never) => {throw new Error(`${shouldNeverRun} unexpected value!`);})(culture)  }
}

