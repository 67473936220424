import React from "react";

import { NavConfig, UserData } from "../../../common";

export type AuthNavConfigData = {
    navConfig: NavConfig;
    loading: boolean;
    userInfo?: UserData;
};

const AuthNavConfigContext = React.createContext<AuthNavConfigData>({ loading: true, navConfig: [], userInfo: {} });
if (process.env.NODE_ENV !== "production") {
    AuthNavConfigContext.displayName = "AuthNavConfigContext";
}

export const AuthNavConfigContextProvider = AuthNavConfigContext.Provider;
export const AuthNavConfigContextConsumer = AuthNavConfigContext.Consumer;
export const useAuthNavConfigContext = (): AuthNavConfigData => React.useContext(AuthNavConfigContext);
