import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import { setCultureCookie } from "./util";

import {
    CountryCode,
    SEARCH_CRITERIA_QUERY,
    SearchCriteriaQuery,
    SearchCriteriaQueryVariables,
    SearchItem,
    SearchType,
} from "$graphql/core";

export async function searchClassicTenant(
    client: ApolloClient<NormalizedCacheObject>,
    item: SearchItem["item"],
    country: CountryCode,
    fusionSearchURI: string
): Promise<void> {
    const {
        data: {
            search: { criteria },
        },
    } = await client.query<SearchCriteriaQuery, SearchCriteriaQueryVariables>({
        query: SEARCH_CRITERIA_QUERY,
        variables: {
            searchType: SearchType.Tenants,
            productType: 3, // Magic number from old UUI. And so the tech debt gets passed on...
            item: JSON.stringify(item),
        },
    });

    submitOnTenantType(criteria, country, fusionSearchURI);
}

const submitOnTenantType = (criteria: string, country: CountryCode, fusionSearchURI: string) => {
    setCultureCookie(country);
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", fusionSearchURI);
    const criteriaField = document.createElement("input");
    criteriaField.setAttribute("name", "criteria");
    criteriaField.setAttribute("value", JSON.stringify(JSON.parse(criteria).criteria));
    form.appendChild(criteriaField);
    document.body.appendChild(form);
    form.submit();
};
