import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import type { ButtonProps } from "@costar/theme-ui";
import { Button, Tooltip } from "@costar/theme-ui";

import { useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

const RemoveButton: FC<ButtonProps> = props => {
    // Set to false after button is clicked, then re-set to true. This prevents
    // the button from dispatching events within the same react render cycle.
    const [canBeClicked, setCanBeClicked] = useState(true);
    // Ref points to canBeClicked state. Used to prevent onClick from being
    // needlessly re-created.
    const canBeClickedRef = useRef(canBeClicked);

    const onClick = useCallback(() => {
        if (canBeClickedRef.current) {
            // console.debug("RemoveButton.onClick");
            subject<UUISubjectPayloads[UUISubject.RemoveButtonClicked]>(UUISubject.RemoveButtonClicked).notify();
            setCanBeClicked(false);
        }
    }, [canBeClickedRef]);

    useEffect(() => {
        // Conditional prevents infinite loop.
        if (!canBeClicked) setCanBeClicked(true);
        canBeClickedRef.current = canBeClicked;
    }, [canBeClicked, canBeClickedRef]);

    const resources = useStrictI18N();
    const removeTooltipMessage = resources.toolbar.tooltip.remove();

    return (
        <Tooltip arrow variant="pendo" placement="bottom" title={removeTooltipMessage}>
            <Button
                automation-id="uui-remove-button"
                variant="ghost"
                data-testid="remove-button"
                p="0"
                size="xxl"
                color="neutral.dark"
                hoverColor="primary"
                icon="remove"
                onClick={onClick}
                {...props}
            />
        </Tooltip>
    );
};

export default React.memo(RemoveButton);
