import configuration from "@costar/configuration";

/**
 * Function to log error object by using injected logger or console log if no logger is configured
 */
const logError = (err: any): void => {
    if (configuration.has("logger")) {
        const logger = configuration.get("logger");
        logger.error(err);
    } else {
        console.log(err);
    }
};

const onLog = (obj: any) => {
    const options = configuration.get("options");
    if (/Warning: Failed prop type/.test(obj.message)) {
        if (options.logger.ignorePropTypeErrors) return false;
        obj.level = "warn";
        obj.type = "prop-type";
    }

    if (/^\[React Intl\] [Missing message:|Cannot format message:]/.test(obj.message)) {
        if (options.logger.ignoreMessageLocalizationErrors) return false;
        obj.type = "localization";
        obj.level = "error";
    }

    if (/^\[XSS detected\]/.test(obj.message)) {
        obj.type = "xss";
        obj.level = "error";
    }

    if (/^(GET|POST) \/api\/build\b/.test(obj.message)) return false;
};

const logMsg = (msg: object): void => {
    if (configuration.has("logger")) {
        const logger = configuration.get("logger");
        logger.log(msg);
    } else {
        console.log(msg);
    }
};

export { logError, onLog, logMsg };
