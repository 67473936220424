import * as React from "react";

import { Box, Button, Tooltip } from "@costar/theme-ui";
import type { PopoverSelectContainerProps } from "@costar/theme-ui-select";

import { useOutsideAlerter, useStrictI18N } from "../../hooks";
import { DropdownContent } from "../../shared/add-listing-dropdown";
import { useAuthNavConfigContext } from "../nav-config";

const AuthAddListingDropdown: React.FC<PopoverSelectContainerProps> = props => {
    const culturalResources = useStrictI18N();
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    const { userInfo } = useAuthNavConfigContext();

    const contactId = userInfo?.contactId;

    /** When clicked on a button, toggle the dropdown*/
    const handleButtonClick = React.useCallback(() => {
        setOpen(!open);
    }, [open]);

    const dropdownRef = React.useRef(null);
    const tooltipRef = React.useRef(null);
    useOutsideAlerter([dropdownRef, tooltipRef], handleClose);

    return (
        <Tooltip
            interactive
            arrow
            placement="bottom-end"
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={handleClose}
            top="20px"
            ref={tooltipRef}
            title={
                <Box ref={dropdownRef}>
                    <DropdownContent contactId={contactId} />
                </Box>
            }
        >
            <Button
                automation-id="uui-add-listing-dropdown"
                variant="buttonDropdown"
                fontSize="sm"
                onClick={handleButtonClick}
                emPaddingX="xs"
                iconAfter={{ icon: "mui-arrowDropDown", shouldFlipIcon: true, toggleState: open }}
            >
                {culturalResources.addListing.title.nonOwner()}
            </Button>
        </Tooltip>
    );
};

export default AuthAddListingDropdown;
