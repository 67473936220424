export const loginHelper = (backupLoginUrl: string) => {
    return (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        try {
            const currentPathName = window.location.pathname;

            const articlePageMatch = currentPathName.match(/\/article\/([^\/]+)/);
            const authorPageMatch = currentPathName.match(/\/author\/([^\/]+)/);
            const searchPageMatch = currentPathName === "/search";

            const element = e.target as HTMLElement;
            const linkElement: HTMLAnchorElement = element["href"]
                ? (element as HTMLAnchorElement)
                : (element.parentElement as HTMLAnchorElement);
            if (linkElement && linkElement.href) {
                // Create a URL object
                const url = new URL(linkElement.href);

                const baseUrl = url.origin;

                // Get the query string part (including the ?)
                const queryParams = url.search;

                if (articlePageMatch) {
                    window.location.assign(`${baseUrl}/home/news/${articlePageMatch[1]}${queryParams}`);
                } else if (authorPageMatch) {
                    window.location.assign(`${baseUrl}/home/news/author/${authorPageMatch[1]}${queryParams}`);
                } else if (searchPageMatch) {
                    window.location.assign(`${baseUrl}/home/news/search${queryParams}`);
                } else {
                    window.location.assign(linkElement.href);
                }
            } else {
                window.location.assign(backupLoginUrl);
            }
        } catch (e) {
            window.location.assign(backupLoginUrl);
        }
    };
};
