import * as React from "react";

import type { Theme } from "@costar/theme-ui";
import { Anchor, Flex, useThemeUI, utils } from "@costar/theme-ui";
import { Select } from "@costar/theme-ui-select";

import { HOME_URL, useConfigContext } from "../../../common";
import { getCookie, mapLangToCultureCode, setCookie } from "../../utils/cookies";

import AddListingDropdown from "./add-listing-dropdown";
import { loginHelper } from "./login-helper";
import { RightNavContentProps } from "./types";

function hideAtMobileBreakpoint(theme: Theme) {
    const { breakpoints } = theme;
    return breakpoints
        ? {
              [`${breakpoints.down(0)}`]: {
                  display: "none",
              },
          }
        : {};
}

const setNewsCookie = (newsCookie: Record<string, any>) => {
    setCookie("cs_newsContext", encodeURIComponent(JSON.stringify(newsCookie)), 30);
    if (window && window.location) {
        console.log("reload");
        window.location.reload();
    }
};

const getNewsCookie = (): Record<string, any> => {
    try {
        return JSON.parse(getCookie("cs_newsContext"));
    } catch (err) {
        if (process.env.NODE_ENV !== "production") {
            console.error(err);
        }

        return {};
    }
};

let storedCurrentCulture: string | null = null;
const UUI_CULTURE_KEY = "uui-currentCulture";
if (typeof localStorage !== "undefined") {
    storedCurrentCulture = localStorage.getItem(UUI_CULTURE_KEY);
}

const RightContent: React.FC<RightNavContentProps> = props => {
    const {
        loginUrl = HOME_URL,
        loginLabel = "Login",
        initialLanguage,
        supportedLocales = ["en-US", "en-GB", "en-CA"],
    } = props ?? {};
    const { theme } = useThemeUI() as { theme: Theme };
    const { akamaiCulture } = useConfigContext();

    const [currentCulture, setCulture] = React.useState(
        mapLangToCultureCode(supportedLocales, initialLanguage || storedCurrentCulture || akamaiCulture || "en-US")
    );

    React.useEffect(() => {
        // if initial language (from newsCookie) is not en-US, en-CA, en-GB
        if (initialLanguage && !supportedLocales.includes(initialLanguage)) {
            const currentNewsCookie = getNewsCookie();
            if (utils.isNotEmptyObject(currentNewsCookie)) {
                // replace culture code in newsCookie to the mapLangToCultureCode (fr-CA --> en-CA)
                const newsCookie = {
                    ...currentNewsCookie,
                    culture: currentCulture,
                };
                setNewsCookie(newsCookie);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const currentNewsCookie = getNewsCookie();
        if (utils.isNotEmptyObject(currentNewsCookie)) {
            const { culture } = currentNewsCookie as {
                culture?: string;
            };
            const currentNewsCulture = mapLangToCultureCode(supportedLocales, culture);
            if (currentNewsCulture !== currentCulture) {
                const newsCookie = {
                    ...currentNewsCookie,
                    culture: currentCulture,
                };
                setNewsCookie(newsCookie);
            }
        } else {
            setNewsCookie({ culture: currentCulture });
        }
    }, [currentCulture]);

    return (
        <Flex gap="lg" alignItems="center" flexWrap="nowrap" paddingRight="xxl">
            <Anchor
                variant="text-button"
                fontSize="xs"
                px="xs"
                textProps={{ display: "flex", justifyContent: "center", minWidth: "55px" }}
                height="2em"
                href={loginUrl}
                sx={hideAtMobileBreakpoint(theme)}
                onClick={loginHelper(loginUrl)}
            >
                {loginLabel}
            </Anchor>
            <AddListingDropdown sx={hideAtMobileBreakpoint(theme)} />
        </Flex>
    );
};

export default React.memo(RightContent);
