import { MutableRefObject, useEffect, useRef } from "react";
import urlJoin from "url-join";

import type { UniversalMenuOptions } from "@costar/universal-menu";
import costarMenus from "@costar/universal-menu";

import { menuButton } from "./constants";
import { HamburgerMenuOptions } from "./types";

import { useStableCallback } from "$client/hooks";

export interface UniversalMenuState {
    readonly visible: boolean;
}

/**
 * Creates the hamburger menu (aka navigation menu, sidebar menu) using the
 * `@costar/universal-menu` package.
 *
 * @param menuButton A reference to the hamburger icon element.
 * @param options Options
 */
export const useUniversalMenu = <FetchMenuBody, FetchMenuResult = unknown>(
    menuButton: MutableRefObject<HTMLElement | null>,
    menu: MutableRefObject<HTMLElement | null>,
    options: HamburgerMenuOptions<FetchMenuBody, FetchMenuResult>
): void => {
    const isCreated = useRef(false);

    const { onCreated, fetchMenuCallback, ...opts } = options;
    const stableOnCreated = useStableCallback(onCreated);
    const stableFetchMenuCallback = useStableCallback(fetchMenuCallback);

    useEffect(() => {
        const menuEl = menu.current;
        const buttonEl = menuButton.current;

        // Do not re-create, even if options change. Also, wait for references to button
        // and menu DOM elements to be provided via these refs.
        // NOTE(don): This could lead to a sneaky bug where the consumer does an incorrect
        // `document.querySelector()` lookup, gets a `null` node, which then causes
        // this check to fail, making the hamburger icon + menu never appear.
        if (isCreated.current || document == null || buttonEl == null || menuEl == null) return;

        // Record creation now to prevent effect re-renders during createUniversalMenu call
        isCreated.current = true;

        // Using stable callbacks allows for changes to be propagated to the
        // menu without needing to re-create it.
        const universalMenuOptions = createMenuOptions({
            ...opts,
            onCreated: stableOnCreated,
            fetchMenuCallback: fetchMenuCallback ? (stableFetchMenuCallback as any) : undefined,
        });

        costarMenus.createUniversalMenu(menuButton.current, menuEl, universalMenuOptions);

        // return () => {
        //     menuEl && costarMenus.hideUniversalMenu(menuEl);
        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuButton, options]);
};

/**
 * map {@link HamburgerMenuOptions} to {@link UniversalMenuOptions}.
 * @todo remove magic strings and numbers
 *
 * @internal
 */
const createMenuOptions = <FetchMenuBody, FetchMenuResult = unknown>(
    options: HamburgerMenuOptions<FetchMenuBody, FetchMenuResult>
): UniversalMenuOptions<FetchMenuBody, FetchMenuResult> => ({
    id: menuButton.buttonId,
    iconId: menuButton.iconId,
    labelId: menuButton.labelId,
    font: options.forcedFont || "Helvetica",
    fontBold: options.forcedFont || "Helvetica",
    logo: options.logo,
    logoWidth: options.logoWidth ?? 145,
    logoHeight: options.logoHeight ?? 40,
    onCreated: options.onCreated,
    fetchMenuCallback: options.fetchMenuCallback,
    contactId: options.contactId,
    applicationId: options.appId,
    buttonText: options.buttonText ?? "",
    marketingBrandId: 1,
    culture: options.culture,
    homeUrl: options.homeUri,
    logoutUrl: urlJoin(options.gatewayUri, "/home/signout"),
    placeholders: {
        "config.country": options.culture, //options.countryCode,
        "config.costarSuiteGatewayHost": options.legacyGatewayUri,
        "config.newCostarSuiteGatewayHost": options.gatewayUri,
        "config.centralAuthClientId": "costar",
        "config.homeUrl": options.homeUri,
        propexUriHost: options.propexUri,
        centralAuthHost: options.authUri,
        costarSuiteLeaseCompsHost: options.leaseDcfUri,
        costarSuiteProductsHost: options.gatewayUri,
        lmHost: options.listingUri,
        LMHost: options.listingUri,
        loopnetHost: options.loopNetUri,
    },
});
