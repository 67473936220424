import { useCallback, useState } from "react";

export type VisibilityState = [isVisible: boolean, show: () => void, hide: () => void, toggle: () => void];

/**
 * Provides a visibility {@link useState state} with commonly used functions for
 * modifying it.
 *
 * Returns an array with the following 4 elements:
 *
 * 1. `isVisible`, a boolean that is `true` when the component should be
 *    visible.
 * 2. `show`, a function that makes the component visible
 * 3. `hide`, a function that hides the component
 * 4. `toggle`, a function that toggles the visibility state.
 *
 * @param initialVisibility the starting visibility. Defaults to `false`.
 *
 * @returns A {@link VisibilityState} object that stores the aforementioned
 * elements.
 */
export const useVisible = (initialVisibility = false): VisibilityState => {
    const [isVisible, setIsVisible] = useState(initialVisibility);

    const show = useCallback(() => setIsVisible(true), [setIsVisible]);
    const hide = useCallback(() => setIsVisible(false), [setIsVisible]);
    const toggle = useCallback(() => setIsVisible(visible => !visible), [setIsVisible]);

    return [isVisible, show, hide, toggle];
};
