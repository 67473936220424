import { useMemo } from "react";

import type Logger from "@costar/logger";

import { AcceptedLanguage } from "../../types";

import { getCookiesPreferCulture } from "$client/utils/cookies";
import {
    type ClientConfig,
    AuthenticatedClientConfig,
    canUseDOM,
    UnauthenticatedClientConfig,
    useConfigContext,
} from "$common";
import getLogger from "$common/log";

export const useCulture = (): AcceptedLanguage => {
    const config = useConfigContext();
    const logger = getLogger();

    const culture = useMemo(() => getCulture(config, logger), [config, logger]);

    return culture;
};

export const getCulture = (config: ClientConfig, logger?: Logger): AcceptedLanguage =>
    config.authenticated ? getCultureAuthenticated(config, logger) : getCultureUnauthenticated(config, logger);

function getCultureAuthenticated(config: AuthenticatedClientConfig, logger?: Logger): AcceptedLanguage {
    if ("userPreferences" in config) {
        const { CultureName } = config.userPreferences ?? {};
        if (CultureName) return CultureName as AcceptedLanguage;

        logger?.debug(`Client config contains user preferences, but user preferences is missing a culture code.`);

        const cookieCulture: string | undefined = canUseDOM ? getCookiesPreferCulture() : undefined;
        if (cookieCulture) {
            logger?.debug(`Found culture ${cookieCulture} in cookie, using it for culture code.`);
            return cookieCulture as AcceptedLanguage;
        }

        logger?.debug("No culture code fallback found, defaulting to en-US");
        return "en-US";
    } else {
        return (getCookiesPreferCulture() ||
            (config as Partial<AuthenticatedClientConfig>).akamaiCulture ||
            "en-US") as AcceptedLanguage;
    }
}

function getCultureUnauthenticated(config: UnauthenticatedClientConfig, logger?: Logger): AcceptedLanguage {
    return (getCookiesPreferCulture(true) || config.akamaiCulture || "en-US") as AcceptedLanguage;
}
