import React, { FC, useCallback, useMemo } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { Flex } from "@costar/theme-ui";

import {
    getTabSelectionFromUrl,
    Section,
    SectionDropdown,
    SectionId,
    setSelectedTab,
    setSubNavSelectedTab,
    setUpsellModal,
    warnOrThrow,
} from "../../../common";
import { useNavState, useSubnavState } from "../../../common/state/state.context";
import { useAuthNavConfigContext } from "../../auth/nav-config";
import { useCurrentTab } from "../../hooks/use-current-tab";
import { NavigationEvent, UUISubject, UUISubjectPayloads } from "../../subjects";

import { NavLeftContent, NavLeftContentProps } from "./nav-left-content";
import { NavRightContent, NavRightContentProps } from "./nav-right-content";

export type SubMenuItem = {
    id: string;
    label: string;
    url?: string;
    children?: SubMenuItem[];
};

export type SubMenuProps = (NavRightContentProps & NavLeftContentProps) & {
    label?: string;
    onBackClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export type ConnectedSubMenuProps = Pick<SubMenuProps, "label">;

export const ConnectedSubMenu: FC<ConnectedSubMenuProps> = props => {
    const {
        subnav: {
            subNavTabSelection,
            message,
            actionButtonState,
            visible,
            activeRecordIndex,
            resultSetTotalCount,
            subnavLabel,
            favoritesCount,
            resultSetTotalCountTooltipText,
            backButtonOverrideLabel,
        },
    } = useSubnavState();

    const {
        dispatch,
        nav: { tabSelection, emitDetailedClickEvent },
    } = useNavState();

    const { loading, navConfig } = useAuthNavConfigContext();
    const subNavConfig = useCurrentTab(navConfig, tabSelection)?.sections;

    const navigateToNewProduct = useCallback(
        (product: Section | SectionDropdown) => {
            const { id, baseUrl, isUserSubscriber } = product;
            const tabHref = product.href;
            const href = tabHref && new URL(tabHref, window.location.origin).toString();

            // This function shouldn't be called when the user isn't a subscriber.
            // This invariant guards against potential future bugs.
            if (!isUserSubscriber) {
                throw new Error("Cannot navigate to a product the user is not subscribed to. This is a bug.");
            }

            // When consumer wants the UUI to handle navigation, emit event
            // anyways if we don't know where to navigate to
            const shouldEmitNavigationEvent: boolean = emitDetailedClickEvent || href === undefined;
            if (shouldEmitNavigationEvent) {
                const navEventPayload: NavigationEvent = {
                    id,
                    href,
                    selfHandleRoute: false, // this seems to always be false in <costar-nav-item />
                    baseUrl: baseUrl,
                    isUserSubscribed: true,
                    landingPath: undefined, // what is this?
                    // This business logic is copied from uui <costar-nav-item />
                    processClickEvent(highlightOnly = false) {
                        if (highlightOnly) {
                            const tab = subNavConfig?.find(({ id }) => id === this.id);
                            if (tab) dispatch(setSubNavSelectedTab(tab.id));
                        } else if (href) {
                            window.location.href = href;
                            // todo: push to history stack so that back works
                        }
                    },
                };

                // Dispatch event to pub/sub bus
                subject<UUISubjectPayloads[UUISubject.MenuTabSelected]>(UUISubject.MenuTabSelected).notify(
                    navEventPayload
                );
            } else {
                const tab = subNavConfig?.find(({ id: tabId }) => tabId === id);
                tab && dispatch(setSubNavSelectedTab(tab.id));
                window.location.href = href as string;
            }
        },
        [dispatch, emitDetailedClickEvent, subNavConfig]
    );

    const setSelection = useCallback(
        (tabSelected: SectionId, dropdownSelected?: SectionId | string) => {
            //            let tab: Section | SectionDropdown | undefined;
            let tab: Section | SectionDropdown | undefined;

            const selectedTab = subNavConfig?.find(({ id }) => id === tabSelected); // should always be able to find a selectedTab

            //if dropdownSelected argument was given, try to find the dropdown item in the tab item
            if (dropdownSelected) {
                tab = selectedTab?.dropdownItems?.find(({ id }) => id === dropdownSelected);
            } else {
                tab = selectedTab;
            }

            // if we did not find a tab, throw an error. This will occur if tabSelected doesnt find any tabs, or if dropdown was supplied and it found nothing
            if (!tab) {
                //if we couldn't find a tab, throw the error safely.
                tab = warnOrThrow(`Could not find tab with id ${tabSelected}`, subNavConfig[0]);
            }

            /* When subscribed, publish a nav event to the bus. If Costar Suite decides
             * to continue with navigation, update the MFE's internal state by dispatching
             * the corresponding action to the state reducer.
             *
             * If they're not subscribed, we still need to propagate down the "selected"
             * tab, which has not been navigated to, down to the main nav so that it
             * can show an upsell message.
             */
            const { id, isUserSubscriber } = tab;
            if (isUserSubscriber) {
                navigateToNewProduct(tab);
            } else {
                dispatch(setUpsellModal(isUserSubscriber, id));
                return;
            }
        },
        [subNavConfig, navigateToNewProduct, dispatch]
    );

    const menuProps = useMemo<SubMenuProps>(
        () => ({
            message,
            actionButtonState,
            activeRecordIndex,
            resultSetTotalCount,
            resultSetTotalCountTooltipText,
            subnavLabel,
            favoritesCount,
            subNavTabSelection,
            tabSelection,
            // navConfig,
            subNavConfig,
            setSelection: loading ? stubClickHandler : setSelection,
            backButtonOverrideLabel,
            // ...commonProps,
            ...props,
        }),
        [
            message,
            actionButtonState,
            activeRecordIndex,
            resultSetTotalCount,
            resultSetTotalCountTooltipText,
            subnavLabel,
            favoritesCount,
            subNavTabSelection,
            tabSelection,
            subNavConfig,
            loading,
            setSelection,
            backButtonOverrideLabel,
            props,
        ]
    );

    const onBackClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.BackButtonClicked]>(UUISubject.BackButtonClicked).notify();
        // Back button navigation is client side on MFEs. Checking url to decide when to set the main and sub tabs. TODO: improve this logic
        const currentUrl = window.location.pathname;
        if (
            currentUrl.includes("/tenants/") ||
            currentUrl.includes("/professionals/") ||
            currentUrl.includes("/listings/") ||
            currentUrl.includes("/owners/")
        ) {
            const { mainTab, subTab } = getTabSelectionFromUrl(window.location.href);

            mainTab && dispatch(setSelectedTab(mainTab));
            subTab && dispatch(setSubNavSelectedTab(subTab));
        }
    }, [dispatch]);

    return visible ? <SubMenu onBackClick={onBackClick} {...menuProps} /> : null;
};

export const SubMenu: FC<SubMenuProps> = props => {
    const { label = "Sub Navigation Menu", onBackClick, ...rest } = props;

    return (
        <Flex
            height="34px"
            width="100%"
            alignItems="center"
            automation-id="uui-submenu"
            gap="xs"
            fontSize="xs"
            bg="neutral.lighter"
            borderTop="solid rgba(191, 191, 191, 0.85) 1px"
            borderBottom="solid rgba(191, 191, 191, 0.85) 1px"
        >
            <NavLeftContent onBackClick={onBackClick} {...rest} />
            <NavRightContent {...rest} />
        </Flex>
    );
};

function stubClickHandler() {
    // noop
}
