import type { Theme } from "@costar/theme-ui";

export default {
    masthead: {
        list: { variant: "lists.list" },
        listItem: {
            cursor: "pointer",
            color: "text",
            "&.is-selected": {
                bg: "neutrals.20",
            },
            "&.is-disabled": {
                cursor: "not-allowed",
                opacity: 0.4,
            },
            "&.is-active, &:hover:not(.is-selected, .is-disabled)": {
                opacity: 0.6,
            },
        },
    },
    legacy: {
        list: { variant: "lists.list" },
        listItem: {
            variant: "lists.listItem",
            "&.is-active, &:hover:not(.is-selected, .is-disabled)": {
                bg: "primary",
                color: "white",
            },
        },
    },
} as Theme;
