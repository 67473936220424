import configuration from "@costar/configuration";
import type Logger from "@costar/logger";

export { logError, logMsg, onLog } from "./log";

/**
 * Obtains the application {@link Logger} from the global configuration store.
 *
 * This function can only be used after a logger has been initialized using
 * {@link configureLogger}
 *
 * @returns The application's {@link Logger} instance.
 *
 * @see Logger
 * @see configureLogger
 */
export default function getLogger(): Logger {
    const logger: unknown = configuration.get("logger");

    if (!logger) {
        throw new Error("Logger has not been configured yet. Call configureLogger first.");
    }

    // NOTE: cannot check for `instanceof Logger` as importing Logger on the
    // client brings in lots of node-only packages. Easier to do a method check.
    // else if (!(logger instanceof Logger)) {
    //     throw new TypeError("Invalid logger stored in configuration");
    // }
    else if (
        typeof (logger as Logger).log !== "function" ||
        typeof (logger as Logger).error !== "function" ||
        typeof (logger as Logger).debug !== "function"
    ) {
        throw new Error("Invalid logger stored in configuration");
    }

    return logger as Logger;
}
