import gql from "graphql-tag";

export const SEARCH_ITEM_PARTS = gql`
    fragment SearchItemParts on SearchItem {
        id
        name
        htmlName
        htmlDescription
        matchType
        propertyType
        country
        item
        redirect {
            __typename
            ... on SearchHrefRedirect {
                href
            }

            ... on SearchFormRedirect {
                action
                method
                formData
            }
        }
        formattedPropertyAddress
    }
`;
