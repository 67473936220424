import type { Theme, ThemeUICSSObject } from "@costar/theme-ui";
import { boldTextFixLength, standardButtonDisabled } from "@costar/theme-ui";

const baseTab: ThemeUICSSObject = {
    cursor: "pointer",
    color: "white",
    emPaddingY: "xs-3",
    ...boldTextFixLength,
    "&:hover:not(:disabled):not(.is-selected)": {
        opacity: 0.4,
    },
    "&[disabled]": standardButtonDisabled,
    "&.is-selected": {
        fontWeight: "bold",
        cursor: "default",
    },
};

export default {
    tabs: {
        bg: "secondary",
        px: undefined, // use undefined to remove base theme settings
        lineHeight: 2,
    },
    tab: {
        ...baseTab,
        "&:first-of-type": {
            ml: "md",
        },
        "&.csg-tui-tab": {
            fontSize: "sm",
        },
    },
    indicator: {
        bg: "accent",
        height: "tabs.indicator",
    },
    scrollButton: {
        color: "white",
        width: undefined, // use undefined to remove base theme settings
        "&.is-right": {
            background: "linear-gradient(to right, transparent, #1b3a6e 50%, #1b3a6e)",
            emPaddingRight: "xs",
        },
        "&.is-left": {
            background: "linear-gradient(to left, transparent, #1b3a6e 50%, #1b3a6e)",
            emPaddingLeft: "xs",
        },
    },
} as Theme;
