/**
 * Configuration for the entire navbar. Contains information for display text,
 * links, user subscriptions, etc. for each tab.
 *
 * Tabs almost always correspond to a product or a {@link ProductGroup group of products}.
 *
 * @api
 *
 * @see {@link ProductGroup} - top level tabs, displayed on main nav
 * @see {@link TabConfig} - base type for all tabs
 */
export type NavConfig = ProductGroup[];

export type UserData = {
    contactId?: string;
    locationCountryCode?: string;
};

export interface ProductGroup extends Readonly<TabConfig<ProductId>> {
    readonly defaultHref?: string;

    /**
     * @deprecated maintained by state
     */
    readonly isActive?: boolean;

    /**
     * Tab data for the products within this group. Sections are displayed
     * as subnav tabs when this product group/main nav tab is selected.
     */
    readonly sections: Section[];

    /**
     * Used for dropdown tabs. Dropdown tabs display a vertical list of tabs
     * when hovered over.
     *
     * Usually dropdown tabs do not have a {@link href} property. Without an
     * href, clicking will do nothing, and only hover will have any real
     * meaning.
     */
    readonly hoverItems?: Section[];

    /**
     * List of culture codes the user has purchased a subscription for.
     * {@link isUserSubscriber} being false implies this list being empty.
     * Similarly, {@link isUserSubscriber} being true implies this list being
     * populated
     *
     * @example ["en-US", "fr-CA"]
     */
    readonly countriesSubscriptions?: string[];
    readonly baseUrl?: string;
}

export interface SectionDropdown extends TabConfig {
    isHeader?: boolean;
    baseUrl?: string;
}

export interface Section extends TabConfig<SectionId> {
    baseUrl: string;
    dropdownItems?: SectionDropdown[];
}

/**
 * Properties that all tabs have in common.
 */
export interface TabConfig<ID extends string = string> {
    /**
     * Unique identifier. Used for selecting and referencing the tab.
     */
    id: ID;

    /**
     * Resource key used for text
     * @deprecated
     */
    i18nKey?: string;

    /**
     * Whether the user has purchased this product or not.
     *
     * @see
     */
    isUserSubscriber?: boolean;

    /**
     * Path segment used to determine if the user is currently on this page.
     *
     * For example, if the page url is /foo/bar/baz/bang and this is /foo/bar,
     * the page "matches" this tab and it is considered active.
     */
    landingPath?: string;

    /**
     * Other possible {@link landingPath landing paths}.
     */
    alternatePaths?: string[];

    /**
     * Not sure
     * @todo
     */
    ignorePaths?: string[];

    /**
     * URL to navigate to when clicked. Not used for button or hover tabs.
     * as buttons
     */
    href?: string;

    /**
     * Internationalized label to display inside the tab.
     */
    text: string;
}
export const LandingPaths = {
    HOME: "home",

    PROPERTIES_MATCH_SEARCH2: "detail/lookup",
    PRODUCT_MATCH_SEARCH: /match-search\/[A-Z]{2}\/(\d)\//,
    LENDER_APP: /lender-app/g,

    PROPERTIES: "search/all-properties",
    PROPERTIES_DETAIL: "detail/all-properties",
    LISTINGS: "listings",
    LISTINGS_FOR_LEASE: "listings/for-lease",
    LISTINGS_FOR_SALE: "listings/for-sale",
    MULTI_FAMILY: "search/multi-family",
    MULTI_FAMILY_DETAIL: "detail/multi-family",
    SALE_COMPS: "search/sale-comps",
    SALE_COMPS_DETAIL: "detail/sale-comps",
    FOR_SALE: "search/for-sale",
    FOR_SALE_DETAIL: "detail/for-sale",
    AUCTIONS: "search/for-sale/gallery-view",
    AUCTIONS_DETAIL: "detail/for-sale/gallery-view",
    OWNERS_FUNDS: "owners/funds",
    OWNERS_COMPANIES_CLOUD: "suiteapps/owners/companies",
    OWNERS_FUNDS_CLOUD: "suiteapps/owners/funds",
    PROFESSIONALS: "app/professionals",
    PROFESSIONALS_ALL_COMPANIES: "app/professionals/companies",
    PROFESSIONALS_ALL_CONTACTS: "app/professionals/contacts",
    PROFESSIONALS_ALL_LOCATIONS: "app/professionals/locations",
    MODERN_TENANTS_COMPANIES: "tenants/companies",
    MODERN_TENANTS_LOCATIONS: "tenants/locations",
    MODERN_PROPERTIES: "properties",
    PUBLIC_RECORD: "search/public-record",
    PUBLIC_RECORD_DETAIL: "detail/public-record",
    MARKET: "Market",
    // I hate this
    MARKET2: "market",
    MARKET_INSIGHTS: "market-insights",
    DATA_EXPORT: "analyticexport",
    MARKET_SAVED_SEARCHES: "analyticexport",
    STR: "app/str",
    LENDER: "lender",
    WEB_ENTERPRISE_CASES: "cases",
    WEB_ENTERPRISE_REVIEWS: "reviews",
    WEB_ENTERPRISE_INTERACTIONS: "interactions",
    WEB_ENTERPRISE_REFERRALS: "referrals",
    NEW_LEASE_COMPS: "suiteapps/lease-comps",
} as const;

/**
 * Configuration for a main nav tab. Each one of these tabs groups together
 * one or more separate products (for sale, sale comps, etc).
 *
 * @see {@link TabConfig}
 */
export type ProductId = keyof typeof reverseMapMainProducts; // 'HOME' | 'PROPERTIES' | PUBLIC_RECORD | ....etc;

export type SectionId = keyof typeof reverseMapSectionIds; // 'ALL_PROPERTIES' | 'AUCTIONS' | DATA_EXPORT | ....etc;

// allows us to use the propertyID values like theyre enums
export const MainProductIds = {
    NONE: "NONE",
    HOME: "HOME",
    PROPERTIES: "PROPERTIES",
    LISTINGS: "LISTINGS",
    PUBLIC_RECORD: "PUBLIC_RECORD",
    LEASING: "LEASING",
    SALES: "SALES",
    CLOUD_FUNDS: "CLOUD_FUNDS",
    OWNER_COMPANIES: "OWNER_COMPANIES",
    TENANT: "TENANT",
    LEGACY_TENANT: "LEGACY_TENANT",
    PROFESSIONALS: "PROFESSIONALS",
    LEGACY_PROFESSIONALS: "LEGACY_PROFESSIONALS",
    MARKETS: "MARKETS",
    MARKETING_CENTER: "MARKETING_CENTER",
    PROPEX: "PROPEX",
    STR: "STR",
    LENDER: "LENDER",
    WEB_ENTERPRISE: "WEB_ENTERPRISE",
} as const;

const reverseMapMainProducts: ReverseMap<typeof MainProductIds> = Object.entries(Map).reduce((rMap, [k, v]) => {
    rMap[v] = k;
    return rMap;
}, {} as ReverseMap<typeof MainProductIds>);

// allows us to use the SectionID values like theyre enums
export const SectionProductIds = {
    ALL_PROPERTIES: "ALL_PROPERTIES",
    AUCTIONS: "AUCTIONS",
    DATA_EXPORT: "DATA_EXPORT",
    DEAL_MAKERS: "DEAL_MAKERS",
    FOR_LEASE: "FOR_LEASE",
    FOR_SALE: "FOR_SALE",
    LISTINGS_FOR_LEASE: "LISTINGS_FOR_LEASE",
    LISTINGS_FOR_SALE: "LISTINGS_FOR_SALE",
    LEASE_ANALYSIS: "LEASE_ANALYSIS",
    LEASE_COMPS: "LEASE_COMPS",
    NEW_LEASE_COMPS: "NEW_LEASE_COMPS",
    LEASE_SAVED_SEARCHES: "LEASE_SAVED_SEARCHES",
    MARKET_AND_SUBMARKETS: "MARKET_AND_SUBMARKETS",
    MARKET_INSIGHTS: "MARKET_INSIGHTS",
    MARKET_SAVED_SEARCHES: "MARKET_SAVED_SEARCHES",
    MULTI_FAMILY: "MULTI_FAMILY",
    PROFESSIONALS_SAVED_SEARCHES: "PROFESSIONALS_SAVED_SEARCHES",
    PROPERTY_SAVED_SEARCHES: "PROPERTY_SAVED_SEARCHES",
    OWNERS_COMPANIES: "OWNERS_COMPANIES",
    CLOUD_OWNERS_COMPANIES: "CLOUD_OWNERS_COMPANIES",
    OWNERS_FUNDS: "OWNERS_FUNDS",
    PROPERTY_UNDERWRITING_REPORTS: "PROPERTY_UNDERWRITING_REPORTS",
    PROFESSIONALS_ALL_COMPANIES: "PROFESSIONALS_ALL_COMPANIES",
    PROFESSIONALS_ALL_CONTACTS: "PROFESSIONALS_ALL_CONTACTS",
    PROFESSIONALS_ALL_LOCATIONS: "PROFESSIONALS_ALL_LOCATIONS",
    MODERN_TENANTS_COMPANIES: "TENANT_COMPANIES",
    MODERN_TENANTS_LOCATIONS: "TENANT_LOCATIONS",
    PROPEX_INBOX: "PROPEX_INBOX",
    PROPEX_OUTBOX: "PROPEX_OUTBOX",
    PROPEX_DIRECTORY: "PROPEX_DIRECTORY",
    PROPEX_INVESTOR_REQUIREMENTS: "PROPEX_INVESTOR_REQUIREMENTS",
    PROPEX_PROFILE: "PROPEX_PROFILE",
    PUBLIC_RECORD: "PUBLIC_RECORD",
    REAL_ESTATE_PROFESSIONAL: "REAL_ESTATE_PROFESSIONAL",
    SALES_SAVED_SEARCHES: "PROPERTY_SAVED_SEARCHES",
    SALE_COMPS: "SALE_COMPS",
    SHOPPING_CENTERS: "SHOPPING_CENTERS",
    TENANTS: "TENANTS",
    TENANTS_SAVED_SEARCHES: "TENANTS_SAVED_SEARCHES",
} as const;
const reverseMapSectionIds: ReverseMap<typeof SectionProductIds> = Object.entries(Map).reduce((rMap, [k, v]) => {
    rMap[v] = k;
    return rMap;
}, {} as any);

type ReverseMap<T extends Record<keyof T, any>> = {
    [V in T[keyof T]]: {
        [K in keyof T]: T[K] extends V ? K : never;
    }[keyof T];
};
