import React, { FC, useCallback, useEffect, useState } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import type { ButtonProps } from "@costar/theme-ui";
import { Button, Tooltip } from "@costar/theme-ui";
import { IconHeart } from "@costar/theme-ui-icons/csg/IconHeart";
import { IconFavorite } from "@costar/theme-ui-icons/md/IconFavorite";

import { useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

const FavoriteButton: FC<ButtonProps> = props => {
    const { toggled, ...rest } = props;
    const [selectFavoriteMessage, setSelectFavoriteMessage] = useState<string>("");
    const resources = useStrictI18N();

    useEffect(() => {
        const record = window && window.location.href.includes("detail/all-properties") ? "property" : "record";
        const recordText =
            record === "property"
                ? resources.toolbar.tooltip.addPropertyToFavorites()
                : resources.toolbar.tooltip.addRecordToFavorites();

        setSelectFavoriteMessage(recordText);
    }, []);

    const favoriteTooltipMessage = props.toggled
        ? resources.toolbar.tooltip.removeFromFavorites()
        : selectFavoriteMessage;

    const onClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.FavoriteButtonClicked]>(UUISubject.FavoriteButtonClicked).notify();
    }, []);
    return (
        <Tooltip arrow variant="pendo" placement="bottom" title={favoriteTooltipMessage}>
            <Button
                automation-id="uui-favorite-button"
                data-testid="favorite-button"
                variant="ghost"
                size="xxl"
                color={toggled ? "primary" : "neutral.dark"}
                p="0"
                hoverColor="primary"
                onClick={onClick}
                icon={toggled ? <IconFavorite /> : <IconHeart />}
                {...rest}
            />
        </Tooltip>
    );
};

export default React.memo(FavoriteButton);
