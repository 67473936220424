import React, { FC, useCallback, useState } from "react";

import { Button } from "@costar/theme-ui";
import { IconSearch } from "@costar/theme-ui-icons/md/IconSearch";

import SearchTypeAhead, { SearchTypeAheadProps } from "./search-type-ahead";

const searchIcon = <IconSearch size="2.0rem" />;

export const SearchPropertyDetailTypeAhead: FC<SearchTypeAheadProps> = ({ showDetailOmniSearch }) => {
    // show typeahead set to false
    const [searchShow, setSearchShow] = useState(false);
    // show search icon button set to true
    const [buttonShow, setButtonShow] = useState(true);
    // typeahead modal set to false
    const [openSearch, setOpenSearch] = useState(false);

    // displays typeahead when search icon clicked and opens search modal
    //closes search modal and typeahead, and displays search icon
    const handleClick = useCallback(() => {
        setSearchShow(current => !current);
        setButtonShow(current => !current);
        setOpenSearch(current => !current);
    }, []);

    return (
        <>
            {buttonShow && (
                <Button
                    automation-id="uui-typeahead-detail"
                    data-testid="detail-omni-button"
                    p="0"
                    icon={searchIcon}
                    variant="ghost"
                    color="neutral.dark"
                    onClick={handleClick}
                />
            )}
            {searchShow && (
                <SearchTypeAhead
                    openSearch={openSearch}
                    showDetailOmniSearch={showDetailOmniSearch}
                    detailSearchClick={handleClick}
                />
            )}
        </>
    );
};
