import React, { FC, useCallback } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import type { ButtonProps } from "@costar/theme-ui";
import { Button, Tooltip } from "@costar/theme-ui";

import { useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

const SelectButton: FC<ButtonProps> = props => {
    const handleSelectClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.CheckMarkClicked]>(UUISubject.CheckMarkClicked).notify();
    }, []);

    const resources = useStrictI18N();
    const selectLabel = resources.toolbar.tooltip.select();
    const unselectLabel = resources.toolbar.tooltip.unselect();

    return (
        <Tooltip arrow variant="pendo" placement="bottom" title={props.toggled ? unselectLabel : selectLabel}>
            <Button
                automation-id="uui-checkmark-button"
                data-testid="select-button"
                variant="ghost"
                p="0"
                size="xxl"
                hoverColor="primary"
                color={props.toggled ? "primary" : "neutral.dark"}
                icon={props.toggled ? "selected" : "select"}
                onClick={handleSelectClick}
                {...props}
            />
        </Tooltip>
    );
};

export default React.memo(SelectButton);
