import type { Theme } from "@costar/theme-ui";

import { THEME_UI_CONTEXT_ID } from "../common";

import lists from "./lists";
import tabs from "./tabs";
import tooltips from "./tooltips";

const localTheme = {
    contextId: THEME_UI_CONTEXT_ID,
    lists,
    tabs,
    tooltips,
    styles: {
        root: {
            "&": {
                "button:focus": { outline: "unset" },
                "a:hover": { color: "unset" },
            },
            "--webkit-font-smoothing": "antialiased",
        },
    },
} as Theme;

export default localTheme;
