import React, { type ReactNode, FC } from "react";

import type Logger from "@costar/logger";

import getLogger from "$common/log";

export interface ErrorBoundaryProps {
    fallback?: ReactNode;
    logger?: Logger;
    children?: any;
}
export interface ErrorBoundaryState {
    error?: Error;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    private _logger: Logger;

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            error: undefined,
        };

        this._logger = props.logger ?? getLogger();
        this.componentDidCatch = this.componentDidCatch.bind(this);
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        Object.assign(error, errorInfo);
        this._logger.error(error);
    }

    render(): ReactNode {
        if (this.state.error) {
            // If a custom fallback component is provided, use it. Otherwise,
            // display the error for developers or an opaque message in production.
            return (
                this.props.fallback ?? (
                    <header>
                        {process.env.NODE_ENV === "production" ? (
                            <h1>An error has occurred. Please try again later.</h1>
                        ) : (
                            <ErrorDisplay {...this.state.error} />
                        )}
                    </header>
                )
            );
        } else {
            return this.props.children;
        }
    }
}

const ErrorDisplay: FC<Error> = ({ message, stack, ..._rest }) => {
    const [stackMessage, ...stackTrace] = typeof stack === "string" ? stack.split("\n") : stack ?? [];
    return (
        <div id="costar-universal-ui-error">
            <h1 className="error message">{message}</h1>
            <h2 className="error stack message">{stackMessage}</h2>
            <ul className="error stack trace">
                {stackTrace.map(stackFrame => (
                    <li key={stackFrame}>{stackFrame}</li>
                ))}
            </ul>
        </div>
    );
};
