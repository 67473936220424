import * as React from "react";
import { FC, useCallback, useMemo, useRef, useState } from "react";

import { Button, Collapse, Flex, List, ListItem, ListItemText } from "@costar/theme-ui";

import { useI18N } from "../../../../i18n/react-i18n";
import { ROOT_URL, useConfigContext } from "../../../common";

import AddListingBuildingModal from "./add-listing-building-modal";
import buildListingOptions from "./build-listing-options";
import { DropdwonContentProps, ListingOptionItem } from "./types";

const COMPONENT_ID = "add-listing-button";

const DropdownContent: FC<DropdwonContentProps> = props => {
    const { contactId } = props;
    // FIXME(don): activeListRef looks to store the same data as _listId, should
    // be de-duplicated
    const [listId, setListId] = useState(-1);
    const activeListRef = useRef(-1);
    const [modalOpen, setModalOpen] = useState(false);

    const configState = useConfigContext();
    const { listingManager } = configState?.options?.navLinks || {};
    const culturalResources = useI18N()!;
    const data = useMemo(
        () => buildListingOptions(culturalResources, listingManager),
        [culturalResources, listingManager]
    );

    const handleOpenModalClick = useCallback(() => setModalOpen(true), []);
    const handleModalClose = useCallback(() => setModalOpen(false), []);
    const onClickHandler = (listId: number) => () => {
        // Clicking the same listing group closes the popup
        const nextId = activeListRef.current === listId ? -1 : listId;
        activeListRef.current = nextId;
        setListId(nextId);
    };

    return (
        <Flex flexDirection="column" gap="sm" fontSize="xs" width="220px">
            {data.map((listingOption, iIndx) => {
                return (
                    <Flex flexDirection="column" key={`${COMPONENT_ID}-${listingOption.id}`}>
                        <Button
                            variant="secondary"
                            justifyContent="center"
                            fontSize="xs"
                            width="100%"
                            onClick={onClickHandler(iIndx)}
                        >
                            {listingOption.label}
                        </Button>
                        <Collapse in={iIndx === activeListRef.current}>
                            <List variant="masthead" paddingBottom="0">
                                {listingOption.children?.map(listingOptionItem => (
                                    <AddListingListItem
                                        key={`${COMPONENT_ID}-${listingOption.id}-${listingOptionItem.id}`}
                                        {...listingOptionItem}
                                        handleOpenModalClick={handleOpenModalClick}
                                    />
                                ))}
                            </List>
                        </Collapse>
                        <AddListingBuildingModal open={modalOpen} onClose={handleModalClose} contactId={contactId} />
                    </Flex>
                );
            })}
        </Flex>
    );
};

const AddListingListItem: FC<ListingOptionItem & { handleOpenModalClick: () => void }> = React.memo(
    ({ id, url, label, handleOpenModalClick }) => {
        const isBusiness = id === "FOR_SALE_LISTING_BUSINESS";
        const href = isBusiness ? ROOT_URL : url;
        return (
            <ListItem color="neutral.dark" px="md" onClick={isBusiness ? handleOpenModalClick : undefined}>
                <ListItemText
                    as="a"
                    resetCss={{ color: "inherit" }}
                    fontSize="xs"
                    target="_blank"
                    primary={label}
                    href={href}
                    disabled={isBusiness}
                />
            </ListItem>
        );
    }
);

if (process.env.NODE_ENV === "production") {
    AddListingListItem.displayName ??= "AddListingListItem";
}

export default React.memo(DropdownContent);
