import { ProductId, SectionId } from "../../nav-config";
import { DisableButtonKeys, SetPagerCountKeys, ShowButtonKeys, ToggleButtonKeys } from "../state";

// =============================================================================
// ================================== ACTIONS ==================================
// =============================================================================

export enum ACTIONS {
    DetailedNavigationEvent = "nav-navigation-detailed-event",
    ShowButton = "action-button-set-visibility",
    ToggleButton = "action-button-set-toggle",
    DisableButton = "action-button-set-disable",
    SetPagerCounts = "action-pager-count",
    ShowSubnav = "masthead-subnav-set-visibility",
    ShowMastheadAlert = "masthead-alerts-button-set-visibility",
    ShowMastheadAlertCount = "masthead-alerts-count-visibility",
    ShowMastheadRecommendationsCart = "masthead-recommendations-cart-button-set-visibility",
    ShowMastheadRecommendationsCount = "masthead-recommendations-count-visibility",
    NavChange = "nav-selection-change",
    SubNavChange = "sub-nav-selection-change",
    UpdateSubmenuRecordCounter = "action-button-update-subnav-prev-next-counter",
    SubnavLabel = "subnav-label",
    FavoritesCount = "favorites-count",
    ResultSetTotalCountTooltipText = "result-set-total-count-tooltip-text",
    BackButtonOverrideLabel = "back-button-override-label",
    ShowOmnisearch = "masthead-omnisearch",
    ActiveProductKey = "active-product-key",
    ShowUpsellModal = "show-upsell-modal",
    HideRequestTraining = "hide-request-training",
}

export type SetDetailedNavigationEventAction = {
    type: ACTIONS.DetailedNavigationEvent;
    value: boolean;
};

/**
 * Show or hide a button in the subnav.
 */
export type SetActionButtonVisibilityAction = {
    type: ACTIONS.ShowButton;

    /**
     * The button to show/hide.
     */
    button: ShowButtonKeys;
    /**
     * `true` to show the button, `false` to hide it.
     */
    value: boolean;
};

export type SetSubNavVisibility = {
    type: ACTIONS.ShowSubnav;
    value: boolean;
};

export type SetOmniSearchVisibility = {
    type: ACTIONS.ShowOmnisearch;
    value: boolean;
};

export type SetHideRequestTraining = {
    type: ACTIONS.HideRequestTraining;
    value: boolean;
};

/**
 * Toggle a subnav button on or off.
 */
export type SetActionButtonToggleAction = {
    type: ACTIONS.ToggleButton;

    /**
     * The button whose state is being modified.
     */
    button: ToggleButtonKeys;
    /**
     * `true` for toggled on, `false` for toggled off.
     */
    value: boolean;
};

/**
 * Disable a subnav button on or off.
 */
export type SetActionButtonDisableAction = {
    type: ACTIONS.DisableButton;

    /**
     * The button whose state is being modified.
     */
    button: DisableButtonKeys;
    /**
     * `true` for disabled on, `false` for disabled off.
     */
    value: boolean;
};

export type SetPagerCountActions = {
    type: ACTIONS.SetPagerCounts;

    /**
     * The button whose state is being modified.
     */
    counterKey: SetPagerCountKeys;

    /**
     * Counter index
     */
    value: number;
};

type UpdateActiveRecordIndex = {
    type: ACTIONS.UpdateSubmenuRecordCounter;
    /**
     * false for previousButtonClicked
     * true for nextButtonClicked
     * */
    value: boolean;
};

/**
 * Shows/hides the Alerts icon on the Masthead.
 */
export type SetAlertsButtonVisibility = {
    type: ACTIONS.ShowMastheadAlert;
    /**
     * `true` to make alerts button visible, `false` to hide it.
     */
    value: boolean;
};

export type SetAlertsCount = {
    type: ACTIONS.ShowMastheadAlertCount;
    value: number;
};

export type SetRecommendationsCartButtonVisibility = {
    type: ACTIONS.ShowMastheadRecommendationsCart;
    value: boolean;
};

export type SetRecommendationsCount = {
    type: ACTIONS.ShowMastheadRecommendationsCount;
    value: number;
};

export type SetSubnavLabel = {
    type: ACTIONS.SubnavLabel;
    value: string;
};

export type SetFavoritesCount = {
    type: ACTIONS.FavoritesCount;
    value: number;
};

export type ShowResultSetTotalCountTooltipText = {
    type: ACTIONS.ResultSetTotalCountTooltipText;
    value: string | undefined;
};
/**
 * Fired when the currently selected main nav tab (e.g. News, Sales) changes.
 * Most often dispatched when the user clicks a different tab, but other events
 * can trigger this too.
 */
export type ChangeSelectedTabAction = {
    type: ACTIONS.NavChange;
    tabSelected: ProductId;
};

export type ChangeSelectedSubNavTabAction = {
    type: ACTIONS.SubNavChange;
    subTabSelected: SectionId;
};
export type SetActiveProductkey = {
    // Used only FOR LEASE / LEASING only
    type: ACTIONS.ActiveProductKey;
    value: ProductId | SectionId | undefined;
};

export type SetShowUpsellModal = {
    type: ACTIONS.ShowUpsellModal;
    upsellUserSubscriber: boolean | undefined;
    upsellProductKey: string | undefined;
};

export type SetBackButtonOverrideLabel = {
    type: ACTIONS.BackButtonOverrideLabel;
    value: string | undefined;
};

export type NavAction =
    | SetDetailedNavigationEventAction
    | SetActionButtonVisibilityAction
    | SetActionButtonToggleAction
    | SetActionButtonDisableAction
    | SetSubNavVisibility
    | SetOmniSearchVisibility
    | SetAlertsButtonVisibility
    | SetAlertsCount
    | ChangeSelectedTabAction
    | ChangeSelectedSubNavTabAction
    | SetPagerCountActions
    | SetSubnavLabel
    | SetFavoritesCount
    | ShowResultSetTotalCountTooltipText
    | UpdateActiveRecordIndex
    | SetActiveProductkey
    | SetShowUpsellModal
    | SetHideRequestTraining
    | SetBackButtonOverrideLabel
    | SetRecommendationsCartButtonVisibility
    | SetRecommendationsCount;

// =============================================================================
// ============================= ACTION PRODUCERS ==============================
// =============================================================================

/**
 * Creates an {@link SetActionButtonVisibilityAction action} that, when dispatched,
 * shows/hides a subnav button (i.e. an {@link ActionButton}).
 *
 * @param button the {@link ActionButton button} being shown/hidden
 * @param show `true` to show the button, `false` to hide it
 * @returns the action
 */
export const showActionButton = (button: ShowButtonKeys, show: boolean): SetActionButtonVisibilityAction => ({
    type: ACTIONS.ShowButton,
    button,
    value: show,
});

export const toggleActionButton = (button: ToggleButtonKeys, toggle: boolean): SetActionButtonToggleAction => ({
    type: ACTIONS.ToggleButton,
    button,
    value: toggle,
});

export const disableActionButton = (button: DisableButtonKeys, disable: boolean): SetActionButtonDisableAction => ({
    type: ACTIONS.DisableButton,
    button,
    value: disable,
});

export const setPagerCount = (counterKey: SetPagerCountKeys, value: number): SetPagerCountActions => ({
    type: ACTIONS.SetPagerCounts,
    counterKey,
    value: value,
});

export const prevNextButtonClicked = (nextPrevClick: boolean): UpdateActiveRecordIndex => ({
    type: ACTIONS.UpdateSubmenuRecordCounter,
    value: nextPrevClick,
});

/**
 * Creates an {@link SetSubNavVisibility action} that, when dispatched,
 * shows/hides the subnav.
 *
 * @param show `true` to show the subnav, `false` to hide it
 * @returns the action
 */
export const showSubNav = (show: boolean): SetSubNavVisibility => ({
    type: ACTIONS.ShowSubnav,
    value: show,
});

export const showOmniSearch = (show: boolean): SetOmniSearchVisibility => ({
    type: ACTIONS.ShowOmnisearch,
    value: show,
});

export const hideRequestTraining = (hide: boolean): SetHideRequestTraining => ({
    type: ACTIONS.HideRequestTraining,
    value: hide,
});

/**
 * Creates an {@link SetAlertsButtonVisibility action} that shows/hides the alerts icon.
 *
 * @param show
 * @returns
 */
export const showAlertsButton = (show: boolean): SetAlertsButtonVisibility => ({
    type: ACTIONS.ShowMastheadAlert,
    value: show,
});

/**
 * Creates an {@link SetAlertsCount action} that, when dispatched, sets the number
 * of alerts the user has, which is then communicated via the alerts bell icon.
 *
 * @param count the number of alerts
 * @returns the action
 */
export const showAlertsCount = (count: number): SetAlertsCount => ({
    type: ACTIONS.ShowMastheadAlertCount,
    value: count,
});

export const showRecommendationsCartButton = (show: boolean): SetRecommendationsCartButtonVisibility => ({
    type: ACTIONS.ShowMastheadRecommendationsCart,
    value: show,
});

export const showRecommendationsCount = (count: number): SetRecommendationsCount => ({
    type: ACTIONS.ShowMastheadRecommendationsCount,
    value: count,
});

export const showSubNavLabel = (label: string): SetSubnavLabel => ({
    type: ACTIONS.SubnavLabel,
    value: label,
});
export const showFavoritesCount = (count: number): SetFavoritesCount => ({
    type: ACTIONS.FavoritesCount,
    value: count,
});

export const showResultSetTotalCountTooltipText = (text: string | undefined): ShowResultSetTotalCountTooltipText => ({
    type: ACTIONS.ResultSetTotalCountTooltipText,
    value: text,
});

/**
 * Creates an {@link ChangeSelectedTabAction action} which, when dispatched,
 * changes the currently selected main nav tab.
 *
 * @param tabSelected The index of the selected tab.
 */
export const setSelectedTab = (tabSelected: ProductId): ChangeSelectedTabAction => ({
    type: ACTIONS.NavChange,
    tabSelected,
});

/**
 * Creates an {@link ChangeSelectedSubNavTabAction action} which, when dispatched,
 * changes the currently selected sub nav tab.
 *
 * @param tabSelected The index of the selected tab.
 */
export const setSubNavSelectedTab = (subTabSelected: SectionId): ChangeSelectedSubNavTabAction => ({
    type: ACTIONS.SubNavChange,
    subTabSelected,
});
/**
 * Use for Leasing and For Lease products only.
 *
 */
export const ShowActiveProductKey = (product: ProductId | SectionId | undefined): SetActiveProductkey => ({
    type: ACTIONS.ActiveProductKey,
    value: product,
});

export const setUpsellModal = (
    userSubscriber: boolean | undefined,
    product: string | undefined
): SetShowUpsellModal => ({
    type: ACTIONS.ShowUpsellModal,
    upsellUserSubscriber: userSubscriber,
    upsellProductKey: product,
});

export const setBackButtonOverrideLabel = (value: string | undefined): SetBackButtonOverrideLabel => ({
    type: ACTIONS.BackButtonOverrideLabel,
    value: value,
});
