import getLogger from "./log";

/**
 * Convert a kebab-case string to camelCase.
 *
 * @param s The string to convert
 * @returns The camelCase string
 *
 * @example
 * ```ts
 * camelCase('hello-world') // 'helloWorld'
 * ```
 */
export const toCamelCase = (s: string): string => s.replace(/-./g, x => x[1].toUpperCase());

/**
 * Convert a camelCase string to kebab-case.
 *
 * @param s The string to convert
 * @returns The kebab-case string
 *
 * @example
 * ```ts
 * kebabCase('helloWorld') // 'hello-world'
 * ```
 * @see {@link toCamelCase}
 */
export const toKebabCase = (s: string): string => s.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

/**
 * Used to handle an invalid state. In production, fail softly by logging an
 * error and falling back to some default value. In development, throw an error.
 *
 * @param message The message to warn or throw with.
 * @param fallback The default value to provide in production
 * @returns {@link fallback};
 */
export function warnOrThrow(message: string | Error): undefined;
export function warnOrThrow<T>(message: string | Error, fallback: T): typeof fallback;
export function warnOrThrow<T>(message: string | Error, fallback?: T): typeof fallback {
    let error: Error;
    if (typeof message === "string") {
        error = new Error(message);
        Error.captureStackTrace?.(error, warnOrThrow);
    } else {
        error = message;
    }

    if (process.env.NODE_ENV === "production") {
        getLogger().error(error);
        return fallback;
    } else {
        throw error;
    }
}

/**
 * Search URL for saleComps or forSale
 * Specific use for showDetailOmniSearch because Suite sets
 * the header "show-detail-omni-search" true for all detail pages.
 */
export const checkDetailTypeaheadEnabled = (pathname = ""): boolean =>
    !["/detail/sale-comps", "/detail/for-sale"].some(val => pathname.toLowerCase().startsWith(val));

/**
 * Checks if the application is running on the browser or the server.
 *
 * @returns `true` if running on the browser, `false` if on the server.
 */
export const isBrowser = (): boolean => canUseDOM;

export const canUseDOM =
    typeof window !== "undefined" && typeof document !== "undefined" && typeof document.createElement !== "undefined";

/**
 * Takes an error object from a catch block and coerces it into an {@link Error}.
 */
export const toError = (err: unknown): Error => {
    let error: Error;

    if (err instanceof Error) {
        return err;
    } else if (typeof err === "string") {
        error = new Error(err);
    } else if (typeof err === "object" && err != null) {
        error = new Error(err["message"] || undefined);
        for (const [key, value] of Object.entries(err) as [string, unknown][]) {
            if (key === "message") continue;
            error[key] = value;
        }
    } else {
        error = new Error();
    }

    return captureStackTrace(error, toError);
};

/**
 * Not all browsers support {@link Error.captureStackTrace}.
 * @private
 */
const captureStackTrace: (...args: Parameters<typeof Error.captureStackTrace>) => Error =
    "captureStackTrace" in Error && typeof Error.captureStackTrace === "function"
        ? (error, ctor) => {
              Error.captureStackTrace(error, ctor);
              return error as Error;
          }
        : error => error as Error;
