import type { Maybe } from "../../../core";
import { ItemsResponse, ProductKey } from "../types";

export interface ProductUserRepository {
    getContactId(): Promise<string>;
    getId(contactId: string): Promise<string>;
    getInfo(userId: string): Promise<ProductUserInfo>;
    getUserLocation(): Promise<ProductUserLocationResponse>;
    getAppFeatures(): Promise<ProductUserAppFeatureResponse>;
    contactInfo(): Promise<ProductUserContactInfoResponse>;
    subscriptions(): Promise<ProductUserSubscriptionsResponse>;
    countriesSubscriptions(userId: string, productIds: string[]): Promise<CountriesSubscriptionsResponse>;
}

export enum UsersPreferencesId {
    DisplayBuildingAreaUnitAs = 358,
    DisplayRentalRatesAs = 4,
    DisplayLandSizeAs = 3,
    PreferredCurrency = 361,
}

export interface ProductUserProduct {
    readonly Id: number;
    readonly Code: string;
}

export interface ProductUserLicense {
    readonly DataSetCode?: Maybe<string>;
    readonly DataSetDescription?: Maybe<string>;
    readonly PropertyTypeId?: Maybe<number>;
    readonly ProductMarketId?: Maybe<number>;
}

export interface ProductUserProductMarket {
    readonly ProductMarketId?: Maybe<number>;
    readonly Name?: Maybe<string>;
    readonly CountryCode: Maybe<string>;
}

export interface ProductUserEntitlement {
    readonly product?: ProductUserProduct;
    readonly propertyTypes?: readonly ProductUserLicense[];
    readonly markets?: readonly ProductUserProductMarket[];
}

export interface ProductUserInfo {
    readonly id: number;
    readonly ts: string; // Date? '2020-06-14T02:39:19.809419-04:00'
    readonly contactId: number;
    readonly email?: Maybe<string>;
    readonly name: string;
    readonly companyId: number;
    readonly companyName: string;
    readonly rootCompanyId: number;
    readonly worksForCoStar?: boolean;
    readonly publicationDateLicense: string; // Date? '1980-01-01T00:00:00-05:00'
    readonly entitlements?: readonly ProductUserEntitlement[];
}

export interface ProductUserContactInfo {
    readonly ContactId: number;
    readonly CompanyId: number;
    readonly CompanyName: string;
    readonly EmailAddress: string;
    readonly FullName: string;
    readonly FirstName: string;
    readonly LastName: string;
    readonly LocationId: number;
    readonly UserId: number;
    readonly HQLocationId: number;
}

export interface ProductUserLocationResponse {
    readonly Latitude: number;
    readonly Longitude: number;
}

export interface ProductUserSubscriptionsResponse {
    readonly FeatureProductIds: readonly number[];
    readonly ProductIds: readonly number[];
    readonly Items: readonly { readonly Value: number }[];
}

export interface ProductUserContactInfoResponse {
    readonly Items: Maybe<readonly ProductUserContactInfo[]>;
}

export interface ProductUserAppFeature {
    readonly AppFeatureId: number;
    readonly AppFeatureName: string;
    readonly Enabled?: boolean;
}

export interface ProductUserAppFeatureResponse {
    readonly Items: readonly ProductUserAppFeature[];
}

export type ProductUserInfoResponse = ProductUserInfo;

export interface ProductUserSalesUnit {
    readonly SalesUnitCD: string;
}

export type ProductUserSalesUnitsResponse = ItemsResponse<ProductUserSalesUnit>;

export interface Subscriptions extends Record<ProductKey, boolean> {
    advisoryServices: boolean;
    anyProduct: boolean;
    clientPresentation: boolean;
    comps: boolean;
    compsExpress: boolean;
    connect: boolean;
    cpd: boolean;
    forSale: boolean;
    forSaleExpress: boolean;
    forSaleLight: boolean;
    go: boolean;
    leaseAnalysis: boolean;
    leaseComps: boolean;
    marketAnalytics: boolean;
    marketPlace: boolean;
    myLoans: boolean;
    portal: boolean;
    property: boolean;
    propertyExpress: boolean;
    propertyLight: boolean;
    propex: boolean;
    showcase: boolean;
    strBenchmarking: boolean;
    strBenchmarkingSuite: boolean;
    strInfoAdmin: boolean;
    lenderAdmin: boolean;
    suite: boolean;
    tenant: boolean;
}

export interface ProductMarket {
    ProductMarketId?: number;
    Name?: string;
    CountryCode?: string;
}

export interface CountriesSubscriptionsResponse {
    readonly productMarkets: readonly ProductMarket[];
}
