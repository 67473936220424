import * as React from "react";

import { Box, Button, Tooltip } from "@costar/theme-ui";
import type { PopoverSelectContainerProps } from "@costar/theme-ui-select";

import { useOutsideAlerter, useStrictI18N } from "../../hooks";
import Content from "../../shared/add-listing-dropdown/dropdown-content";

const AddListingDropdown: React.FC<PopoverSelectContainerProps> = props => {
    const culturalResources = useStrictI18N();
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    /** When clicked on a button, toggle the dropdown*/
    const handleButtonClick = React.useCallback(() => {
        setOpen(!open);
    }, [open]);

    const wrapperRef = React.useRef(null);
    const wrapperRefTwo = React.useRef(null);
    useOutsideAlerter([wrapperRef, wrapperRefTwo], handleClose);

    return (
        <Tooltip
            interactive
            arrow
            placement="bottom-end"
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={handleClose}
            top="20px"
            ref={wrapperRefTwo}
            title={
                <Box ref={wrapperRef}>
                    <Content />
                </Box>
            }
        >
            <Button
                automation-id="uui-add-listing-dropdown"
                variant="buttonDropdown"
                fontSize="xs"
                onClick={handleButtonClick}
                emPaddingX="xs"
                iconAfter={{
                    icon: "mui-arrowDropDown",
                    shouldFlipIcon: true,
                    toggleState: open,
                }}
            >
                {culturalResources.addListing.title.nonOwner()}
            </Button>
        </Tooltip>
    );
};

export default AddListingDropdown;
