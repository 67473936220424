
import React from "react";
import { clientResources, initializeMessages } from "../resources.client";
import { Resources } from "../resources";
import { CultureKeyValuePairs, KeyValuePairs } from "@costar/i18n";

const I18NContext = React.createContext<Resources | undefined>(undefined);

//..forwardMessages parameter is used to forward messsages prop to direct children
//..if in future we remove old react-intl appraoch then we can clean up this parameter
const I18NProvider = ({ children, messages, forwardMessages }: { children: JSX.Element | JSX.Element[]; messages: CultureKeyValuePairs | KeyValuePairs; forwardMessages?: boolean }) => {
    initializeMessages(messages);
    return (
        <I18NContext.Provider value={clientResources}>
            {forwardMessages
                ? React.Children.map(children, c => React.cloneElement(c, { ...c.props, messages }))
                : children}
        </I18NContext.Provider>
    );
};
export { I18NContext, I18NProvider };
