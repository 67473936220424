import React from "react";

import { Anchor, Icon, Text } from "@costar/theme-ui";

export interface BackButtonProps {
    message: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const BackButton = React.memo<BackButtonProps>(({ onClick, message }) => (
    <Anchor marginLeft="1.0rem" title={message} automation-id="uui-back-button" color="primary" onClick={onClick}>
        <Icon icon="mui-keyboardArrowLeft" size="xxs" />
        <Text fontSize="xs" fontWeight="400" marginLeft="xxs">
            {message}
        </Text>
    </Anchor>
));

if (process.env.NODE_ENV === "production") {
    BackButton.displayName = "BackButton";
}

export default BackButton;
