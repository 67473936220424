import React, { FC, useEffect, useRef } from "react";

import { Flex } from "@costar/theme-ui";

import CenterContent from "./center-content";
import LeftContent from "./left-content";
import RightContent from "./right-content";

import { useMastheadState } from "$common";

/**
 * This is the top part of the authenticated nav. It displays the CoStar logo,
 * the Omni Search bar, the alerts icon, etc.
 *
 * Because this component and its business logic rely on API calls to PDS (e.g.
 * user pref, user subscriptions), it is not used by the unauthenticated nav.
 * Instead, it uses the `UnauthMasthead` in
 * `src/client/components/unauth-masthead`.
 *
 */
export const AuthMasthead: FC = () => {
    // todo replace with landingPath match
    const {
        masthead: { alerts, help, showOmniSearch, showDetailOmniSearch, hideRequestTraining, recommendations },
    } = useMastheadState();

    const hamburgerIconRef = useRef<HTMLElement>(null);
    useEffect(() => {
        hamburgerIconRef.current?.setAttribute("automation-id", "uui-hamburger-menu");
    }, [hamburgerIconRef]);

    return (
        <Flex
            height="48px"
            width="100%"
            justifyContent="space-between"
            bg="white"
            fontSize="sm"
            automation-id="uui-masthead"
        >
            <LeftContent hamburgerIconRef={hamburgerIconRef} showDetailOmniSearch={showDetailOmniSearch} />
            <CenterContent showOmniSearch={showOmniSearch} />
            <RightContent
                showAlertsButton={alerts.show}
                alertsCount={alerts.alertCount}
                showRecommendationsCartButton={recommendations.show}
                recommendationsCount={recommendations.recommendationsCount}
                showHelpButton={help?.show}
                hideRequestTraining={hideRequestTraining}
            />
        </Flex>
    );
};
