import React from "react";

import { Badge, Button, Flex, Text, Tooltip } from "@costar/theme-ui";

import { useI18N } from "../../../../i18n/react-i18n";

export const navigateToRecommendations = (): void => {
    window.history.pushState({}, "", "/suiteapps/recommendations/new");
    window.history.go();
};

export const RecommendationsCartButton = ({ recommendationsCount }: { recommendationsCount?: number }): JSX.Element => {
    const resources = useI18N();

    return (
        <Tooltip
            interactive
            arrow
            placement="bottom"
            top="20px"
            maxWidth="384px"
            title={
                <Flex flexDirection="column" gap="md">
                    <Text fontWeight="bold"> {resources?.recommendations?.tooltipLabel()} </Text>
                    <Text>{resources?.recommendations?.tooltip()}</Text>
                </Flex>
            }
        >
            <Button
                iconAfter={<Badge variant="primary" badgeContent={recommendationsCount}></Badge>}
                automation-id="uui-recommendations-button"
                variant="buttonDropdown"
                fontSize="sm"
                emPaddingX="xs"
                gap="4px"
                disabled={!recommendationsCount}
                onClick={navigateToRecommendations}
            >
                {resources?.recommendations?.label()}
            </Button>
        </Tooltip>
    );
};
