import { useMemo } from "react";

import { useI18N } from "../../../../i18n/react-i18n";
import { useConfigContext } from "../../../common";
import { getNavConfig, NavConfig, UserData } from "../../../common/nav-config";
import { HEADER_QUERY, User, UserInfo } from "../../../graphql/core";
import { useIsomorphicQuery } from "../../hooks/use-isomorphic-query";

export type ResultData = {
    navConfig: NavConfig;
    userInfo: UserData;
};

export type NavConfigQueryData = {
    loading: boolean;
    data: ResultData;
};

export const useNavConfigQuery = (culture: string): NavConfigQueryData => {
    const culturalResources = useI18N()!;
    const config = useConfigContext();
    const response = useIsomorphicQuery<{
        user: Pick<User, "apps">;
        getUserInfo: Pick<UserInfo, "contactId" | "locationCountryCode">;
    }>(HEADER_QUERY, {
        variables: { culture },
        initialData: { user: { apps: getNavConfig(culturalResources, config) }, getUserInfo: {} as UserInfo },
    });

    const memoizedData: NavConfigQueryData = useMemo(() => {
        const { apps = [] } = response?.data?.user || {};
        const { contactId, locationCountryCode } = response?.data?.getUserInfo || {};

        return {
            loading: response.loading,
            data: { navConfig: apps, userInfo: { contactId, locationCountryCode } } as ResultData,
        };
    }, [response]);

    return memoizedData;
};
