import { useDebugValue, useMemo } from "react";

import { isProductId, MainProductIds, NavConfig, ProductGroup, ProductId, warnOrThrow } from "../../common";

import getLogger from "$common/log";

/**
 * Selects the tab config for the current tab. i.e., gets the {@link ProductGroup}
 * object for the page the user is currently on.
 *
 * @param navConfig The {@link NavConfig} containing all available tabs
 * @param tabIndex The current tab index.
 *
 * @returns config data about the currently selected tab.
 *
 * @throws if {@link tabIndex} cannot index {@link navConfig} (i.e. `tabIndex < 0 || tabIndex >= navConfig.length`)
 */
export function useCurrentTab(navConfig: NavConfig, tabIndex: number): ProductGroup;
/**
 * Selects the tab config for the current tab. i.e., gets the {@link ProductGroup}
 * object for the page the user is currently on.
 *
 * @param navConfig The {@link NavConfig} containing all available tabs
 * @param tabId the {@link ProductId id} of the current tab.
 *
 * @returns config data about the currently selected tab.
 *
 * @throws if {@link tabIndex} cannot index {@link navConfig} (i.e. `tabIndex < 0 || tabIndex >= navConfig.length`)
 */
export function useCurrentTab(navConfig: NavConfig, tabId: ProductId): ProductGroup;
export function useCurrentTab(navConfig: NavConfig, current: ProductId | number): ProductGroup {
    const logger = useMemo(() => getLogger(), []);
    const currentTab = useMemo(() => {
        // current is a tab index, use it to access the config array
        if (typeof current === "number") {
            if (current < 0) {
                logger.warn(`Got negative tab selection: ${current}`);
                // eslint-disable-next-line react-hooks/exhaustive-deps -- we want temporary re-assignment
                current = 0;
            }
            return navConfig[current];
        } else {
            if (!isProductId(current)) warnOrThrow(`Current tab ${current} is not a product ID.`);
            // current is a product id, find it within the config
            return navConfig.find(tab => tab.id === current);
        }
    }, [navConfig, current]);

    // show the product id of the found tab for debug purposes
    useDebugValue(currentTab?.id);

    // Throw if current is invalid, unless we're in production, then warn and
    // fall back to the homepage
    if (!currentTab && current === MainProductIds.NONE) {
        return navConfig[0];
    }
    if (!currentTab) {
        logger.warn(`Cannot find selected tab ${current} in navconfig`);
        return navConfig[0];
        // return warnOrThrow(`Cannot find selected tab ${current} in navconfig`, navConfig[0]);
    } else {
        return currentTab;
    }
}
