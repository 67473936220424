import React, { FC } from "react";

import { Button } from "@costar/theme-ui";

import { SingleNavbarButtonProps } from "$client/subjects";

const RenderButton: FC<SingleNavbarButtonProps> = props => {
    return (
        <Button
            size="xs"
            title={props.text}
            data-testid="reports-button"
            automation-id="uui-reports-button"
            onClick={props.onButtonClick}
            {...props}
        >
            {props.text}
        </Button>
    );
};

const NavbarButtons = props => {
    const { navbarButtons } = props;

    if (navbarButtons == null || navbarButtons.show == null || navbarButtons.show.length === 0) {
        return null;
    }
    return props.navbarButtons?.show.map(button => <RenderButton {...button} />);
};

export default React.memo(NavbarButtons);
