import { type Resources } from "../../../i18n";
import { FeatureToggleHeaders } from "../../types";
import { ClientConfig } from "../config";
import { ROOT_URL } from "../constants";

import { NavConfig, ProductGroup, Section } from "./nav-config.types";

/**
 * Checks if a specific app feature is has a specific value (true or false typically)
 *
 * Unauth header does not support feature toggles
 * @param config
 * @param value
 * @returns
 */
const hasFeatureValue =
    (config: ClientConfig, value: string) =>
    (...featureNames: string[]): boolean => {
        const { authenticated, appFeatures } = config;
        // don't support app features in unauth header.
        if (!authenticated || !appFeatures) return false;
        return featureNames.some(feature => appFeatures[feature] === value);
    };
/**
 * Static nav config data. Used outright by unauth nav. Used as initial data by
 * auth nav, then updated from gql query. Also used during SSR so that
 * _something_ shows up.
 *
 * @note user aren't subscribers until proven otherwise.
 * @note everyone has access to the homepage
 * @todo refactor the GraphQL user apps resolver to use this function
 */
export const getNavConfig = (culturalResources: Resources, config: ClientConfig): NavConfig => {
    const {
        options: {
            navLinks: { login: baseUrl },
        },
        appFeatures,
        authenticated,
    } = config;
    const baseOptions = {
        baseUrl,
        isUserSubscriber: false,
    };
    const isEnabled = hasFeatureValue(config, "true");
    const isDisabled = hasFeatureValue(config, "false");

    const isLocal = config.environment === "local";

    const isListings = isEnabled(FeatureToggleHeaders.LISTINGS, "Listings");
    const isModernProperties = isEnabled(FeatureToggleHeaders.PROPERTIES);
    const isMarketsMfeEnabled = isEnabled(FeatureToggleHeaders.MARKETSMEF);

    const propertiesSections: Section[] = isModernProperties
        ? [
              // Multi-Family not yet supported with new Properties MFE
              {
                  id: "ALL_PROPERTIES",
                  text: culturalResources.products.property.allProperties(),
                  ...baseOptions,
              },
          ]
        : [
              {
                  id: "ALL_PROPERTIES",
                  text: culturalResources.products.property.allProperties(),
                  ...baseOptions,
              },
              {
                  id: "MULTI_FAMILY",
                  text: culturalResources.products.property.multiFamily(),
                  ...baseOptions,
              },
          ];

    return [
        {
            id: "HOME",
            text: culturalResources.products.news(),
            href: ROOT_URL,
            sections: [],
            isUserSubscriber: true,
        },
        {
            id: "PROPERTIES",
            text: culturalResources.products.property.properties(),
            href: isModernProperties ? "/properties?new_search" : "/search/all-properties?new_search=true&r=hmenu",
            sections: [
                ...propertiesSections,
                {
                    id: "SHOPPING_CENTERS",
                    text: culturalResources.products.property.shoppingCenters(),
                    ...baseOptions,
                },
                {
                    id: "PROPERTY_UNDERWRITING_REPORTS",
                    text: culturalResources.products.underwritingReport(),
                    ...baseOptions,
                },
            ],
            isUserSubscriber: false,
        },
        {
            id: "LEASING",
            text: culturalResources.products.leasing(),
            href: "/search/all-properties?new_search=true&search_type=forLease&r=hmenu",
            sections: [],
            isUserSubscriber: false,
        },
        {
            id: "SALES",
            text: culturalResources.products.sales.label(),
            href: "/search/sale-comps?new_search=true&r=hmenu",
            sections: [
                {
                    id: "SALE_COMPS",
                    text: culturalResources.products.comps.saleComps(),
                    ...baseOptions,
                },
                {
                    id: "FOR_SALE",
                    text: culturalResources.products.property.forSale(),
                    ...baseOptions,
                },
                {
                    id: "AUCTIONS",
                    text: culturalResources.products.sales.auctions(),
                    ...baseOptions,
                },
            ],
            isUserSubscriber: false,
        },
        ...(isListings
            ? [
                  {
                      id: "LISTINGS",
                      text: culturalResources.products.listings(),
                      href: "/listings/for-lease",
                      sections: [
                          {
                              id: "FOR_LEASE",
                              text: culturalResources.products.property.forLease(),
                              ...baseOptions,
                          },
                          {
                              id: "FOR_SALE",
                              text: culturalResources.products.property.forSale(),
                              ...baseOptions,
                          },
                      ],
                      isUserSubscriber: false,
                  } as ProductGroup,
              ]
            : []),
        {
            id: "OWNER_COMPANIES",
            text: culturalResources.products.owners.label(),
            href: "/suiteapps/owners/companies?new_search",
            sections: [
                {
                    ...baseOptions,
                    id: "CLOUD_OWNERS_COMPANIES",
                    text: culturalResources.products.property.ownerCompanies(),
                },
                {
                    id: "OWNERS_FUNDS",
                    text: culturalResources.products.funds.label(),
                    href: "/owners/funds?new_search",
                    sections: [],
                    isUserSubscriber: false,
                },
            ],
            isUserSubscriber: false,
        } as ProductGroup,
        {
            id: "TENANT",
            text: culturalResources.products.tenants.label(),
            // href: ROOT_URL,
            sections: [],
            isUserSubscriber: false,
        },
        {
            id: "PROFESSIONALS", //No need to change this for new directory product. Changing IDs need to be adjusted everywhere include consumers
            text: culturalResources.products.professionals.label(),
            sections: [
                {
                    ...baseOptions,
                    id: "PROFESSIONALS_ALL_CONTACTS",
                    text: culturalResources.products.professionals.contacts(),
                },
                {
                    ...baseOptions,
                    id: "PROFESSIONALS_ALL_LOCATIONS",
                    text: culturalResources.products.professionals.locations(),
                },
                {
                    ...baseOptions,
                    id: "PROFESSIONALS_ALL_COMPANIES",
                    text: culturalResources.products.professionals.companies(),
                },
            ],
            isUserSubscriber: false,
        },
        {
            id: "MARKETS",
            text: culturalResources.products.marketAnalytics(),
            href: isLocal && isMarketsMfeEnabled ? "/app/markets" : "/Market/#/search/map",
            sections: [],
            isUserSubscriber: false,
        },
        {
            id: "PUBLIC_RECORD",
            text: culturalResources.products.publicRecord(),
            href: "/search/public-record?new_search=true&r=hmenu",
            sections: [],
            isUserSubscriber: false,
        },
        {
            id: "MARKETING_CENTER",
            text: culturalResources.products.marketingCenter(),
            href: config.options.navLinks.listingManager,
            sections: [],
            isUserSubscriber: true,
        },
        {
            id: "STR",
            text: culturalResources.products.str.label(),
            // href: ROOT_URL,
            sections: [],
            isUserSubscriber: false,
        },
    ];
};
