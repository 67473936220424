import * as React from "react";
import { FC } from "react";

import type { ButtonProps } from "@costar/theme-ui";
import { Box, Button, Image } from "@costar/theme-ui";

import HamburgerIconSVG from "../../../assets/icons/hamburger-menu-icon.svg";

export const HamburgerIcon: FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (
        <Button data-testid="hamburger-icon" ref={ref} variant="icon" paddingLeft="lg" py="0" height="48px" {...props}>
            <Box height="24px" width="24px">
                <Image src={HamburgerIconSVG} height={"12px"} width={"18px"} />
            </Box>
        </Button>
    );
});

if (process.env.NODE_ENV !== "production") {
    HamburgerIcon.displayName ||= "HamburgerIcon";
}
