import React, { FC, useCallback } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import type { ButtonProps } from "@costar/theme-ui";
import { Button } from "@costar/theme-ui";

import { useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

const ReportsButton: FC<ButtonProps> = props => {
    const onClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.ReportsButtonClicked]>(UUISubject.ReportsButtonClicked).notify();
    }, []);

    const resources = useStrictI18N();
    const reportsLabel = resources.submenu.detail.label.reports();

    return (
        <>
            <Button
                size="xs"
                title={reportsLabel}
                data-testid="reports-button"
                automation-id="uui-reports-button"
                onClick={onClick}
                {...props}
            >
                {reportsLabel}
            </Button>
        </>
    );
};

export default React.memo(ReportsButton);
