import fetch from "isomorphic-fetch";
import React, { FC, PropsWithChildren, useEffect, useMemo } from "react";

import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    BatchHttpLink,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    onError,
    Operation,
    split,
} from "./apollo-packages";
import { ApolloCacheManager } from "./cache";
import { formatClientError } from "./utility";

import { ParsedUserPreferences } from "$types";

declare global {
    interface Window {
        options?: {
            graphql?: {
                debugMode?: boolean;
            };
        };
        // @ts-expect-error duplicate glance
        GLANCE?: {
            Cobrowse?: {
                Visitor?: {
                    stopSession: () => void;
                    inSession: () => boolean;
                    getKey: () => string;
                    startSession: () => void;
                    addEventListener: (eventName: string, listener: () => void) => void;
                };
            };
        };
    }
}

const UUI_CACHE_KEY = "__UUI_APOLLO_CACHE__";
const cacheManager = new ApolloCacheManager({
    key: UUI_CACHE_KEY,
});
const getBatchKey = (operation: Operation): string => operation.getContext().batchKey;

export let client: ApolloClient<NormalizedCacheObject>; // Todo - better way to export this?

const getHttpLink = (pathPrefix: string) =>
    new HttpLink({
        uri: `${pathPrefix}/api/graphql`,
        credentials: "same-origin",
        fetch,
    });

const getBatchHttpLink = (pathPrefix: string) =>
    new BatchHttpLink({
        uri: `${pathPrefix}/api/graphql`,
        credentials: "same-origin",
        fetch,
        batchKey: (operation: Operation) => getBatchKey(operation),
        batchMax: 100,
        batchInterval: 10,
    });

const createApolloClient = (pathPrefix: string, middleware?: ApolloLink, apolloState?: NormalizedCacheObject) => {
    let client: ApolloClient<NormalizedCacheObject>;

    const link = split(
        operation => !!getBatchKey(operation),
        // inject Apollo client into formatClientError
        ApolloLink.from([onError(err => formatClientError(client)(err)), getBatchHttpLink(pathPrefix)]),
        ApolloLink.from([onError(err => formatClientError(client)(err)), getHttpLink(pathPrefix)])
    );
    client = new ApolloClient({
        connectToDevTools: window?.options?.graphql?.debugMode,
        // PLEASE NOTE: the ordering of middleware -> link is important
        link: middleware ? ApolloLink.from([middleware, link]) : link,
        cache: new InMemoryCache({
            addTypename: true,
            typePolicies: {
                User: {
                    merge: true,
                },
            },
        }).restore(apolloState || {}),
        ssrForceFetchDelay: 100, // don't imediately fire quires
    });

    return client;
};

export type ProviderProps = {
    middleware?: ApolloLink;
    preferences: ParsedUserPreferences | undefined;
    pathPrefix: string;
};

/**
 *
 * @param middleware
 * @param preferences
 * @returns hydrated apollo client
 */
function useApolloClientInternal(
    pathPrefix: string,
    middleware: ApolloLink | undefined,
    preferences: ParsedUserPreferences | undefined
): ApolloClient<NormalizedCacheObject> {
    const client = useMemo(() => {
        const apolloState = cacheManager.load(preferences);
        return createApolloClient(pathPrefix, middleware, apolloState);
    }, [middleware, preferences, pathPrefix]);

    // Store the cache when the MFE unmounts
    useEffect(() => () => cacheManager.store(client, preferences), [client, preferences]);

    // Store the cache when the user navigates away
    useEffect(() => {
        const onBeforeUnload = () => cacheManager.store(client, preferences);
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => window.removeEventListener("beforeunload", onBeforeUnload);
    }, [client, preferences]);

    return client;
}

export const GraphqlClientSideProvider: FC<PropsWithChildren<ProviderProps>> = props => {
    const { children, middleware, preferences, pathPrefix } = props;
    client = useApolloClientInternal(pathPrefix, middleware, preferences);

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
